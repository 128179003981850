import { StyleSheet } from 'react-native';
import Colors from './Colors';

export default StyleSheet.create({
  container: {},
  input: {
    resize: 'vertical',
    minHeight: 40,
    fontSize: 18,
    borderColor: 'gray',
    borderWidth: 1,
    paddingVertical: 10,
    paddingHorizontal: 15,
    color: '#000',
    backgroundColor: '#fff',
    fontFamily: 'inter-regular',
  },
  inputDisabled: {
    backgroundColor: '#ddd',
  },
  inputLight: {
    borderWidth: 2,
    borderColor: '#fff',
    backgroundColor: 'transparent',
  },
  picker: {
    padding: 8,
    marginTop: 10,
    marginBottom: 10,
    fontSize: 15,
  },
  xl: {
    fontSize: 60,
  },
  h1: {
    color: Colors.darkBlue,
    fontSize: 35,
  },
  h2: {
    color: Colors.darkBlue,
    fontSize: 28,
  },
  h3: {
    color: Colors.darkBlue,
    fontSize: 25,
  },
  h4: {
    color: Colors.darkBlue,
    fontSize: 20,
  },
  h5: {
    color: Colors.darkBlue,
    fontSize: 18,
    letterSpacing: 0.5,
    lineHeight: 25,
  },
  h6: {
    color: Colors.darkBlue,
    fontSize: 16,
  },
  h7: {
    color: Colors.darkBlue,
    fontSize: 14,
  },
  h8: {
    color: Colors.darkBlue,
    fontSize: 12,
  },
  h9: {
    fontSize: 10,
  },
  p: {
    fontSize: 15,
    lineHeight: 17,
  },
  button: {
    // cursor: 'pointer',
    color: '#fff',
  },
  buttonFancy: {
    // backgroundColor: Colors.orange,
    borderWidth: 2,
    borderColor: '#000',
    paddingVertical: 16,
    paddingHorizontal: 25,
    // marginHorizontal: 10,
  },
  buttonSimple: {
    display: 'flex',
  },
  buttonFancyColor: {
    backgroundColor: Colors.orange,
  },
  buttonDisabled: {
    borderWidth: 2,
    borderColor: 'transparent',
    paddingVertical: 5,
    paddingHorizontal: 20,
    // marginHorizontal: 10,
    backgroundColor: '#ddd',
  },
  buttonLight: {
    borderWidth: 2,
    borderColor: '#fff',
    backgroundColor: 'transparent',
  },
  shadow: {
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
  },
});
