import MagicTableItemWrapper, { ColumnCriteriaPrompt } from '@src/components/MagicTable';
import Screen from '@src/components/Screen';
import Colors from '@src/components/shared/Colors';
import { TextBodyMedium, TextBodyRegular } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { View } from '@src/components/shared/Themed';
import { createMagicTableCellRequest } from '@src/prompts/MagicTableCell';
import { createTaskListCountRequest } from '@src/prompts/TaskList';
import React from 'react';

const defaultCriteriaColumns = [
  { type: 'task', label: 'Organization', query: 'What is the name of the organization that this document is about?', prompt: createMagicTableCellRequest },
  { type: 'task-list', label: 'Total tasks', prompt: createTaskListCountRequest },
  // { type: 'task', label: 'Reference document(s)', query: 'How many ?', prompt: createMagicTableCellRequest },
  { type: 'task', label: 'Date', query: 'What are some dates associated?', prompt: createMagicTableCellRequest },
  { type: 'task', label: 'Office location', query: 'Office location', prompt: createMagicTableCellRequest },
  { type: 'task', label: '% Volunteers increase', query: 'How did the number of volunteers increase, either by percentage or the total number of volunteers?', prompt: createMagicTableCellRequest },
  { type: 'task', label: '# Clients served', query: `How many clients were served? Return the number only.`, prompt: createMagicTableCellRequest },
  { type: 'task', label: '% Growth from prior year', query: 'What was the percentage grown? Only return the percentage number.', prompt: createMagicTableCellRequest },
  { type: 'task', label: '# of travel emissions reduced in tCO2e', query: '# of travel emissions reduced in tCO2e', prompt: createMagicTableCellRequest },
] as ColumnCriteriaPrompt[];

const vectorStores = [
  {
    indexId: 'demo-0',
    namespace: '0.159138876429616-1727959244900',
  },
  {
    indexId: 'demo-0',
    namespace: '0.028853095483698965-1727959286662',
  },
  {
    indexId: 'demo-0',
    namespace: '0.9419524116419278-1727809528099',
  },
  {
    indexId: 'demo-0',
    namespace: '0.5699571074936003-1727957594714',
  },
  {
    indexId: 'demo-0',
    namespace: '0.7095232073939237-1727959650505',
  },
];

const SustainabilityDemo = () => {
  return (
    <Screen>
      <View
        style={{
          margin: '3%',
          justifyContent: 'space-between',
        }}
      >
        <View
          style={{
            padding: '3%',
            width: '100%',
            backgroundColor: '#fff',
            justifyContent: 'center',
          }}
        >
          <TextBodyRegular style={[Styles.h4, { marginBottom: 20, color: Colors.buttonBackground }]}>All Data Table</TextBodyRegular>
          <TextBodyMedium style={[Styles.h3, { marginBottom: 30, color: Colors.buttonBackground }]}>Project: EY Sustainability</TextBodyMedium>

          <TextBodyRegular style={[Styles.h5, { marginBottom: 10, width: '65%' }]}>{`Use this table to explore data across all your reference documents.`}</TextBodyRegular>
          <TextBodyRegular style={[Styles.h5, { marginBottom: 10, width: '100%', marginLeft: 20 }]}>{`• Click on a cell in the table to get more detail on that attribute and follow-up with stakeholders`}</TextBodyRegular>
          <TextBodyRegular style={[Styles.h5, { marginBottom: 10, width: '100%', marginLeft: 20 }]}>{`• Ask any questions through the ‘Add column +’ button to extract insights across your organization simultaneously, or see where information is missing`}</TextBodyRegular>
          <TextBodyRegular style={[Styles.h5, { marginBottom: 10, width: '100%', marginLeft: 20 }]}>{`• Click “Go to summary” to see an overview of the submitted documentation gain further insights into the data for that organization`}</TextBodyRegular>

          <TextBodyRegular style={[Styles.h5, { marginVertical: 30, width: '65%' }]}>{`Note: you are able to upload your own documents to further explore this tool. Your documents are not saved to the server and are deleted once you close this window.`}</TextBodyRegular>
        </View>
        <MagicTableItemWrapper
          defaultCriteriaColumn={defaultCriteriaColumns}
          defaultVectorStores={vectorStores}
        />
      </View>
    </Screen>
  );
};

export default SustainabilityDemo;
