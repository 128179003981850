import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import CountdownTimer from '@src/components/Countdown';
import { DemoContext } from '@src/components/Demos/Context';
import Colors from '@src/components/shared/Colors';
import { TextBodyMedium, TextBodyRegular, TextHeader } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { View } from '@src/components/shared/Themed';
import { LoadingContext } from '@src/providers/Loading';
import { ModalContext } from '@src/providers/Modal';
import { uploadFiles } from '@src/utils/FileUpload';
import React, { useContext, useState } from 'react';
import { ActivityIndicator, ScrollView, TouchableOpacity } from 'react-native';
import TaskModal from '../TaskList/TaskModal';
import AddColumnModal from './AddColumnModal';
import MagicTableItem from './MagicTableItem';
import UploadRow from './UploadRow';
const extraColumns = 3;

type CellProps = {
  columnCriteria: ColumnCriteriaPrompt;
  criteriaColumns: ColumnCriteriaPrompt[];
  attributes: vectorStore;
};

type RowUploadProps = {
  addRow?: (vs: vectorStore) => void;
};

const RowUpload = ({ addRow }: RowUploadProps) => {
  const { getPineconeIndex } = useContext(DemoContext);
  const { addLoading, removeLoading, checkLoading } = useContext(LoadingContext);
  const handleFileUpload = async (files: FileList | null) => {
    if (files) {
      addLoading('loadPineconeIndex');
      // create pinecone index for this user.  each user should have only 1 index associated with them, where each index includes namespaces that are associated with projects
      // we need to get the indexId and namespace
      const newPineconeData = await getPineconeIndex();
      if (newPineconeData) {
        const indexId = newPineconeData?.indexId;
        const namespace = newPineconeData?.namespace;
        try {
          const fileNames = Array.from(files).map(file => file.name);
          console.log('Row fileNames', { indexId, namespace, fileNames });
          await uploadFiles(files, indexId, namespace);
          setTimeout(() => {
            // hack because sometimes the namespace in pinecone is not ready for search
            removeLoading('loadPineconeIndex');
            if (addRow) addRow({ indexId, namespace });
          }, 5000);
        } catch (error) {
          removeLoading('loadPineconeIndex');
          console.error('Error uploading file:', error);
        }
      }
    }
  };

  return (
    <>
      <View
        style={{
          flexDirection: 'row',
        }}
      >
        <View
          style={{
            width: 50,
            justifyContent: 'center',
            borderRightWidth: 1,
            borderRightColor: '#eee',
            alignItems: 'center',
          }}
        >
          <Ionicons name="add" />
        </View>
        {!checkLoading('loadPineconeIndex') && (
          <UploadRow onPress={handleFileUpload}>
            <TextBodyRegular
              style={[
                Styles.h6,
                {
                  padding: 20,
                },
              ]}
            >
              Add row
            </TextBodyRegular>
          </UploadRow>
        )}
        {checkLoading('loadPineconeIndex') && (
          <View style={{ flexDirection: 'row', padding: 12, alignItems: 'center' }}>
            <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 20 }}>
              <ActivityIndicator
                size={16}
                color="black"
              />
              <TextHeader style={[Styles.h6, { marginLeft: 10 }]}>Loading</TextHeader>
            </View>
            <TextBodyRegular style={[Styles.h6, { marginHorizontal: 10 }]}>Please wait. This can take 30 seconds depending on the number of files.</TextBodyRegular>
            <CountdownTimer
              size={14}
              initialTime={30}
            />
          </View>
        )}
      </View>
    </>
  );
};

const Cell = ({ columnCriteria, criteriaColumns, attributes }: CellProps) => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const { setModalContent } = useContext(ModalContext);

  const handlePress = (response: string) => {
    if (columnCriteria.type === 'task-list') {
      navigation.navigate('taskList', attributes);
    } else {
      setModalContent(
        <TaskModal
          task={{ name: columnCriteria.label, summary: response }}
          indexId={attributes.indexId}
          namespace={attributes.namespace}
        />,
      );
    }
  };

  return (
    <View
      style={{
        width: `${100 / (criteriaColumns.length + extraColumns)}%`,
        borderRightWidth: 1,
        borderRightColor: '#fff',
        borderBottomWidth: 1,
        borderBottomColor: '#fff',
        flex: 1,
        justifyContent: 'center',
      }}
    >
      <MagicTableItem
        criteria={columnCriteria}
        namespace={attributes.namespace}
        indexId={attributes.indexId}
        handlePress={handlePress}
      />
    </View>
  );
};

interface ColumnHeaderProps {
  label: string;
}

const ColumnHeader = ({ label }: ColumnHeaderProps) => {
  return (
    <TouchableOpacity
      onPress={() => {}}
      style={{
        justifyContent: 'center',
        flex: 1,
        padding: 15,
      }}
    >
      <TextBodyMedium style={[Styles.h6, { color: '#fff' }]}>{label}</TextBodyMedium>
    </TouchableOpacity>
  );
};

export type ColumnCriteriaPrompt = ColumnCriteria & {
  prompt?: ({ indexId, namespace, criteria }: createMagicTableCellRequestProps) => {};
};

export type createMagicTableCellRequestProps = {
  indexId: string;
  namespace: string;
  criteria: ColumnCriteria;
};

export type vectorStore = {
  namespace: string;
  indexId: string;
};

export type ColumnCriteria = {
  type: string;
  label: string;
  query: string;
};

interface MagicTableProps {
  defaultCriteriaColumn: ColumnCriteriaPrompt[];
  defaultVectorStores: vectorStore[];
}

const MagicTable = ({ defaultCriteriaColumn, defaultVectorStores }: MagicTableProps) => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const { setModalContent } = useContext(ModalContext);
  const [criteriaColumns, setCriteriaColumns] = useState<ColumnCriteriaPrompt[]>(defaultCriteriaColumn);
  const [vectorStores, setVectorStores] = useState<vectorStore[]>(defaultVectorStores);

  const onAddColumn = () => {
    // todo we need to run some queries while this modal is open.
    setModalContent(
      <AddColumnModal
        setCriteriaColumns={setCriteriaColumns}
        criteriaColumns={criteriaColumns}
      />,
    );
  };

  const addRow = (vs: vectorStore) => {
    let newVectorStores = [...vectorStores, vs];
    setVectorStores(newVectorStores);
  };

  const gotoReport = (vsAttributes: vectorStore) => {
    // goes to the report page
    navigation.navigate('reports', vsAttributes);
  };

  return (
    <ScrollView
      style={{
        marginTop: 30,
        backgroundColor: '#fff',
      }}
      horizontal
    >
      <View>
        {/* column headers */}
        <View
          style={{
            flexDirection: 'row',
            backgroundColor: Colors.bluelighter,
            // justifyContent: 'space-between',
          }}
        >
          <View
            style={{
              width: 50,
              justifyContent: 'center',
              borderRightWidth: 1,
              borderRightColor: '#eee',
              borderBottomWidth: 1,
              borderBottomColor: '#eee',
              alignItems: 'center',
            }}
          >
            <TouchableOpacity style={{}}>
              <TextHeader style={[Styles.h8, { color: '#fff' }]}>i</TextHeader>
            </TouchableOpacity>
          </View>
          {criteriaColumns.map((task, index) => {
            return (
              <View
                key={`column-header-${index}`}
                style={{
                  width: `${100 / (criteriaColumns.length + extraColumns)}%`,
                  borderRightWidth: 1,
                  borderRightColor: '#eee',
                  borderBottomWidth: 1,
                  borderBottomColor: '#eee',
                  flex: 1,
                  justifyContent: 'center',
                }}
              >
                <ColumnHeader label={task.label} />
              </View>
            );
          })}
          <View
            style={{
              width: `${100 / (criteriaColumns.length + extraColumns)}%`,
              borderRightWidth: 1,
              borderRightColor: '#eee',
              borderBottomWidth: 1,
              borderBottomColor: '#eee',
              justifyContent: 'space-between',
              padding: 15,
              flex: 1,
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Ionicons
              name="document-text-sharp"
              color="#000"
              size={20}
            />
            <TextBodyRegular style={[Styles.h6, { color: '#fff' }]}>Summary</TextBodyRegular>
          </View>
          <View
            style={{
              width: `${100 / (criteriaColumns.length + extraColumns)}%`,
              justifyContent: 'center',
              borderRightWidth: 1,
              borderRightColor: '#eee',
              borderBottomWidth: 1,
              borderBottomColor: '#eee',
              padding: 15,
              flex: 1,
            }}
          >
            <TouchableOpacity
              style={{ flex: 1, justifyContent: 'center' }}
              onPress={onAddColumn}
            >
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <TextBodyRegular style={[Styles.h6, { marginRight: 10, color: '#fff' }]}>Add column</TextBodyRegular>
                <Ionicons
                  name="add"
                  size={20}
                  color="#fff"
                />
              </View>
            </TouchableOpacity>
          </View>
        </View>

        {/* rows */}

        {vectorStores &&
          vectorStores.map((vsAttributes, rowIndex: number) => {
            return (
              <View
                key={`row-${rowIndex}`}
                style={{
                  flexDirection: 'row',
                }}
              >
                <View
                  style={{
                    width: 50,
                    borderRightWidth: 1,
                    borderRightColor: '#eee',
                    borderBottomWidth: 1,
                    borderBottomColor: '#eee',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <TextBodyRegular style={Styles.h6}>{rowIndex + 1}</TextBodyRegular>
                </View>
                {criteriaColumns &&
                  criteriaColumns.map((columnCriteria, columnIndex) => {
                    return (
                      <Cell
                        key={`row-${columnIndex}`}
                        columnCriteria={columnCriteria}
                        attributes={vsAttributes}
                        criteriaColumns={criteriaColumns}
                      />
                    );
                  })}
                <View
                  style={{
                    width: `${100 / (criteriaColumns.length + extraColumns)}%`,
                    justifyContent: 'center',
                    borderRightWidth: 1,
                    borderRightColor: '#eee',
                    borderBottomWidth: 1,
                    borderBottomColor: '#eee',
                    padding: 15,
                    flex: 1,
                  }}
                >
                  <TouchableOpacity
                    style={{ flex: 1 }}
                    onPress={() => gotoReport(vsAttributes)}
                  >
                    <View
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Ionicons
                        name="document-text-sharp"
                        color="#000"
                        size={20}
                      />
                      <TextBodyRegular style={Styles.h6}>Go to summary</TextBodyRegular>
                    </View>
                  </TouchableOpacity>
                </View>
                <View
                  style={{
                    width: `${100 / (criteriaColumns.length + extraColumns)}%`,
                    justifyContent: 'center',
                    borderRightWidth: 1,
                    borderRightColor: '#eee',
                    borderBottomWidth: 1,
                    borderBottomColor: '#eee',
                    padding: 15,
                    flex: 1,
                  }}
                >
                  <TouchableOpacity
                    style={{ flex: 1 }}
                    onPress={onAddColumn}
                  >
                    <Ionicons
                      name="add"
                      size={30}
                    />
                  </TouchableOpacity>
                </View>
              </View>
            );
          })}
        <RowUpload addRow={addRow} />
      </View>
    </ScrollView>
  );
};

export default MagicTable;
