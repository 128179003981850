import { TextBodyMedium, TextBodyRegular } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { View } from '@src/components/shared/Themed';
import { LoadingContext } from '@src/providers/Loading';
import { getTranslation } from '@src/services/i18n';
import React, { useContext, useRef, useState } from 'react';
import { ActivityIndicator } from 'react-native';
import Button from './Button';

interface UploadDocumentButtonProps {
  onPress?: (event?: FileList | null) => void;
}

const UploadDocumentButton = ({ onPress }: UploadDocumentButtonProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<FileList | string | undefined>();
  const { checkLoading, addLoading, removeLoading } = useContext(LoadingContext);
  const [uploadComplete, setUploadComplete] = useState(false);

  const handleOnPress = () => {
    if (fileInputRef.current !== null) {
      fileInputRef.current?.click();
    }
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      try {
        addLoading('uploadFile1');
        setFile(event.target.files);
        if (onPress) onPress(event.target.files);
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        removeLoading('uploadFile1');
      }
    }
  };

  const onUploadAnother = () => {
    setUploadComplete(false);
    setFile(undefined);
  };

  return (
    <View>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileUpload}
        style={{ display: 'none' }}
        multiple
      />
      {!checkLoading('uploadFile1') && !uploadComplete ? (
        <>
          <Button
            theme="fancy"
            onPress={handleOnPress}
            icon="cloud-upload"
          >
            {getTranslation('generics.documentUpload')}
          </Button>
        </>
      ) : !checkLoading('uploadFile1') && uploadComplete ? (
        <View>
          <TextBodyMedium style={[Styles.h3, { marginBottom: 10 }]}>{`Congratulations!`}</TextBodyMedium>
          <View
            style={{
              flexDirection: 'row',
              marginBottom: 10,
            }}
          >
            <TextBodyRegular style={[Styles.h4]}>{`File(s) uploaded: ${file?.length}`}</TextBodyRegular>
            <TextBodyRegular style={[Styles.h4]}>{`${file}`}</TextBodyRegular>
          </View>
          <Button
            style={[Styles.button]}
            onPress={onUploadAnother}
            icon="chevron-forward"
            theme="fancy"
          >
            Upload another file
          </Button>
        </View>
      ) : (
        <View
          style={{
            alignItems: 'center',
          }}
        >
          <ActivityIndicator
            size="large"
            color="#000"
          />
          <TextBodyMedium style={[Styles.h5, { marginLeft: 10 }]}>Please wait. Your file is being uploaded and processed.</TextBodyMedium>
          <TextBodyRegular style={[Styles.h6, { marginLeft: 10 }]}>Depending on the number of files you uploaded and their file size, this can take 30 seconds to 2 minutes. Thank you for your patience.</TextBodyRegular>
        </View>
      )}
    </View>
  );
};

export default UploadDocumentButton;
