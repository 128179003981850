import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import { ModalContext } from '@src/providers/Modal';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Pressable, View } from 'react-native';
import Colors from '../shared/Colors';
import { TextBodyRegular } from '../shared/StyledText';
import Styles from '../shared/Styles';

interface ButtonProps {
  onPress?: () => void;
  disabled?: boolean;
  theme?: string;
  style?: any;
  fontStyle?: any;
  children?: JSX.Element | string | undefined;
  icon?: string;
  iconSet?: string;
  active?: boolean;
  toolTip?: String;
  modal?: JSX.Element | undefined;
}
const Button = ({ onPress, disabled, style, fontStyle, children, theme, icon, iconSet, active, toolTip, modal }: ButtonProps) => {
  const { onButtonModal, setOnButtonModal } = useContext(ModalContext);
  const modalRef = useRef(null); // Create a ref for the modal

  let iconColor = 'white';

  const [onHover, setOnHover] = useState(false);
  const handleHoverIn = () => {
    setOnHover(true);
  };
  const handleHoverOut = () => {
    setOnHover(false);
  };
  let styles = [Styles.button, style];
  let fontStyles = [Styles.h4, fontStyle];

  if (theme?.includes('fancy')) {
    if (disabled) {
      styles = [
        ...styles,
        {
          paddingHorizontal: 25,
          paddingVertical: 10,
          borderColor: Colors.buttonBackground,
          borderWidth: 2,
          backgroundColor: '#fff',
        },
      ];
      fontStyles = [{ ...Styles.h4, color: '#999' }];
    } else {
      styles.push({
        paddingHorizontal: 25,
        paddingVertical: 10,
        borderWidth: 2,
        backgroundColor: onHover ? Colors.buttonBackground : Colors.buttonBackground,
        borderColor: onHover ? Colors.lightGrayPurple : Colors.buttonBackground,
      });
      fontStyles = [{ ...Styles.h4, color: onHover ? '#fff' : '#fff' }];
    }
  }

  if (theme?.includes('simple')) {
    styles.push(Styles.buttonSimple);
    fontStyles.push({
      fontSize: 14,
      paddingBottom: 3,
      borderBottomWidth: 1,
    });
  }

  if (theme?.includes('small')) {
    styles.push({
      borderWidth: 1,
      paddingHorizontal: 15,
      paddingVertical: 5,
    });
    fontStyles = [{ ...Styles.h6, color: onHover ? '#fff' : '#fff' }];
    iconColor = onHover ? '#fff' : 'fff';
  }

  if (theme?.includes('light') || (!theme && !disabled)) {
    styles.push(Styles.buttonFancy);
  }

  if (theme?.includes('red')) {
    styles.push([{ ...Styles.buttonFancy, backgroundColor: '#FF0000' }]);
  }

  if (active) {
    styles.push({
      backgroundColor: Colors.buttonBackgroundActive,
      cursor: 'default',
      borderWidth: 2,
      borderColor: Colors.buttonBackgroundActive,
    });
  }

  const handleOnPress = () => {
    onPress && onPress();
    if (modal && !onButtonModal) {
      setOnButtonModal(true);
    }
  };

  // Effect to handle clicks outside the modal
  useEffect(() => {
    if (modal) {
      const handleClickOutside = (event: MouseEvent) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          setOnButtonModal(false); // Close the modal if clicked outside
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [modal]);

  return (
    <>
      <Pressable
        ref={modalRef}
        onPress={handleOnPress}
        disabled={disabled}
        style={[styles.map(style => style), { position: 'relative', alignItems: 'center' }]}
        onHoverIn={handleHoverIn}
        onHoverOut={handleHoverOut}
      >
        {onHover && toolTip && (
          <View style={{ alignItems: 'center', position: 'absolute', maxWidth: '120%', bottom: '120%', padding: 10, zIndex: 1000, backgroundColor: Colors.toolTip }}>
            <TextBodyRegular style={[Styles.h7, { color: Colors.darkBlue }]}>{toolTip}</TextBodyRegular>
            <View
              style={{
                position: 'absolute',
                bottom: -10,
                width: 0,
                height: 0,
                borderLeftWidth: 10,
                borderRightWidth: 10,
                borderTopWidth: 10,
                borderTopColor: Colors.toolTip,
                borderLeftColor: 'transparent',
                borderRightColor: 'transparent',
                borderBottomColor: 'transparent',
              }}
            ></View>
          </View>
        )}
        {onButtonModal && modal && <View style={{ alignItems: 'center', position: 'absolute', bottom: '120%', padding: 10, zIndex: 10, backgroundColor: Colors.toolTip }}>{modal}</View>}
        {typeof children === 'string' ? (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <TextBodyRegular style={[fontStyles, { marginHorizontal: 15 }]}>{children}</TextBodyRegular>
            {icon && iconSet === 'MaterialCommunityIcons' ? (
              <MaterialCommunityIcons
                name={icon}
                size={24}
                color="black"
              />
            ) : (
              <Ionicons
                name={icon}
                size={24}
                style={{}}
                color={iconColor}
              />
            )}
          </View>
        ) : (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {children}
            {icon && iconSet === 'MaterialCommunityIcons' ? (
              <MaterialCommunityIcons
                name={icon}
                size={24}
                color="black"
              />
            ) : (
              <Ionicons
                name={icon}
                size={24}
                style={{}}
                color={iconColor}
              />
            )}
          </View>
        )}
      </Pressable>
    </>
  );
};

export default Button;
