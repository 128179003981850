import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql';
import { FontAwesome5 } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { SubmissionPreScreen } from '@src/API';
import Screen from '@src/components/Screen';
import Colors from '@src/components/shared/Colors';
import { TextBodyMedium, TextBodyRegular } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { Pressable, View } from '@src/components/shared/Themed';
import { deleteSubmissionPreScreen } from '@src/graphql/mutations';
import { listSubmissionPreScreens } from '@src/graphql/queries';
import { LoadingContext } from '@src/providers/Loading';
import { generateClient } from 'aws-amplify/api';
import React, { useContext, useEffect, useState } from 'react';

const SPSDashboard = () => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const { addLoading, removeLoading } = useContext(LoadingContext);
  const [list, setList] = useState<SubmissionPreScreen[]>();
  const [onHover, setOnHover] = useState(false);
  const client = generateClient({
    authMode: 'apiKey',
  });

  const getDatabaseList = async () => {
    console.log('GRAPHQL_AUTH_MODE.AWS_IAM', GRAPHQL_AUTH_MODE.AWS_IAM);
    return await client.graphql({
      query: listSubmissionPreScreens,
    });
  };

  const load = async () => {
    addLoading('loadDashboard');
    try {
      const dbList = await getDatabaseList();
      setList(dbList.data.listSubmissionPreScreens.items);
    } catch (err) {
      console.log('error getting database', err);
    } finally {
      removeLoading('loadDashboard');
    }
  };

  const remove = async (id: string) => {
    await client.graphql({
      query: deleteSubmissionPreScreen,
      variables: {
        input: { id },
      },
    });
    // Update the list after removal
    setList(prevList => prevList.filter(item => item.id !== id));
  };

  // useEffect(() => {
  //   const subscription = client
  //     .graphql({
  //       query: onDeleteSubmissionPreScreen,
  //     })
  //     .subscribe({
  //       next: () => {
  //         load(); // Reload the list when a SubmissionPreScreen is deleted
  //       },
  //       error: err => console.error('Subscription error:', err),
  //     });

  //   load(); // Initial load

  //   return () => subscription.unsubscribe(); // Cleanup subscription on unmount
  // }, []);

  const gotoDetails = (id: string) => {
    navigation.navigate('submit', {
      id,
    });
  };

  const gotoCreate = () => {
    navigation.navigate('create');
  };

  const handleHoverIn = () => {
    setOnHover(true);
  };
  const handleHoverOut = () => {
    setOnHover(false);
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <Screen>
      <>
        <View
          style={{
            backgroundColor: Colors.light.container,
            marginTop: '3%',
            marginHorizontal: '3%',
            padding: 30,
          }}
        >
          <TextBodyRegular style={[Styles.h3, { marginBottom: 20, color: Colors.buttonBackground }]}>Dashboard</TextBodyRegular>
          <TextBodyMedium style={[Styles.h6, {}]}>Below is a list of your alignments</TextBodyMedium>
        </View>
        {list && (
          <View
            style={{
              backgroundColor: Colors.light.container,
              marginTop: '3%',
              marginHorizontal: '3%',
              padding: 30,
            }}
          >
            <TextBodyMedium style={[Styles.h3, { marginBottom: 20, color: Colors.buttonBackground }]}>{`Your alignments`}</TextBodyMedium>
            <TextBodyMedium style={[Styles.h5, { marginBottom: 20, color: Colors.buttonBackground }]}>{`Total: ${list.length}`}</TextBodyMedium>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-around',
                flexWrap: 'wrap',
                marginLeft: '-1%',
              }}
            >
              {list.map((listItem, index) => (
                <View
                  style={{
                    width: '22%',
                    minHeight: 100,
                    flexGrow: 1,
                    margin: '1%',
                  }}
                  key={`dashboard-list-item-${index}`}
                >
                  <Pressable
                    key={`dashboard-list-item-${index}`}
                    onPress={() => gotoDetails(listItem.id)}
                    onHoverIn={handleHoverIn}
                    onHoverOut={handleHoverOut}
                    style={{
                      height: '100%',
                      padding: 15,
                      backgroundColor: Colors.lilac,
                    }}
                  >
                    <TextBodyRegular style={[Styles.h5, { color: '#fff' }]}>{listItem.titleLLM.replace(/"/g, '')}</TextBodyRegular>
                  </Pressable>
                  <Pressable
                    onPress={() => remove(listItem.id)}
                    style={{
                      position: 'absolute',
                      bottom: 5,
                      right: 5,
                    }}
                  >
                    <FontAwesome5
                      name="trash"
                      size={24}
                      color="red"
                    />
                  </Pressable>
                </View>
              ))}
            </View>
          </View>
        )}
      </>
    </Screen>
  );
};

export default SPSDashboard;
