import Wrapper from '@src/components/Wrapper';
import Providers from '@src/providers';
import { Amplify } from 'aws-amplify';
import { fetchAuthSession } from 'aws-amplify/auth';
import { ConsoleLogger, Hub } from 'aws-amplify/utils';
import { useFonts } from 'expo-font';
import React from 'react';
import awsExportsProduction from './amplifyconfiguration.json';
import './src/globalStyles.css'; // Import the global styles
const logger = new ConsoleLogger('foo');

console.log('APP root', awsExportsProduction);
// if (process.env.NODE_ENV === 'development') {
//   Amplify.configure(awsExportsLocal);
// }
// try {
//   Amplify.configure({
//     ...awsExportsProduction,
//     Auth: {
//       Cognito: {
//         ...awsExportsProduction.Auth.Cognito,
//         tokenProvider: cognitoUserPoolsTokenProvider,
//       },
//     },
//   });
// } catch (error) {
//   console.log('error amplify configuration:', error);
// }

try {
  Amplify.configure(
    {
      ...awsExportsProduction,
      Auth: {
        // 'Cognito': {
        //   'userPoolId': 'us-east-1_Sp4VgjOVX',
        //   'userPoolClientId': '3en1k17it0sp0ihcv7j4vr0dg',
        // },
        Cognito: {
          identityPoolId: 'banabana2911aaaa_identitypool_2911aaaa__dev',
          allowGuestAccess: true, // set to false to not use the unauthenticated role
        },
      },
    },
    // awsExportsProduction,
    {
      ssr: true,
      API: {
        GraphQL: {
          headers: async () => {
            return {
              Authorization: undefined,
            };
            const session = await fetchAuthSession();
            console.log('session', session);
            const idToken = session.tokens?.idToken?.toString();

            console.log('idToken', idToken);
            if (!idToken) {
              return {
                'guest': 'true',
              };
            }
            return {
              Authorization: idToken,
            };
          },
        },
      },
    },
  );
} catch (error) {
  console.log('error amplify configuration:', error);
  logger.info('info bar');
}

const App = () => {
  Hub.listen('auth', ({ payload }) => {
    switch (payload.event) {
      case 'signedIn':
        console.log('user have been signedIn successfully.');
        break;
      case 'signedOut':
        console.log('user have been signedOut successfully.');
        break;
      case 'tokenRefresh':
        console.log('auth tokens have been refreshed.');
        break;
      case 'tokenRefresh_failure':
        console.log('failure while refreshing auth tokens.');
        break;
      case 'signInWithRedirect':
        console.log('signInWithRedirect API has successfully been resolved.');
        break;
      case 'signInWithRedirect_failure':
        console.log('failure while trying to resolve signInWithRedirect API.');
        break;
      case 'customOAuthState':
        console.info('custom state returned from CognitoHosted UI');
        break;
    }
  });

  const [fontsLoaded] = useFonts({
    // inter
    'inter-regular': require('@src/assets/fonts/Inter-Regular.ttf'),
    'inter-light': require('@src/assets/fonts/Inter-Light.ttf'),
    'inter-semibold': require('@src/assets/fonts/Inter-SemiBold.ttf'),
    'inter-medium': require('@src/assets/fonts/Inter-Medium.ttf'),
    'inter-bold': require('@src/assets/fonts/Inter-Bold.ttf'),
    'inter-extra-bold': require('@src/assets/fonts/Inter-ExtraBold.ttf'),
    //youngserif
    'youngserif-regular': require('@src/assets/fonts/Young_Serif/YoungSerif-Regular.ttf'),
    // bitter
    'bitter-regular': require('@src/assets/fonts/Bitter/static/Bitter-Regular.ttf'),
    'bitter-medium': require('@src/assets/fonts/Bitter/static/Bitter-Medium.ttf'),
  });

  return <Providers>{fontsLoaded && <Wrapper />}</Providers>;
};

export default App;
