import Responsive from '@src/utils/Responsive';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Dimensions, Image, ImageURISource, StyleSheet, View } from 'react-native';
import { TextBodyRegular } from '../shared/StyledText';
import Styles from '../shared/Styles';

interface HeroProps {
  image: ImageURISource;
  title?: string;
  body?: string | JSX.Element;
  children?: JSX.Element;
  loading?: boolean;
}

const Hero = ({ image, title, body, children, loading }: HeroProps) => {
  const [screenWidth, setScreenWidth] = useState(Dimensions.get('window').width);
  const isWideScreen = screenWidth > Responsive.small;

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(Dimensions.get('window').width);
    };

    Dimensions.addEventListener('change', handleResize);

    return () => {
      Dimensions.removeEventListener('change', handleResize);
    };
  }, []);

  return (
    <View style={isWideScreen ? styles.containerWide : styles.container}>
      <Image
        style={isWideScreen ? styles.imageWide : styles.image}
        source={image}
      />
      {title && body && (
        <View style={isWideScreen ? styles.contentWide : styles.content}>
          {!loading && (
            <>
              <View>
                {title && <TextBodyRegular style={[Styles.xl, styles.title]}>{title}</TextBodyRegular>}
                {body && (typeof body === 'string' ? <TextBodyRegular style={[Styles.h4, styles.body]}>{body}</TextBodyRegular> : body)}
              </View>
              <View>{children}</View>
            </>
          )}
          {loading && (
            <View style={styles.loadingContainer}>
              <ActivityIndicator
                size="large"
                color={'#000'}
              />
            </View>
          )}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  containerWide: {
    flex: 1,
    padding: 30,
    flexDirection: 'row',
  },
  image: {
    width: '100%',
    height: 400,
  },
  imageWide: {
    width: '50%',
    height: 400,
  },
  contentWide: {
    width: '50%',
    padding: 30,
  },
  content: {
    width: '100%',
    padding: 20,
  },
  title: {
    letterSpacing: 1,
    lineHeight: 65,
  },
  loadingContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flexGrow: 1,
  },
});

export default Hero;
