import { createMagicTableCellRequestProps } from '@src/components/MagicTable';

export const createMagicTableCellRequest = ({ indexId, namespace, criteria }: createMagicTableCellRequestProps) => {
  const source = {
    requestName: 'question',
    numberOfSources: 50,
    modelName: 'gpt-4o-mini',
    indexId,
    namespace,
    question: `Find source material related to the prompt: ${criteria.query}`,
  };

  const cellContent = {
    modelName: 'gpt-4o-mini',
    templateWrapper: `Return the what is asked in the prompt: "${criteria.query}" in at most 4 words from the <source>{source}</source> material. If answer is not in the source material say "Not Available"`,
  };

  return { getSource: { source }, parrallel: { cellContent } };
};
