import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import SourcesList from '@src/components/BackOffice/Analysis/SourceList';
import CountdownTimer from '@src/components/Countdown';
import Screen from '@src/components/Screen';
import Colors from '@src/components/shared/Colors';
import { TextBodyRegular, TextHeader } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { View } from '@src/components/shared/Themed';
import TaskList from '@src/components/TaskList/index';
import { LoadingContext } from '@src/providers/Loading';
import { uploadFiles } from '@src/utils/FileUpload';
import React, { useContext, useState } from 'react';
import { ActivityIndicator } from 'react-native';
import { DemoContext } from '../Context';

const DemoUploadToPinecone = () => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const { getPineconeIndex } = useContext(DemoContext);
  const { addLoading, removeLoading, loading, checkLoading } = useContext(LoadingContext);
  const [pData, setPData] = useState<any>({});
  const [filesUploaded, setFilesUploaded] = useState(false);
  const handleFileUpload = async (files: FileList | null) => {
    if (files) {
      addLoading('loadPineconeIndex');
      // create pinecone index for this user.  each user should have only 1 index associated with them, where each index includes namespaces that are associated with projects
      // we need to get the indexId and namespace
      const newPineconeData = await getPineconeIndex();
      if (newPineconeData) {
        const indexId = newPineconeData?.indexId;
        const namespace = newPineconeData?.namespace;
        try {
          const fileNames = Array.from(files).map(file => file.name);
          setPData({ indexId, namespace, fileNames });
          console.log('DemoUploadToPinecone fileNames', { indexId, namespace, fileNames });
          const completedFiles = await uploadFiles(files, indexId, namespace);
          setTimeout(() => {
            // hack because sometimes the namespace in pinecone is not ready for search
            setFilesUploaded(true);
            removeLoading('loadPineconeIndex');
          }, 5000);
        } catch (error) {
          removeLoading('loadPineconeIndex');
          console.error('Error uploading file:', error);
        }
      }
    }
  };

  console.log('\n\npData:', pData);

  return (
    <Screen>
      <View
        style={{
          margin: '3%',
          justifyContent: 'space-between',
        }}
      >
        <View
          style={{
            padding: '3%',
            width: '100%',
            backgroundColor: '#fff',
            justifyContent: 'center',
          }}
        >
          <TextBodyRegular style={[Styles.h3, { marginBottom: 30, color: Colors.buttonBackground }]}>Report maker</TextBodyRegular>
          <TextBodyRegular style={[Styles.h5, { marginBottom: 30, width: '65%' }]}>{`This experience is a demo to show you how you could interact with your existing documents on the Baltare platform.  The documents you upload are not saved on a server and will disappear when you close or refresh this window.`}</TextBodyRegular>
        </View>
        {filesUploaded && (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: '3%',
            }}
          >
            <SourcesList
              fileNames={['none']}
              indexId={pData.indexId}
              namespace={pData.namespace}
              filesUploaded={() => {
                console.log('re-run query');
              }}
            />
            <View
              style={{
                padding: '3%',
                paddingBottom: 40,
                backgroundColor: '#fff',
                width: '75%',
              }}
            >
              <TaskList
                indexId={pData.indexId}
                namespace={pData.namespace}
              />
            </View>
          </View>
        )}
        {!checkLoading('loadPineconeIndex') && !filesUploaded && (
          <View
            style={{
              padding: '3%',
              marginTop: '3%',
              width: '40%',
              backgroundColor: '#fff',
            }}
          >
            <TextBodyRegular style={[Styles.h4, { marginBottom: 20, color: Colors.buttonBackground }]}>Start here</TextBodyRegular>
            <TextBodyRegular style={[Styles.h5, { marginBottom: 30 }]}>Upload a report or combination of reports related to a single project that you would like to generate an action plan for.</TextBodyRegular>
            {loading && (
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <TextBodyRegular style={[Styles.h4, { marginRight: 30 }]}>Uploading...</TextBodyRegular>
                <ActivityIndicator
                  size="large"
                  color="black"
                />
              </View>
            )}
            {!loading && <UploadDocumentButton onPress={handleFileUpload} />}
          </View>
        )}
        {checkLoading('loadPineconeIndex') && !filesUploaded && (
          <>
            <View
              style={{
                padding: '3%',
                marginVertical: '3%',
                backgroundColor: '#fff',
                borderRadius: 5,
                width: '100%',
              }}
            >
              <View style={{}}>
                <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 20 }}>
                  <ActivityIndicator
                    size={20}
                    color="black"
                  />
                  <TextHeader style={[Styles.h4, { marginLeft: 10 }]}>Loading</TextHeader>
                </View>
                <TextBodyRegular style={[Styles.h6, { marginTop: 20, width: '50%' }]}>Please wait. This can take 30 seconds depending on the number of files.</TextBodyRegular>
                <CountdownTimer
                  size={14}
                  initialTime={30}
                />
              </View>
            </View>
          </>
        )}
      </View>
    </Screen>
  );
};

export default DemoUploadToPinecone;
