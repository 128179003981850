import * as React from 'react';
import { Text, TextProps } from './Themed';

export function TextBodyRegular(props: TextProps) {
  const { style } = props;
  return (
    <Text
      {...props}
      style={[style, { fontFamily: 'bitter-regular' }]}
    />
  );
}

export function TextBodyMedium(props: TextProps) {
  const { style } = props;
  return (
    <Text
      {...props}
      style={[style, { fontFamily: 'bitter-medium' }]}
    />
  );
}

export function TextHeader(props: TextProps) {
  const { style } = props;
  return (
    <Text
      {...props}
      style={[style, { fontFamily: 'youngserif-regular' }]}
    />
  );
}

export function TextInterExtraBold(props: TextProps) {
  const { style } = props;
  return (
    <Text
      {...props}
      style={[style, { fontFamily: 'inter-extrabold' }]}
    />
  );
}

export function TextInterSemiBold(props: TextProps) {
  const { style } = props;
  return (
    <Text
      {...props}
      style={[style, { fontFamily: 'inter-semibold' }]}
    />
  );
}

export function TextInterRegular(props: TextProps) {
  const { style } = props;
  return (
    <Text
      {...props}
      style={[style, { fontFamily: 'inter-regular' }]}
    />
  );
}
export function TextInterMedium(props: TextProps) {
  const { style } = props;
  return (
    <Text
      {...props}
      style={[style, { fontFamily: 'inter-medium' }]}
    />
  );
}
