import Hero from '@src/components/Hero';
import { getTranslation } from '@src/services/i18n';
import React from 'react';
import { View } from 'react-native';

const MyHeader: React.FC<any> = () => {
  const onClickButton = () => {
    window.location.href = 'https://calendly.com/baltare/meeting';
  };
  return (
    <>
      <View
        style={{
          position: 'relative',
        }}
      >
        <Hero image={require('@assets/images/screening.png')} title={getTranslation('project.dashboard.hero.title')} body={getTranslation('project.dashboard.hero.body')}></Hero>
      </View>
    </>
  );
};

export default MyHeader;
