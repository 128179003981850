import Button from '@src/components/Button';
import { TextBodyMedium, TextBodyRegular } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { View } from '@src/components/shared/Themed';
import useResponsive from '@src/hooks/useResponsive';
import { LoadingContext } from '@src/providers/Loading';
import { ProjectContext } from '@src/providers/Project';
import { uploadFiles } from '@src/utils/FileUpload';
import React, { useContext } from 'react';
import { ActivityIndicator } from 'react-native';

const baseUrl = 'https://banabanabucket9571c-dev.s3.amazonaws.com/';

const DocumentManager = () => {
  const { currentProject, saveProject } = useContext(ProjectContext);
  const { loading, addLoading, removeLoading } = useContext(LoadingContext);
  const [showDocuments, setShowDocuments] = React.useState(false);
  const { isWideScreen } = useResponsive();

  const toggleDocuments = () => {
    setShowDocuments(!showDocuments);
  };

  const handleFileUpload = async (files: FileList | null) => {
    if (files) {
      addLoading('uploadFile');
      try {
        if (currentProject) {
          await uploadFiles(files, currentProject.indexId, currentProject?.namespace);
          let documentNames = [];
          for (let i = 0; i < files.length; i++) {
            documentNames.push(files[i].name);
          }
          if (currentProject?.documents) {
            saveProject({
              documents: currentProject?.documents?.concat(documentNames),
            });
          } else {
            saveProject({
              documents: documentNames,
            });
          }
        }
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        removeLoading('uploadFile');
      }
    }
  };

  return (
    <>
      <View
        style={{
          padding: isWideScreen ? '5%' : 20,
          backgroundColor: '#fff',
          borderRadius: 5,
        }}
      >
        <TextBodyMedium style={[Styles.h3, { marginBottom: 10 }]}>Source material</TextBodyMedium>
        <TextBodyRegular style={[Styles.h6]}>Upload files below to add it to your data set for analysis.</TextBodyRegular>
        {currentProject && (
          <View style={{ alignSelf: 'center', marginVertical: 30 }}>
            {!loading && <UploadDocumentButton onPress={handleFileUpload} />}
            {loading && (
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <TextBodyRegular style={[Styles.h4, { marginRight: 30 }]}>Uploading...</TextBodyRegular>
                <ActivityIndicator
                  size="large"
                  color="black"
                />
              </View>
            )}
          </View>
        )}
        {currentProject && currentProject.documents?.length !== 0 && <TextBodyRegular style={[Styles.h5, { marginVertical: 10 }]}>{`Total documents: ${currentProject.documents?.length}`}</TextBodyRegular>}
        {currentProject && currentProject.documents?.length !== 0 && (
          <View style={{}}>
            <View
              style={{
                alignSelf: 'flex-end',
                marginTop: 10,
              }}
            >
              <Button
                onPress={toggleDocuments}
                icon={`chevron-${showDocuments ? 'up' : 'down'}`}
                theme="simple"
              >{`${showDocuments ? 'Hide' : 'Show'} documents`}</Button>
            </View>
            <View>
              {showDocuments &&
                currentProject.documents?.map((doc: any, i: number) => (
                  <View
                    key={`document-number-${i}`}
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    <TextBodyRegular style={[Styles.h6, { marginBottom: 10 }]}>{`${i + 1}. ${baseUrl}${doc}`}</TextBodyRegular>
                  </View>
                ))}
            </View>
          </View>
        )}
      </View>
    </>
  );
};

export default DocumentManager;
