import React from 'react';
import { ScrollView } from 'react-native';
import Colors from '../shared/Colors';
import { View } from '../shared/Themed';

interface ScreenProps {
  children: JSX.Element;
}
const Screen = ({ children }: ScreenProps) => {
  return (
    <ScrollView
      contentContainerStyle={{
        width: '100%',
        minHeight: '100%',
        backgroundColor: Colors.light.background,
      }}
    >
      <View
        style={{
          flex: 1,
          flexGrow: 1,
          paddingBottom: 100,
          width: '95%',
          margin: 'auto',
        }}
      >
        {children}
      </View>
    </ScrollView>
  );
};

export default Screen;
