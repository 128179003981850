import Colors from '@src/components/shared/Colors';
import { TextInterMedium, TextInterRegular } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { Pressable, View } from '@src/components/shared/Themed';
import React, { useState } from 'react';

interface AlignmentItemProps {
  task: any;
  index: number;
  onPress: () => void;
}

const AlignmentItem = ({ task, index, onPress }: AlignmentItemProps) => {
  const { name, summary } = task;

  const randomColor = React.useMemo(() => {
    const colors = [Colors.orangePastel, Colors.green, Colors.red];
    return colors[Math.floor(Math.random() * colors.length)];
  }, []);

  const [onHover, setOnHover] = useState(false);
  const handleHoverIn = () => {
    setOnHover(true);
  };
  const handleHoverOut = () => {
    setOnHover(false);
  };

  return (
    <Pressable
      onPress={onPress}
      onHoverIn={handleHoverIn}
      onHoverOut={handleHoverOut}
      style={{
        borderWidth: 2,
        borderColor: onHover ? Colors.lightGrayPurple : Colors.lilac,
        backgroundColor: Colors.lilac,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            alignItems: 'center',
            flexDirection: 'row',
            padding: 15,
            width: '80%',
          }}
        >
          <View
            style={{
              backgroundColor: '#fff',
              width: 30,
              height: 30,
              marginRight: 15,
              borderRadius: 30,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <TextInterRegular style={[Styles.h7]}>{index + 1}</TextInterRegular>
          </View>
          <TextInterMedium style={[Styles.h5, { color: '#fff' }]}>{name}</TextInterMedium>
        </View>
        <View
          style={{
            flex: 1, // This allows the View to fill the height of the parent
            alignSelf: 'flex-end',
            width: '20%',
            height: '100%',
            backgroundColor: randomColor,
          }}
        />
      </View>
    </Pressable>
  );
};

export default AlignmentItem;
