/**
 * Modal Context
 *
 * The purpose of this Context is to support moving a component to the top of all elements and positioning it inside of a modal
 */
import React, { PropsWithChildren, useMemo, useState } from 'react';

export type ModalContextProps = {
  modalContent: any;
  dimensions: any;
  setModalContent: (content: any) => void;
  onButtonModal: boolean;
  setOnButtonModal: (_: any) => void;
};

export const ModalContext = React.createContext<ModalContextProps>({
  modalContent: undefined,
  dimensions: undefined,
  setModalContent: _ => {},
  onButtonModal: false,
  setOnButtonModal: _ => {},
});

export const { Consumer } = ModalContext;
export default Consumer;

export function Provider({ children }: PropsWithChildren<Record<symbol, symbol>>) {
  const [modalContent, setModalContent] = useState();
  const [dimensions, setDimensions] = useState();
  const [onButtonModal, setOnButtonModal] = useState(false);

  const contextValue: ModalContextProps = useMemo(() => ({ modalContent, dimensions, setModalContent, setDimensions, onButtonModal, setOnButtonModal }), [modalContent, dimensions, setModalContent, setDimensions, onButtonModal, setOnButtonModal]);

  return <ModalContext.Provider value={contextValue}>{children}</ModalContext.Provider>;
}
