import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import Button from '@src/components/Button';
import Input from '@src/components/Input';
import { TextBodyMedium, TextBodyRegular, TextHeader } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { getTranslation } from '@src/services/i18n';
import { createPineconeIndex } from '@src/utils/Pinecone';
import { signUp } from 'aws-amplify/auth';
import React, { useState } from 'react';
import { View } from 'react-native';

// TODO this component isnt being used.  see providers/Authentication.tsx.  it could be in the future, for a custom account creation screen
const MySignUp: React.FC<any> = ({ toSignIn }) => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();

  const gotoTerms = () => {
    navigation.navigate('terms');
  };

  const [step, setStep] = useState('signup');
  const [name, setName] = useState('');
  const [inviteCode, setInviteCode] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [companyPosition, setCompanyPosition] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [country, setCountry] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userGroup, setUserGroup] = useState('');
  const [error, setError] = useState('');
  const [acceptPrivacySwitch, setAcceptPrivacySwitch] = useState<boolean>(false);
  const [acceptTermsSwitch, setAcceptTermsSwitch] = useState<boolean>(false);
  function togglePrivacySwitch(val: boolean) {
    setAcceptPrivacySwitch(val);
  }
  function toggleTermsSwitch(val: boolean) {
    setAcceptTermsSwitch(val);
  }

  const validInvitationCodes = ['ALPHA']; // Example codes

  const handleSignUp = async () => {
    if (!validInvitationCodes.includes(inviteCode)) {
      console.error('Invalid invitation code');
      setError('Invalid invitation code');
      return;
    }

    // the ID that will be used by pinecone to determine which index this user is associated.  this is used in conjunction with the namespace
    const indexId = `${name.replace(/[^a-zA-Z0-9_-]/g, '-').toLowerCase()}-${Date.now()}`;

    try {
      // TODO we need to figure out how to configure the "userGroup"
      // if (!email) {
      //   setError('Your email is required');
      //   return;
      // }
      // if (!password) {
      //   setError('Password is required');
      //   return;
      // }
      // if (!companyName) {
      //   setError("Please enter the company's name");
      //   return;
      // }
      // if (!companyWebsite) {
      //   setError("Please enter the company's website");
      //   return;
      // }
      // if (!companyPosition) {
      //   setError('Please enter your position at the company you work for.');
      //   return;
      // }
      // if (!name) {
      //   setError('name is required');
      //   return;
      // }
      // if (!lastName) {
      //   setError('lastName is required');
      //   return;
      // }
      // if (!phoneNumber) {
      //   setError('phoneNumber is required');
      //   return;
      // }
      // if (!userGroup) {
      //   setError('userGroup is required');
      //   return;
      // }

      await signUp({
        username: email.toLowerCase(),
        password,
        options: {
          userAttributes: {
            name: name,
            'custom:inviteCode': inviteCode,
            'custom:indexId': indexId,
          },
        },
      });
    } catch (error) {
      console.error('Error signing up:', error);
    }

    try {
      // create pinecone index for this user.  each user should have only 1 index associated with them, where each index includes namespaces that are associated with projects
      createPineconeIndex(indexId);

      // reveal the new screen that shows success and suggest the user to check their emails for the verification link
      setStep('verify');
      // TODO update the user model to the verify step so if the user hasn't verified yet, they dont see the signup flow
    } catch (error) {
      console.error('Error creating pinecone index:', error);
    }
  };

  return (
    <View
      style={{
        marginTop: 24,
        padding: 24,
      }}
    >
      {step === 'signup' && (
        <>
          <View>
            <TextHeader style={[Styles.h2]}>{getTranslation('auth.create.title')}</TextHeader>
            <Button
              style={[
                {
                  marginTop: 20,
                  width: '35%',
                  alignSelf: 'flex-start',
                },
              ]}
              theme={'simple'}
              onPress={toSignIn}
            >
              <TextBodyRegular style={{}}>{`< Go back to Sign In`}</TextBodyRegular>
            </Button>
          </View>
          <View
            style={{
              marginVertical: 45,
            }}
          >
            <View style={{ marginTop: 10 }}>
              <TextBodyMedium style={Styles.h4}>Enter your invitation code:</TextBodyMedium>
              <Input
                style={Styles.input}
                placeholder="Invitation code"
                onChangeText={text => setInviteCode(text)}
                value={inviteCode}
              />
            </View>
            <View style={{ marginTop: 10 }}>
              <TextBodyMedium style={Styles.h4}>Enter your name below:</TextBodyMedium>
              <Input
                style={Styles.input}
                placeholder="Name"
                onChangeText={text => setName(text)}
                value={name}
              />
            </View>
            <View style={{ marginTop: 10 }}>
              <TextBodyMedium style={Styles.h4}>Enter your email below:</TextBodyMedium>
              <Input
                style={Styles.input}
                placeholder="Email"
                onChangeText={text => setEmail(text)}
                value={email}
              />
            </View>
            <View style={{ marginTop: 10 }}>
              <TextBodyMedium style={Styles.h4}>Create a password for your account:</TextBodyMedium>
              <Input
                style={Styles.input}
                placeholder="Password"
                secureTextEntry
                onChangeText={text => setPassword(text)}
                value={password}
              />
            </View>
            {error.length > 0 && (
              <View style={{ marginTop: 10 }}>
                <TextHeader
                  style={[
                    Styles.h4,
                    {
                      color: 'red',
                      marginTop: 30,
                    },
                  ]}
                >
                  {error}
                </TextHeader>
              </View>
            )}
          </View>
          <View
            style={{
              width: '100%',
              justifyContent: 'space-between',
              marginTop: 10,
              marginBottom: 30,
            }}
          >
            <Button
              icon="chevron-forward"
              theme="fancy"
              style={[
                Styles.button,
                {
                  width: '35%',
                },
              ]}
              onPress={handleSignUp}
            >
              <TextBodyRegular style={{}}>Sign Up</TextBodyRegular>
            </Button>
          </View>
        </>
      )}
      {step === 'verify' && (
        <View
          style={{
            alignItems: 'flex-start',
          }}
        >
          <TextHeader style={[Styles.h2, { marginBottom: 30 }]}>{getTranslation('auth.create.verify.title')}</TextHeader>
          <TextBodyMedium style={[Styles.h5, { marginBottom: 30 }]}>{getTranslation('auth.create.verify.body')}</TextBodyMedium>
          <Button
            icon="chevron-forward"
            theme="fancy"
            style={[
              {
                width: '40%',
                marginTop: 10,
                marginBottom: 30,
              },
            ]}
            onPress={toSignIn}
          >
            <TextBodyRegular style={{}}>Go back to Sign In</TextBodyRegular>
          </Button>
        </View>
      )}
    </View>
  );
};

export default MySignUp;
