import Input from '@src/components/Input';
import { TextBodyMedium, TextBodyRegular } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { createMagicTableCellRequest } from '@src/prompts/MagicTableCell';
import { ModalContext } from '@src/providers/Modal';
import { getTranslation } from '@src/services/i18n';
import React, { useContext, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { ColumnCriteriaPrompt } from '.';
import Button from '../Button';

interface AddColumnModalProps {
  setCriteriaColumns: React.Dispatch<React.SetStateAction<ColumnCriteriaPrompt[]>>;
  criteriaColumns: ColumnCriteriaPrompt[];
}

// TODO what would be super cool is to include the prmopt builder in here: see BackOffice Analysis

const AddColumnModal = ({ criteriaColumns, setCriteriaColumns }: AddColumnModalProps) => {
  const [columnName, setColumnName] = useState('');
  const { setModalContent } = useContext(ModalContext);

  const closeModal = () => {
    setModalContent(undefined);
  };

  const onAddColumn = () => {
    const newCriteriaColumn = {
      type: 'task', // this is determined by the user
      label: columnName,
      query: columnName,
      prompt: createMagicTableCellRequest,
    };
    const newCriteriaColumns = [...criteriaColumns, newCriteriaColumn];
    setCriteriaColumns(newCriteriaColumns);
    closeModal();
  };

  return (
    <ScrollView
      style={{
        position: 'absolute',
        backgroundColor: '#fff',
        borderRadius: 20,
        padding: 20,
        left: '20%',
        top: '20%',
        bottom: '20%',
        width: '60%',
      }}
    >
      <View
        style={{
          padding: 20,
          paddingTop: 50,
          height: 200,
        }}
      >
        <Button
          theme="fancySmall"
          style={{ position: 'absolute', top: 20, right: 20, paddingVertical: 6, paddingHorizontal: 8 }}
          onPress={closeModal}
          icon="close"
        >
          <></>
        </Button>
        <TextBodyMedium style={[Styles.h3, { marginBottom: 10 }]}>Add Column</TextBodyMedium>
        <TextBodyRegular style={[Styles.h6, { marginBottom: 10 }]}>Ask any question to extract insights across all your organization simultaneously.</TextBodyRegular>
        <TextBodyRegular style={[Styles.h6, { marginBottom: 10 }]}>The more precise you are, the better the result!</TextBodyRegular>
        <Input
          style={[{ ...Styles.input, marginVertical: 40 }]}
          placeholder=""
          onChangeText={setColumnName}
          multiline={true}
          numberOfLines={2}
          value={columnName}
        />
        <Button
          style={{ alignSelf: 'flex-start' }}
          onPress={onAddColumn}
          theme="fancy"
        >
          <TextBodyRegular style={[Styles.h6, { color: '#fff' }]}>{getTranslation('buttons.save')}</TextBodyRegular>
        </Button>

        <TextBodyRegular style={[Styles.h6, { marginVertical: 20 }]}>This functionality allows you to create a new dimension to look at your data and dig deep!</TextBodyRegular>
      </View>
    </ScrollView>
  );
};

export default AddColumnModal;
