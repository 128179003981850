import { Ionicons } from '@expo/vector-icons';
import { useRoute } from '@react-navigation/native';
import { SubmissionPreScreen } from '@src/API';
import Button from '@src/components/Button';
import Input from '@src/components/Input';
import Screen from '@src/components/Screen';
import Colors from '@src/components/shared/Colors';
import { TextBodyMedium, TextBodyRegular, TextHeader, TextInterMedium, TextInterRegular, TextInterSemiBold } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { View } from '@src/components/shared/Themed';
import TaskModal from '@src/components/TaskList/TaskModal';
import UploadDocumentButton from '@src/components/UploadDocumentButton';
import { getSubmissionPreScreen } from '@src/graphql/queries';
import { LoadingContext } from '@src/providers/Loading';
import { ModalContext } from '@src/providers/Modal';
import { getTranslation } from '@src/services/i18n';
import isValidJson from '@src/utils/isValidJson';
import { generateClient } from 'aws-amplify/api';
import React, { useContext, useEffect, useState } from 'react';
import { ActivityIndicator } from 'react-native';
import AlignmentItem from './AlignmentItem';
import EmailCapture from './EmailCapture';

const SPSSubmit = () => {
  const route = useRoute();
  const client = generateClient({
    authMode: 'apiKey',
  });
  const { addLoading, removeLoading, checkLoading } = useContext(LoadingContext);
  const { setModalContent } = useContext(ModalContext);
  const [data, setData] = useState<SubmissionPreScreen | null>();
  const id = route?.params?.id;

  const load = async () => {
    addLoading('loadData');
    try {
      const { data } = await client.graphql({
        query: getSubmissionPreScreen,
        variables: {
          id,
        },
      });
      setData(data.getSubmissionPreScreen);
    } catch (err) {
      console.log('err', err);
    } finally {
      removeLoading('loadData');
    }
  };

  const openTask = (task: any) => {
    // todo we need to run some queries while this modal is open.
    if (data) {
      setModalContent(
        <TaskModal
          task={task}
          indexId={data.indexId}
          namespace={data.namespace}
        />,
      );
    }
  };

  const onAdd = (event?: FileList | null) => {
    setModalContent(<EmailCapture />);
  };

  const alignmentStateDeliverables = data?.deliverablesLLM;
  const tasks = alignmentStateDeliverables && typeof alignmentStateDeliverables === 'string' && alignmentStateDeliverables.includes('{') && alignmentStateDeliverables.trim().startsWith('{') && isValidJson(alignmentStateDeliverables) && JSON.parse(alignmentStateDeliverables).tasks;

  useEffect(() => {
    load();
  }, []);

  return (
    <>
      <Screen>
        <View
          style={{
            marginBottom: 150,
          }}
        >
          {!checkLoading('loadData') ? (
            <>
              <View
                style={{
                  backgroundColor: Colors.light.container,
                  marginTop: '3%',
                  marginHorizontal: '3%',
                  padding: 30,
                }}
              >
                <>
                  {data && (
                    <View
                      style={{
                        width: '60%',
                      }}
                    >
                      <TextHeader style={[Styles.h2, { marginBottom: 20 }]}>{`What is this?`}</TextHeader>
                      <TextInterMedium style={[Styles.h5, { marginBottom: 30 }]}>{`This is a unique URL that includes a list of tasks to be completed.  As information is uploaded and gathered, the tasks below will automatically update their status.`}</TextInterMedium>
                      <TextInterMedium style={[Styles.h5, { marginBottom: 30 }]}>{`You are able to ask questions of the requirements to learn about ways in which they could be completed, such as what documents to upload or facts to provide.`}</TextInterMedium>
                      <TextInterMedium style={[Styles.h5, { marginBottom: 30 }]}>{`The documents uploaded are stored securely and are only visible to the person who uploaded it and the person who created this unique "alignment".`}</TextInterMedium>
                      <TextInterMedium style={[Styles.h5, { marginBottom: 30 }]}>{`Please only submit documents associated with the outstanding requirements below.  (Learn more about how we manage unrelated documents by contacting us)`}</TextInterMedium>
                      <TextInterMedium style={[Styles.h5, { marginBottom: 30 }]}>{`A list of uploaded documents will allow you to add or remove documents from the alignment.`}</TextInterMedium>
                      <TextInterMedium style={[Styles.h5, { marginBottom: 30 }]}>{`Once all requirements are completed and verified, a submit button will appear allowing you to send "${data.owner}" your submission.`}</TextInterMedium>
                      <TextInterMedium style={[Styles.h5, { marginBottom: 0 }]}>{`You can share this link with others who may have answers.`}</TextInterMedium>
                    </View>
                  )}
                </>
              </View>

              {data && !!data.titleLLM && (
                <View
                  style={{
                    backgroundColor: Colors.light.container,
                    marginTop: '3%',
                    marginHorizontal: '3%',
                    padding: 30,
                  }}
                >
                  <View
                    style={{
                      borderBottomColor: '#fff',
                      paddingBottom: 20,
                      marginBottom: 20,
                      borderBottomWidth: 1,
                    }}
                  >
                    <TextBodyMedium style={[Styles.h4, { color: Colors.lilac }]}>Subject</TextBodyMedium>
                  </View>
                  <TextInterSemiBold style={[Styles.h3, { marginVertical: 20, color: Colors.darkBlue }]}>{data.titleLLM}</TextInterSemiBold>
                </View>
              )}

              {data && !!data.requirementsLLM && (
                <View
                  style={{
                    backgroundColor: Colors.light.container,
                    marginTop: '3%',
                    marginHorizontal: '3%',
                    padding: 30,
                  }}
                >
                  <View
                    style={{
                      borderBottomColor: '#fff',
                      paddingBottom: 20,
                      marginBottom: 20,
                      borderBottomWidth: 1,
                    }}
                  >
                    <TextBodyMedium style={[Styles.h4, { marginBottom: 10, color: Colors.lilac }]}>Current status of the alignment</TextBodyMedium>
                    <TextBodyRegular style={[Styles.h6, {}]}>A quick overview of the current status of the alignment, including outstanding issues and recently incorporated details.</TextBodyRegular>
                  </View>
                  <TextInterRegular style={[Styles.h5, { marginBottom: 20, color: Colors.darkBlue }]}>{data.requirementsLLM}</TextInterRegular>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      borderTopWidth: 1,
                      borderTopColor: '#fff',
                    }}
                  >
                    <View
                      style={{
                        width: '45%',
                      }}
                    >
                      <TextBodyRegular style={[Styles.h7, { marginVertical: 20 }]}>Chat with the alignment below 🗣️🤖</TextBodyRegular>
                      <Input
                        height={100}
                        style={[Styles.input, {}]}
                        placeholder="Learn more about or add information to the alignment"
                        placeholderTextColor="#aaa"
                        onChangeText={() => {}}
                        value={''}
                        multiline={true}
                        numberOfLines={5}
                      />
                      <View
                        style={{
                          marginTop: 20,
                          alignSelf: 'center',
                        }}
                      >
                        <Button
                          theme="fancy"
                          icon="add"
                          onPress={onAdd}
                        >
                          {getTranslation('buttons.submit')}
                        </Button>
                      </View>
                    </View>
                    <View
                      style={{
                        width: '45%',
                      }}
                    >
                      <TextBodyRegular style={[Styles.h7, { marginVertical: 20 }]}>Instead of adding information in the text box to the left, you can also upload documentation directly to the alignment that may satisfy the requirements. If multiple requirements are answered by a single upload, all of those requirements will update accordingly. </TextBodyRegular>
                      <UploadDocumentButton onPress={onAdd} />
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginTop: 20,
                        }}
                      >
                        <Ionicons
                          name="lock-closed"
                          size={18}
                        />
                        <TextBodyRegular style={[Styles.h7, { color: Colors.darkBlue, marginLeft: 10 }]}>{`The documents you upload are stored securely.  To learn more, please see our Privacy Policy.`}</TextBodyRegular>
                      </View>
                    </View>
                  </View>
                </View>
              )}

              {tasks && (
                <View
                  style={{
                    backgroundColor: Colors.light.container,
                    marginTop: '3%',
                    marginHorizontal: '3%',
                    padding: 30,
                  }}
                >
                  <View
                    style={{
                      borderBottomColor: '#fff',
                      paddingBottom: 20,
                      borderBottomWidth: 1,
                      marginBottom: 20,
                    }}
                  >
                    <TextBodyMedium style={[Styles.h4, { marginBottom: 10, color: Colors.lilac }]}>Requirements to be completed</TextBodyMedium>
                    <TextBodyRegular style={[Styles.h6, {}]}>Each item in the list below automatically updates as new information is added to the alignment. Click any to learn more about their status.</TextBodyRegular>
                  </View>
                  {tasks.map((requirement, index) => {
                    return (
                      <View
                        key={`review-requirement-${index}`}
                        style={{
                          marginBottom: 20,
                        }}
                      >
                        <AlignmentItem
                          onPress={() => openTask(requirement)}
                          index={index}
                          task={requirement}
                        />
                      </View>
                    );
                  })}
                </View>
              )}
              <View
                style={{
                  backgroundColor: Colors.light.container,
                  marginTop: '3%',
                  marginHorizontal: '3%',
                  padding: 30,
                }}
              >
                <View
                  style={{
                    borderBottomColor: '#fff',
                    paddingBottom: 20,
                    marginBottom: 20,
                    borderBottomWidth: 1,
                  }}
                >
                  <TextBodyMedium style={[Styles.h4, { marginBottom: 10, color: Colors.lilac }]}>Documents uploaded</TextBodyMedium>
                  <TextBodyRegular style={[Styles.h6, {}]}>This is temporary and only for visual purposes. The list of files is not currently connected to those that are uploaded.</TextBodyRegular>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 20,
                  }}
                >
                  <Ionicons
                    name="document"
                    size={24}
                  />
                  <TextBodyRegular style={[Styles.h5, { marginLeft: 10, color: Colors.darkBlue }]}>1. Lease agreement</TextBodyRegular>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 20,
                  }}
                >
                  <Ionicons
                    name="document"
                    size={24}
                  />
                  <TextBodyRegular style={[Styles.h5, { marginLeft: 10, color: Colors.darkBlue }]}>2. Landlord reference</TextBodyRegular>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 20,
                  }}
                >
                  <Ionicons
                    name="document"
                    size={24}
                  />
                  <TextBodyRegular style={[Styles.h5, { marginLeft: 10, color: Colors.darkBlue }]}>3. Paystub</TextBodyRegular>
                </View>
              </View>
              <View
                style={{
                  backgroundColor: Colors.light.container,
                  marginTop: '3%',
                  marginHorizontal: '3%',
                  padding: 30,
                }}
              >
                <View
                  style={{
                    borderBottomColor: '#fff',
                    paddingBottom: 20,
                    borderBottomWidth: 1,
                    marginBottom: 20,
                  }}
                >
                  <TextBodyMedium style={[Styles.h4, { marginBottom: 10, color: Colors.lilac }]}>Contributors</TextBodyMedium>
                  <TextBodyRegular style={[Styles.h6, {}]}>Below is a list of individuals who submitted information.</TextBodyRegular>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 20,
                  }}
                >
                  <Ionicons
                    name="person"
                    size={24}
                  />
                  <TextBodyRegular style={[Styles.h5, { marginLeft: 10, color: Colors.darkBlue }]}>team@baltare.com</TextBodyRegular>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 20,
                  }}
                >
                  <Ionicons
                    name="person"
                    size={24}
                  />
                  <TextBodyRegular style={[Styles.h5, { marginLeft: 10, color: Colors.darkBlue }]}>Adriaan Balt</TextBodyRegular>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 20,
                  }}
                >
                  <Ionicons
                    name="person"
                    size={24}
                  />
                  <TextBodyRegular style={[Styles.h5, { marginLeft: 10, color: Colors.darkBlue }]}>Peter Nakitare</TextBodyRegular>
                </View>
              </View>
            </>
          ) : (
            <View
              style={{
                backgroundColor: Colors.light.container,
                marginTop: '3%',
                marginHorizontal: '3%',
                padding: 30,
              }}
            >
              <TextBodyRegular style={[Styles.h5, { marginBottom: 0 }]}>Please wait. We are loading the requirements for your submission.</TextBodyRegular>
              <ActivityIndicator
                size={36}
                color={Colors.lilac}
              />
            </View>
          )}
        </View>
      </Screen>
    </>
  );
};

export default SPSSubmit;
