import { useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import Colors from '@src/components/shared/Colors';
import { TextBodyRegular, TextHeader } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { View } from '@src/components/shared/Themed';
import { LoadingContext } from '@src/providers/Loading';
import { useContext, useState } from 'react';
import TaskList from '..';
import Button from '../../Button';
import Screen from '../../Screen';

const TaskListDemo = () => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const route = useRoute();
  const { addLoading, removeLoading, checkLoading } = useContext(LoadingContext);
  const [response, setResponse] = useState<any>();

  // if (route?.params?.indexId && route?.params?.namespace) {
  const indexId = route?.params?.indexId;
  const namespace = route?.params?.namespace;
  // }

  const goBack = () => {
    navigation.navigate('sustainability');
  };

  return (
    <Screen>
      <>
        <View
          style={{
            margin: '3%',
            justifyContent: 'space-between',
          }}
        >
          <View
            style={{
              padding: '3%',
              width: '100%',
              backgroundColor: '#fff',
              justifyContent: 'center',
            }}
          >
            <Button
              style={{ alignSelf: 'flex-start' }}
              icon="chevron-forward"
              theme="fancy"
              onPress={goBack}
            >
              Back to the all data table
            </Button>
            <View style={{ marginTop: 30, borderBottomWidth: 1, borderBottomColor: '#eee', paddingBottom: 30 }}>
              <TextHeader style={[Styles.h2, { marginBottom: 30, color: Colors.buttonBackground }]}>Your action plan and progress assessment!</TextHeader>
              <TextBodyRegular style={[Styles.h6, { marginBottom: 10 }]}>The list of tasks below are based on the documents you uploaded for this report. Each task includes a summary and progress. If you open the task a detailed summary and optional email will be created.</TextBodyRegular>
            </View>
          </View>
          {indexId && namespace && (
            <View
              style={{
                paddingBottom: '3%',
                paddingHorizontal: '3%',
                width: '100%',
                backgroundColor: '#fff',
                justifyContent: 'center',
              }}
            >
              <TaskList
                indexId={indexId}
                namespace={namespace}
              />
            </View>
          )}
        </View>
      </>
    </Screen>
  );
};

export default TaskListDemo;
