import Button from '@src/components/Button';
import { TextBodyRegular } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { ModalContext } from '@src/providers/Modal';
import { getTranslation } from '@src/services/i18n';
import React, { useContext } from 'react';
import { View } from 'react-native';

interface SaveEditableInputModalProps {
  onYes: () => void;
  onNo: () => void;
}

// TODO what would be super cool is to include the prmopt builder in here: see BackOffice Analysis

const SaveEditableInputModal = ({ onYes, onNo }: SaveEditableInputModalProps) => {
  const { setOnButtonModal } = useContext(ModalContext);
  const handleOnNo = () => {
    setOnButtonModal(false);
    if (onNo) onNo();
  };
  const handleOnYes = () => {
    if (onYes) onYes();
  };
  return (
    <View
      style={{
        padding: 10,
      }}
    >
      <TextBodyRegular style={[Styles.h7, { marginBottom: 10 }]}>Do you want to update the other areas of your alignment? (summary, list, email)</TextBodyRegular>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Button
          onPress={handleOnNo}
          theme="fancySmall"
          style={{
            marginRight: 15,
          }}
        >
          {getTranslation('buttons.no')}
        </Button>
        <Button
          onPress={handleOnYes}
          theme="fancySmall"
        >
          {getTranslation('buttons.yes')}
        </Button>
      </View>
    </View>
  );
};

export default SaveEditableInputModal;
