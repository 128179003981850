import MagicTable, { ColumnCriteriaPrompt } from '@src/components/MagicTable';
import Screen from '@src/components/Screen';
import Colors from '@src/components/shared/Colors';
import { TextBodyRegular } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { View } from '@src/components/shared/Themed';
import { createMagicTableCellRequest } from '@src/prompts/MagicTableCell';
import { createTaskListCountRequest } from '@src/prompts/TaskList';
import React from 'react';

const defaultCriteriaColumns = [
  { type: 'task-list', label: 'Total tasks', prompt: createTaskListCountRequest },
  { type: 'task', label: 'Company name', prompt: createMagicTableCellRequest },
  { type: 'task', label: 'Name of document', prompt: createMagicTableCellRequest },
  { type: 'task', label: 'Founding date', prompt: createMagicTableCellRequest },
  { type: 'task', label: 'Document Type', prompt: createMagicTableCellRequest },
  { type: 'task', label: 'Employee count', prompt: createMagicTableCellRequest },
  { type: 'task', label: 'Current CEO', prompt: createMagicTableCellRequest },
] as ColumnCriteriaPrompt[];

const vectorStores = [
  {
    indexId: 'demo-0',
    namespace: '0.13460625280884586-1727614038391',
  },
  // {
  //   indexId: 'demo-0',
  //   namespace: '0.3273875525572434-1727445511165',
  // },
  // {
  //   indexId: 'adriaan-index-test',
  //   namespace: 'komaza-1722437006439',
  // },
  // {
  //   indexId: 'adriaan-index-test',
  //   namespace: 'duoro-valley-1725616887421',
  // },
  // {
  //   indexId: 'adriaan-index-test',
  //   namespace: 'fun-duoro-scala-project-1725626441900',
  // },
  // {
  //   indexId: 'demo-0',
  //   namespace: '0.307521351592525-1727445009500',
  // },
  // {
  //   indexId: 'demo-0',
  //   namespace: '0.08896433622452249-1727526115431',
  // },
  // {
  //   indexId: 'demo-0',
  //   namespace: '0.26426999579791377-1727612960794',
  // },
  // {
  //   indexId: 'demo-0',
  //   namespace: '0.8902255495674525-1727613145126',
  // },
  // {
  //   indexId: 'demo-0',
  //   namespace: '0.9194381943350227-1727613206253',
  // },
  // {
  //   indexId: 'demo-0',
  //   namespace: '0.7585985501008339-1727613310799',
  // },
];

const MiningDemo = () => {
  return (
    <Screen>
      <View
        style={{
          margin: '3%',
          justifyContent: 'space-between',
        }}
      >
        <View
          style={{
            padding: '3%',
            width: '100%',
            backgroundColor: '#fff',
            justifyContent: 'center',
          }}
        >
          <TextBodyRegular style={[Styles.h3, { marginBottom: 30, color: Colors.buttonBackground }]}>Mining</TextBodyRegular>
          <TextBodyRegular style={[Styles.h5, { marginBottom: 30, width: '65%' }]}>{`Explore your matrix with curated details of existing data and upload your own to discover new insights.`}</TextBodyRegular>
          <TextBodyRegular style={[Styles.h5, { marginBottom: 30, width: '65%' }]}>{`This experience is a demo to show you how you could interact with your existing documents on the Baltare platform.  The documents you upload are not saved on a server and will disappear when you close or refresh this window.`}</TextBodyRegular>
        </View>
        <MagicTable
          defaultCriteriaColumn={defaultCriteriaColumns}
          defaultVectorStores={vectorStores}
        />
      </View>
    </Screen>
  );
};

export default MiningDemo;
