import Button from '@src/components/Button';
import Input from '@src/components/Input';
import Colors from '@src/components/shared/Colors';
import { TextInterRegular, TextInterSemiBold } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { View } from '@src/components/shared/Themed';
import { AlignmentContext } from '@src/providers/Alignment';
import React, { useContext, useState } from 'react';
import { NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import SaveEditableInputModal from './SaveEditableInputModal';

interface DeliverableLLMItemProps {
  task: any;
  index: number;
  onAlign?: (property: string) => void;
}

const DeliverableLLMItem = ({ task, index, onAlign }: DeliverableLLMItemProps) => {
  const { alignmentState, setAlignmentState } = useContext(AlignmentContext);
  const { name } = task;
  const property = 'deliverablesLLM';
  const value = name;
  const [inputValue, setInputValue] = useState<string>(value);
  const [state, setState] = useState<string>();

  const onRealign = () => {
    onAlign && onAlign(property);
    setState(undefined);
  };

  const onKeyPress = (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    if (e.shiftKey && e.key === 'Enter') {
      // Create a new line
    } else if (e.key === 'Enter') {
      // Trigger the same function as onSave button
      onSave();
    }
  };

  const setDeliverablesLLMState = (newVal: string) => {
    if (alignmentState[property] && JSON.parse(alignmentState[property]).tasks) {
      const allTasks = JSON.parse(alignmentState[property]).tasks;
      allTasks[index] = { ...allTasks[index], name: newVal }; // Update the specific index directly
      const ret = { ...alignmentState, [property]: JSON.stringify({ tasks: allTasks }) }; // Stringify the updated object
      setAlignmentState(ret);
    }
  };

  const onSave = () => {
    setDeliverablesLLMState(inputValue);
    setState(undefined);
  };

  const onCancel = () => {
    console.log('\n\n!! value', value);
    setDeliverablesLLMState(value);
    setInputValue(value);
    setState(undefined);
  };

  return (
    <View
      style={{
        backgroundColor: Colors.buttonBackground,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 15,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            backgroundColor: '#fff',
            width: 30,
            height: 30,
            marginRight: 15,
            borderRadius: 30,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <TextInterRegular style={[Styles.h7]}>{index + 1}</TextInterRegular>
        </View>

        {state === 'edit' ? (
          <Input
            // style={[Styles.h6]}
            onChangeText={setInputValue}
            value={inputValue || value || ''}
            // numberOfLines={1}
            multiline={true}
            onKeyPress={onKeyPress}
            height={28}
          />
        ) : (
          <TextInterSemiBold style={[Styles.h5, { color: '#fff' }]}>{value}</TextInterSemiBold>
        )}
      </View>
      {state === 'edit' ? (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            theme="fancy small"
            modal={
              <SaveEditableInputModal
                onYes={onRealign}
                onNo={onSave}
              />
            }
          >
            Save
          </Button>
          <Button
            theme="fancy small"
            onPress={onCancel}
          >
            Cancel
          </Button>
        </View>
      ) : (
        <View
          style={{
            alignSelf: 'flex-end',
          }}
        >
          <Button
            theme="fancy"
            onPress={() => {
              setState('edit');
            }}
          >
            Edit
          </Button>
        </View>
      )}
    </View>
  );
};

export default DeliverableLLMItem;
