import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import Button from '@src/components/Button';
import { DemoContext } from '@src/components/Demos/Context';
import { vectorStore } from '@src/components/MagicTable';
import Colors from '@src/components/shared/Colors';
import { TextBodyMedium, TextBodyRegular, TextInterMedium } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { Pressable, View } from '@src/components/shared/Themed';
import { createSubmissionPreScreen } from '@src/graphql/mutations';
import { AlignmentContext } from '@src/providers/Alignment';
import { LoadingContext } from '@src/providers/Loading';
import { generateClient } from 'aws-amplify/api';
import React, { useContext, useEffect, useState } from 'react';
import { ActivityIndicator } from 'react-native';
import { v4 as uuidv4 } from 'uuid';
import { SPSPage } from '.';

const SPSGenerate = ({ goto }: SPSPage) => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const { addLoading, removeLoading, checkLoading } = useContext(LoadingContext);
  const { alignmentState } = useContext(AlignmentContext);
  const { getPineconeIndex } = useContext(DemoContext);
  const client = generateClient({
    authMode: 'apiKey',
  });
  const [link, setLink] = useState<string>();

  const createDatabaseEntry = async (uuid: string, vsData: vectorStore) => {
    const input = {
      id: uuid,
      indexId: vsData.indexId,
      namespace: vsData.namespace,
      ...alignmentState,
    };
    return await client.graphql({
      query: createSubmissionPreScreen,
      variables: {
        input,
      },
      // authMode: isLoggedIn ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS : GRAPHQL_AUTH_MODE.AWS_IAM,
      // export type GraphQLAuthMode = 'apiKey' | 'oidc' | 'userPool' | 'iam' | 'identityPool' | 'lambda' | 'none';
    });
  };

  const generate = async () => {
    addLoading('generate');
    try {
      // create pinecone index
      const vsData = await getPineconeIndex();
      if (vsData) {
        const uuid = uuidv4();
        // create URL
        const domain = window.location.hostname === 'localhost' ? 'http://localhost:19006' : 'https://app.baltare.com';
        setLink(`${domain}/wealign/s/${uuid}`);
        // create a database entry with a uuid
        const db = await createDatabaseEntry(uuid, vsData);
        return db;
      }
    } catch (err) {
      console.log('Error Generate createDatabaseEntry()', err);
    } finally {
      removeLoading('generate');
    }
  };

  const copyToClipboard = (text: string) => {
    if (text) navigator.clipboard.writeText(text);
  };

  const gotoLink = () => {
    const extractedUuid = link ? link.match(/s\/([a-f0-9\-]+)/)?.[1] : null; // Extract UUID from the link
    if (extractedUuid) {
      navigation.navigate('wealign', {
        screen: 'submit',
        params: {
          id: extractedUuid,
        },
      });
    }
  };

  const gotoReview = () => {
    goto('review');
  };

  useEffect(() => {
    generate();
  }, []);

  const emailText = `${alignmentState.emailLLM}\n\n\nClick here to give answers: ${link}`;

  return (
    <>
      <View
        style={{
          backgroundColor: Colors.light.container,
          marginTop: '3%',
          marginHorizontal: '3%',
          padding: 30,
        }}
      >
        <TextBodyMedium style={[Styles.h3, { marginBottom: 30 }]}>{`Your alignment is ${checkLoading('generate') ? 'loading.' : 'ready!'}`}</TextBodyMedium>
        <TextInterMedium style={[Styles.h6, { marginBottom: 20 }]}>{`Below you ${checkLoading('generate') ? 'will' : ' '}see the email from the previous step that you can use directly in your personal email client.`}</TextInterMedium>
        <TextInterMedium style={[Styles.h6, { marginBottom: 30 }]}>As well as a direct link to the page where your recipient will upload their details and documents.</TextInterMedium>
      </View>

      {checkLoading('generate') && (
        <View
          style={{
            backgroundColor: Colors.light.container,
            marginTop: '3%',
            marginHorizontal: '3%',
            padding: 30,
          }}
        >
          <ActivityIndicator
            size="large"
            color={Colors.buttonBackgroundActive}
          />
        </View>
      )}
      {!checkLoading('generate') && link && (
        <>
          <View
            style={{
              backgroundColor: Colors.light.container,
              marginTop: '3%',
              marginHorizontal: '3%',
              padding: 30,
            }}
          >
            <TextBodyMedium style={[Styles.h4, { marginBottom: 20 }]}>Visit your unique alignment</TextBodyMedium>
            <View style={{ borderTopWidth: 1, borderTopColor: '#fff', padding: 5, borderColor: Colors.buttonBackground, paddingHorizontal: 5, marginBottom: 10 }}>
              <Pressable
                onPress={gotoLink}
                style={{ flexDirection: 'row', alignItems: 'center' }}
              >
                <Ionicons
                  name="finger-print"
                  size={30}
                />
                <TextInterMedium
                  style={[
                    Styles.h4,
                    {
                      marginLeft: 20,
                      paddingTop: 30,
                      marginBottom: 10,
                      textDecorationLine: 'underline',
                    },
                  ]}
                >
                  {link}
                </TextInterMedium>
              </Pressable>
            </View>
            <Button
              theme="fancy"
              style={{ alignSelf: 'flex-start' }}
              onPress={() => copyToClipboard(link || '')}
              icon="clipboard"
            >
              Copy link
            </Button>
          </View>
          <View
            style={{
              backgroundColor: Colors.light.container,
              marginTop: '3%',
              marginHorizontal: '3%',
              padding: 30,
            }}
          >
            <View
              style={{
                borderBottomColor: '#fff',
                paddingBottom: 20,
                borderBottomWidth: 1,
                width: '100%',
              }}
            >
              <TextBodyMedium style={[Styles.h3, {}]}>Your alignment's headline</TextBodyMedium>
              <TextInterMedium style={[Styles.h6, { marginTop: 10 }]}>Click the link below to see your alignment, or copy and share it.</TextInterMedium>
            </View>
            <TextBodyRegular style={[Styles.h3, { paddingTop: 30 }]}>{alignmentState.titleLLM}</TextBodyRegular>
          </View>
          <View
            style={{
              backgroundColor: Colors.light.container,
              marginTop: '3%',
              marginHorizontal: '3%',
              padding: 30,
            }}
          >
            <TextBodyMedium style={[Styles.h3, {}]}>Distribute your alignment</TextBodyMedium>
            <TextInterMedium style={[Styles.h6, { marginTop: 10, marginBottom: 20 }]}>Copy this email and drop it into your email client and send it to the responsible parties. This feature will be automated if you added assignees in the previous step.</TextInterMedium>
            <View
              style={{
                borderTopWidth: 1,
                borderTopColor: '#fff',
                padding: 5,
                borderColor: Colors.buttonBackground,
                marginBottom: 10,
              }}
            >
              <TextBodyRegular
                style={[
                  Styles.h4,
                  {
                    paddingTop: 30,
                  },
                ]}
              >
                {emailText}
              </TextBodyRegular>
            </View>
            <Button
              theme="fancy"
              style={{ alignSelf: 'flex-start', marginVertical: 30 }}
              onPress={() => copyToClipboard(emailText || '')}
              icon="clipboard"
            >
              Copy email
            </Button>
          </View>
          {!checkLoading('generate') && (
            <>
              <View
                style={{
                  backgroundColor: Colors.light.container,
                  marginTop: '3%',
                  marginHorizontal: '3%',
                  padding: 30,
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  onPress={gotoReview}
                  theme="fancy"
                  icon="chevron-back"
                  toolTip={'Go back and edit your alignment'}
                >
                  Edit
                </Button>
                <Button
                  onPress={gotoLink}
                  theme="fancy highlight"
                  icon="chevron-forward"
                >
                  See your alignment!
                </Button>
              </View>

              <View
                style={{
                  backgroundColor: Colors.light.container,
                  marginTop: '3%',
                  marginHorizontal: '3%',
                  padding: 30,
                }}
              >
                <TextBodyRegular style={[Styles.h5, { marginBottom: 10 }]}>Do you want to share a similar alignment but to a different party? Click duplicate below and a new URL will be greated that you can share.</TextBodyRegular>
                <TextBodyRegular style={[Styles.h6, { marginBottom: 10 }]}>Duplicating would make sense in the situation where you are trying to gather the same information but from different groups of people. For instance, a landlord accepting application documents from multiple potential tenants.</TextBodyRegular>
                <Button
                  theme="fancy"
                  toolTip="Coming soon"
                >
                  Duplicate
                </Button>
              </View>
            </>
          )}
        </>
      )}
    </>
  );
};

export default SPSGenerate;
