import { ingest } from '@src/utils/Pinecone';
import UploadToS3 from '@src/utils/UploadToS3';

interface UploadFileOptions {
  file: File;
  indexId: string;
  namespace: string;
  user?: any;
}

export const uploadFiles = async (files: FileList, indexId: string, namespace: string, user?: any) => {
  const fileOperations = Array.from(files).map(async (file: File) => {
    const fileExtension = file.name.split('.').pop();
    const baseFileName = file.name.replace(/\.[^/.]+$/, '').replace(/[^a-zA-Z0-9_-]/g, '-');
    const fileName = `${baseFileName}-${Date.now()}.${fileExtension}`.toLowerCase();
    const ingestPromise = ingest(file, indexId, namespace);
    if (user) {
      const uploadPromise = UploadToS3({ data: file, path: fileName });
      return await Promise.all([uploadPromise, ingestPromise]);
    } else {
      return await Promise.all([ingestPromise]);
    }
  });

  return await Promise.all(fileOperations);
};

export const extractFileNames = (completedFiles: Array<any>) => {
  return completedFiles.map(fileArray => fileArray[0]?.path).filter(path => path !== undefined);
};
