import { Authenticator } from '@aws-amplify/ui-react-native';
import Create from '@src/components/Auth/Create';
import Header from '@src/components/Auth/Header';
import Login from '@src/components/Auth/Login';
import React from 'react';
import Screen from '../Screen';

const AuthLogin = () => {
  return (
    <Screen>
      <>
        <Authenticator
          components={{
            SignIn: props => {
              return <Login {...props} />;
            },
            SignUp: props => {
              return <Create {...props} />;
            },
          }}
          Header={Header}
        ></Authenticator>
      </>
    </Screen>
  );
};

export default AuthLogin;
