import Colors from '@src/components/shared/Colors';
import { TextBodyMedium } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { chat } from '@src/utils/Chat';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { ColumnCriteriaPrompt } from '.';

interface MagicTableItemProps {
  criteria: ColumnCriteriaPrompt;
  namespace: string;
  indexId: string;
  handlePress: (cellContent: string) => void;
}

const MagicTableItem = ({ criteria, namespace, indexId, handlePress }: MagicTableItemProps) => {
  const [cellContent, setCellContent] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  const onHandlePress = () => {
    if (handlePress) handlePress(cellContent);
  };

  const triggerRequest = async () => {
    setLoading(true);

    try {
      if (criteria.prompt) {
        const req = criteria.prompt({ indexId, namespace, criteria }); // Pass indexId and namespace here

        // make this pinecone enabled
        const res = await chat(req);

        if (res) {
          setCellContent(res.cellContent);
        }
      } else {
        throw Error('No criteria prompt()');
      }
    } catch (err) {
      console.log('magic table item llm error', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    triggerRequest();
  }, []);

  return (
    <TouchableOpacity
      onPress={onHandlePress}
      style={{ flex: 1, borderTopWidth: 0, borderTopColor: '#fff', borderRightWidth: 0, borderRightColor: '#fff', backgroundColor: cellContent ? (cellContent.toLowerCase() === 'not available' ? '#FF6961' : '#C1E1C1') : '#fff', padding: 15 }}
    >
      {!loading && <TextBodyMedium style={[Styles.h6, {}]}>{cellContent}</TextBodyMedium>}
      {loading && (
        <ActivityIndicator
          style={{
            alignItems: 'center ',
            justifyContent: 'center',
          }}
          size="small"
          color={Colors.buttonBackground}
        />
      )}
    </TouchableOpacity>
  );
};

export default MagicTableItem;
