import { TextBodyMedium, TextBodyRegular } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { View } from '@src/components/shared/Themed';
import { LoadingContext } from '@src/providers/Loading';
import React, { useContext, useRef, useState } from 'react';
import { ActivityIndicator, TouchableOpacity } from 'react-native';

interface UploadRowProps {
  children: JSX.Element;
  onPress?: (event: FileList | null) => Promise<void>;
}

const UploadRow = ({ children, onPress }: UploadRowProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<FileList | string | undefined>();
  const { checkLoading, addLoading, removeLoading } = useContext(LoadingContext);
  const [uploadComplete, setUploadComplete] = useState(false);

  const handleOnPress = () => {
    if (fileInputRef.current !== null) {
      fileInputRef.current?.click();
    }
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      try {
        addLoading('uploadFile1');
        setFile(event.target.files);
        if (onPress) await onPress(event.target.files);
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        removeLoading('uploadFile1');
      }
    }
  };

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileUpload}
        style={{ display: 'none' }}
        multiple
      />
      {!checkLoading('uploadFile1') && !uploadComplete ? (
        <TouchableOpacity
          onPress={handleOnPress}
          style={{ alignSelf: 'flex-start', width: '100%' }}
        >
          {children}
        </TouchableOpacity>
      ) : (
        <View
          style={{
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <ActivityIndicator
            size={24}
            color="#000"
          />
          <TextBodyMedium style={[Styles.h5, { marginLeft: 10 }]}>Please wait. Your file is being uploaded and processed.</TextBodyMedium>
          <TextBodyRegular style={[Styles.h7, { marginLeft: 10 }]}>Depending on the number of files you uploaded and their file size, this can take 30 seconds to 2 minutes. Thank you for your patience.</TextBodyRegular>
        </View>
      )}
    </>
  );
};

export default UploadRow;
