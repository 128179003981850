// https://www.youtube.com/watch?v=Ix9WIZpArm0
// https://js.langchain.com/v0.1/docs/integrations/document_loaders/web_loaders/pdf/

import { WebPDFLoader } from '@langchain/community/document_loaders/web/pdf';
import { OpenAIEmbeddings } from '@langchain/openai';
import { PineconeStore } from '@langchain/pinecone';
import { RecursiveCharacterTextSplitter } from '@langchain/textsplitters';
import { Pinecone } from '@pinecone-database/pinecone';
import Constants from 'expo-constants';

export const PINECONE_API_KEY = Constants?.expoConfig?.extra?.pinecone.API_KEY;
export const OPENAI_API_KEY = Constants?.expoConfig?.extra?.openai.API_KEY;
export const PINECONE_PLAN = 'starter';
export const QUOTAS = {
  starter: {
    projectsPerOrganization: 1,
    podsPerOrganization: 0,
    ServerlessIndexesPerProject: 5,
    ServerlessIndexStoragePerProject: 2,
    NamespacesPerServerlessIndex: 100,
    PodBasedIndexesPerProject: 0,
    PodsPerProject: 0,
    CollectionsPerProject: 100,
  },
  standard: {
    projectsPerOrganization: 20,
    podsPerOrganization: 300,
    serverlessIndexesPerProject: 20,
    serverlessIndexStoragePerProject: 2,
    namespacesPerServerlessIndex: 10,
    podBasedIndexesPerProject: 'n/a',
    podsPerProject: 100,
    collectionsPerProject: 'n/a',
  },
  enterprice: {
    projectsPerOrganization: 100,
    podsPerOrganization: 300,
    serverlessIndexesPerProject: 20,
    serverlessIndexStoragePerProject: 'N/A',
    namespacesPerServerlessIndex: 100000,
    podBasedIndexesPerProject: 'n/a',
    podsPerProject: 100,
    collectionsPerProject: 'n/a',
  },
};
const pinecone = new Pinecone({
  apiKey: PINECONE_API_KEY,
});

/**
 * Ingests a PDF file and stores its text in a Pinecone index.
 * @param file The PDF file to ingest.
 * @param indexId The ID of the Pinecone index to store the text in.
 */
export async function ingest(file: File, indexId: string, namespace: string) {
  let rawDocs, docs;
  try {
    const fileExtension = file.name.split('.').pop();
    const baseFileName = file.name.replace(/\.[^/.]+$/, '').replace(/[^a-zA-Z0-9_-]/g, '-');
    const fileName = `${baseFileName}-${Date.now()}.${fileExtension}`.toLowerCase();
    const loader = new WebPDFLoader(file, {
      parsedItemSeparator: '',
    });
    rawDocs = await loader.load();
    rawDocs = rawDocs.map(doc => ({ ...doc, metadata: { ...doc.metadata, fileName } }));
  } catch (error) {
    console.log('WebPDFLoader error:', file, error);
    throw new Error('WebPDFLoader error:');
    return;
  }

  try {
    /* Split text into chunks */
    const textSplitter = new RecursiveCharacterTextSplitter({
      chunkSize: 1000,
      chunkOverlap: 200,
    });

    docs = await textSplitter.splitDocuments(rawDocs);
  } catch (error) {
    console.log('textSplitter error:', error);
    throw new Error('textSplitter error:');
    return;
  }

  try {
    /*create and store the embeddings in the vectorStore*/
    const embeddings = new OpenAIEmbeddings({
      openAIApiKey: OPENAI_API_KEY,
    });
    const index = pinecone.Index(indexId);

    //embed the PDF documents
    await PineconeStore.fromDocuments(
      docs,
      embeddings,
      // must add chunk to make multiple files
      {
        pineconeIndex: index,
        namespace,
        textKey: 'text',
      },
    );
  } catch (error) {
    console.log('PineconeStore error:', error);
    throw new Error('PineconeStore error:');
  }
}

/**
 * Creates a new Pinecone index.
 * @param indexId The ID of the index to create.
 * @param dimension The dimension of the vectors that will be stored in the index.
 * @param metric The metric type for the index (e.g., "cosine", "euclidean").
 */
export async function createPineconeIndex(indexId: string, dimension: number = 3072, metric: 'cosine' | 'euclidean' = 'cosine') {
  try {
    const response = await pinecone.createIndex({
      name: indexId,
      dimension,
      metric,
      spec: {
        serverless: {
          cloud: 'aws',
          region: 'us-east-1',
        },
      },
    });
  } catch (error) {
    console.error('Failed to create index:', error);
    throw new Error('Failed to create Pinecone index');
  }
}

export async function listNamespacesByIndexId(indexId: string) {
  try {
    const index = pinecone.index(indexId);
    const stats = await index.describeIndexStats();
    if (stats.namespaces) {
      return stats.namespaces;
    } else {
      return [];
    }
  } catch (error) {
    console.error('Failed to describe index:', error);
    throw new Error('Failed to create Pinecone index');
  }
}
/**
 * Lists all Pinecone indexes.
 */
export async function listPineconeIndexes() {
  try {
    const response = await pinecone.listIndexes();
    console.log('Indexes:', response);
    return response;
  } catch (error) {
    console.error('Failed to list indexes:', error);
    throw new Error('Failed to list Pinecone indexes');
  }
}

/**
 * Deletes a Pinecone index.
 * @param indexId The ID of the index to delete.
 */
export async function deletePineconeIndex(indexId: string) {
  try {
    const response = await pinecone.deleteIndex(indexId);
    console.log('Index deleted:', response);
  } catch (error) {
    console.error('Failed to delete index:', error);
    throw new Error('Failed to delete Pinecone index');
  }
}

// delete namespace
export async function deletePineconeNamespace(indexId: string, namespace: string) {
  try {
    const index = pinecone.Index(indexId);
    await index.namespace(namespace).deleteAll();
  } catch (error) {
    console.error('Failed to delete namespace:', error);
    throw new Error('Failed to delete Pinecone namespace');
  }
}

// // how many namespaces are in the index
// export async function listPineconeNamespaces(indexId: string) {
//   try {
//     const index = pinecone.Index(indexId);
//     const response = await index.listNamespaces();
//     console.log('Namespaces:', response);
//     return response;
//   } catch (error) {
//     console.error('Failed to list namespaces:', error);
//     throw new Error('Failed to list Pinecone namespaces');
//   }
// }

export async function getIndex() {
  try {
    const indexId = indices[currentIndex];
    const namespaces = await listNamespacesByIndexId(indexId);
    if (Object.keys(namespaces).length <= QUOTAS[PINECONE_PLAN].NamespacesPerServerlessIndex) {
      // create a new namespace
    } else {
      // use a different index
    }
  } catch (error) {
    console.error('Failed to create namespace:', error);
    throw new Error('Failed to create Pinecone namespace');
  }
}
