import { taskListWrapper } from '@src/components/Conversation/placeholders';
import { createMagicTableCellRequestProps } from '@src/components/MagicTable';

// Function to create taskListRequest
export const createTaskListRequest = (indexId: string, namespace: string) => {
  const vsTaskListQuery = {
    modelName: 'gpt-4o-mini',
    templateWrapper: 'Your response should be to first analyze the goal of question, and then produce a "single simplified question" that will be used to search through a vector store to retrieve relevant documents related to the question: <question>{question}</question>.  Nothing other than the "single simplified question" should be returned.  No other text should be included.  Do not include any brackets like {{}}',
    question: taskListWrapper.templateWrapper,
  };

  const taskListSource = {
    requestName: 'vsTaskListQuery',
    numberOfSources: 50,
    modelName: 'gpt-4o-mini',
    indexId,
    namespace,
  };

  const generateTaskList = {
    modelName: 'gpt-4o-mini',
    templateWrapper: `${taskListWrapper.templateWrapper}\n${taskListWrapper.jsonFormat}`,
    isJson: true,
  };

  return {
    0: {
      vsTaskListQuery,
    },
    1: {
      taskListSource,
    },
    2: {
      generateTaskList,
    },
  };
};
export const createTaskListCountRequest = ({ indexId, namespace }: createMagicTableCellRequestProps) => {
  // export const createMagicTableCellRequest = (indexId: string, namespace: string) => {
  const vsTaskListQuery = {
    modelName: 'gpt-4o-mini',
    templateWrapper: 'Your response should be to first analyze the goal of question, and then produce a "single simplified question" that will be used to search through a vector store to retrieve relevant documents related to the question: <question>{question}</question>.  Nothing other than the "single simplified question" should be returned.  No other text should be included.  Do not include any brackets like {{}}',
    question: taskListWrapper.templateWrapper,
  };

  const taskListSource = {
    requestName: 'vsTaskListQuery',
    numberOfSources: 50,
    modelName: 'gpt-4o-mini',
    indexId,
    namespace,
  };

  const generateTaskList = {
    modelName: 'gpt-4o-mini',
    templateWrapper: taskListWrapper.templateWrapper,
  };

  const cellContent = {
    modelName: 'gpt-4o-mini',
    templateWrapper: `How many tasks were found in the source? <source>{generateTaskList}</source> Only return the number of tasks, nothing else but the number.`,
  };

  return {
    0: {
      vsTaskListQuery,
    },
    1: {
      taskListSource,
    },
    2: {
      generateTaskList,
    },
    3: {
      cellContent,
    },
  };
};
