import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

type SvgLogo = {
  width: number;
  height: number;
  style?: any;
  color?: string;
};
export default function SvgLogo({ width, height, style, color }: SvgLogo) {
  return (
    <Svg
      width={width}
      height={height}
      style={style}
      fill={color}
      viewBox="0 0 715.5 186.6"
    >
      <G>
        <Path
          d="M51.9,89.5c0.3,0.2,0.4,0.4,0.7,0.5c11.9,3.6,16.3,12.9,17.6,24c1,8.6-0.4,16.8-5.9,23.9c-5.7,7.3-13.5,10.7-22.5,11.5
     c-6.5,0.6-13,0.4-19.5,0.5c-6.6,0.1-13.2,0-19.7,0.1c-1.9,0-2.6-0.5-2.6-2.5C0.1,111.6,0,75.8,0,40c0-1.4,0-2.7,2-2.6
     c10.9,0.1,21.8-0.1,32.7,0.4c7,0.3,13.5,2.7,18.7,7.9c3.3,3.3,5.4,7.2,6.4,11.7c2.1,9.4,1.9,18.6-3.4,27
     C55.3,86.2,53.6,87.7,51.9,89.5z M21.9,116.7c0,4.5,0,9,0,13.5c0,1.5,0.4,2.4,2.1,2.3c4.2-0.3,8.5-0.2,12.7-0.9
     c8.2-1.4,11.7-5.8,12-14.1c0-1.2,0-2.3-0.1-3.5c-0.4-6.1-3.5-10.4-9.5-11.6c-5-1-10.2-1-15.3-1.5c-1.7-0.1-1.8,1-1.8,2.3
     C21.9,107.7,21.9,112.2,21.9,116.7z M40.7,69.9c0.3-9.5-4.1-14.4-13.7-15c-0.8-0.1-1.7,0-2.5-0.1c-2.1-0.2-2.8,0.7-2.7,2.7
     c0.1,6.3,0,12.6,0,19c0,8.6,0.5,9,9.1,7.2c5.7-1.2,8.8-4.5,9.5-10.3C40.6,72.2,40.6,71,40.7,69.9z"
        />
        <Path
          d="M587.6,102.9c10.3,15.4,20.7,30.9,31.3,46.7c-1.1,0.1-1.8,0.2-2.5,0.2c-7,0-14-0.1-21,0.1c-2,0-3.1-0.6-4.2-2.3
     c-8.2-13.8-16.5-27.6-24.8-41.3c-0.3-0.4-0.5-0.8-1.4-1.2c-0.1,0.9-0.1,1.8-0.1,2.7c0,13-0.1,26,0.1,39c0,2.6-0.8,3.3-3.3,3.2
     c-5.4-0.2-10.8-0.1-16.2,0c-1.9,0-2.4-0.7-2.4-2.5c0-35.9,0-71.8,0-107.7c0-1.8,0.6-2.6,2.4-2.6c11.6,0.2,23.2-0.1,34.7,0.6
     c9.6,0.6,18.1,4.3,24.1,12.3c8.6,11.4,9.7,36.6-7.1,48.3C594.4,100.3,591,101.3,587.6,102.9z M564.8,72.1c0,4.9,0,9.8,0,14.7
     c0,1.5,0.4,2.5,2.1,2.2c3.9-0.5,7.8-0.7,11.6-1.5c5.1-1.2,8-4.8,9.2-9.8c1-4.1,1-8.2-0.1-12.3c-1.1-4-3.5-7.3-7.5-8.3
     c-4.3-1.1-8.8-1.4-13.2-2c-1.7-0.2-2,0.9-2,2.3C564.8,62.3,564.8,67.2,564.8,72.1z"
        />
        <Path
          d="M505,149.9c-1.3,0-2.1,0-2.9,0c-5.8,0-11.7-0.1-17.5,0c-2.1,0-3.1-0.6-3.8-2.6c-2.2-6.3-4.6-12.5-6.7-18.8
     c-0.6-1.8-1.6-2.4-3.5-2.4c-11.4,0.1-22.8,0.1-34.2,0c-1.9,0-2.8,0.6-3.5,2.4c-2.4,6.3-4.9,12.6-7.3,18.9c-0.7,1.8-1.6,2.6-3.6,2.6
     c-5.8-0.1-11.7,0-17.5-0.1c-0.7,0-1.5-0.1-2.5-0.2c0.3-1.2,0.5-2.1,0.9-3c12.5-32.9,25-65.9,37.5-98.8c1.4-3.8,3-7.6,4.3-11.4
     c0.6-1.6,1.4-2.2,3.1-2.2c3.9,0.1,7.8,0.1,11.7,0c2.2-0.1,3.2,0.7,4,2.8c6.7,18.5,13.6,36.9,20.4,55.3
     c6.7,18.2,13.4,36.4,20.2,54.6C504.2,147.7,504.5,148.5,505,149.9z M453.1,68.2c-0.2,0-0.5,0-0.7,0c-0.3,0.7-0.6,1.3-0.8,2
     c-3.6,11.9-7.2,23.7-10.8,35.6c-0.6,1.9,0,2.6,1.9,2.6c6.8,0,13.7,0,20.5,0c3.6,0,3.8-0.3,2.6-3.7c-3.6-10.5-7.3-21.1-10.9-31.6
     C454.3,71.3,453.7,69.8,453.1,68.2z"
        />
        <Path
          d="M207.6,149.7c-0.9,0.1-1.6,0.2-2.4,0.2c-6,0-12-0.1-18,0.1c-2.2,0.1-3.1-0.8-3.7-2.7c-2.2-6.3-4.6-12.5-6.8-18.8
     c-0.6-1.7-1.5-2.3-3.3-2.3c-11.5,0.1-23,0.1-34.5,0c-1.8,0-2.7,0.7-3.3,2.3c-2.4,6.4-5,12.7-7.4,19.1c-0.7,1.7-1.6,2.5-3.5,2.4
     c-5.9-0.1-11.8,0-17.7,0c-0.7,0-1.4-0.1-2.5-0.2c0.3-1,0.5-1.8,0.8-2.6c11.4-30.1,22.9-60.1,34.3-90.2c2.6-6.8,5.2-13.5,7.7-20.3
     c0.6-1.6,1.5-2.4,3.3-2.3c4,0.1,8,0.1,12,0c1.9-0.1,2.8,0.6,3.5,2.4c9.2,25.1,18.5,50.2,27.7,75.3c4.4,11.9,8.8,23.8,13.1,35.6
     C207.2,148.2,207.3,148.8,207.6,149.7z M155.4,67.4c-0.6,1.5-1,2.3-1.2,3.1c-3.6,11.7-7.1,23.4-10.7,35.1c-0.6,2-0.2,2.7,2,2.7
     c7-0.1,14,0,21,0c3.1,0,3.3-0.3,2.3-3.1c-4-11.6-8-23.3-12.1-34.9C156.4,69.5,156.1,68.8,155.4,67.4z"
        />
        <Path
          d="M657.3,93.8c0-17.7,0-35.3,0-53c0-3.4,0-3.4,3.5-3.4c17.1,0,34.2,0,51.2,0c3.4,0,3.4,0,3.4,3.5c0,4.1-0.1,8.2,0,12.2
     c0.1,2.4-0.7,3.2-3.1,3.2c-10.1-0.1-20.2,0-30.2-0.1c-2.4,0-3.2,0.6-3.2,3.1c0.1,6.3,0.2,12.7,0,19c-0.1,2.6,0.9,3.2,3.3,3.2
     c9.6-0.1,19.2,0,28.7-0.1c2.3,0,3.3,0.5,3.2,3c-0.2,4.3-0.2,8.7,0,13c0.1,2.2-0.6,3-2.9,3c-9.7-0.1-19.3,0-29-0.1
     c-2.5,0-3.4,0.6-3.3,3.2c0.2,8.2,0.1,16.3,0,24.5c0,2.5,0.9,3.1,3.2,3.1c10-0.1,20,0,30,0c3.2,0,3.3,0,3.3,3.4
     c0,4.2-0.1,8.5,0,12.7c0.1,2.4-0.8,2.9-3,2.9c-17.4-0.1-34.8-0.1-52.2,0c-2.3,0-3-0.7-3-3C657.4,129.2,657.3,111.5,657.3,93.8z"
        />
        <Path
          d="M363.8,139.9c0,14.5-0.1,29,0.1,43.5c0,2.6-0.7,3.3-3.2,3.2c-5.2-0.2-10.5-0.2-15.7,0c-2.3,0.1-3-0.7-3-3
     c0.1-28.3,0-56.7,0-85c0-0.7,0-1.5,0-2.2c0-3.4,0-3.4-3.3-3.4c-5.5,0-11-0.1-16.5,0c-2.1,0-3-0.5-2.9-2.8c0.2-4.6,0.1-9.2,0-13.7
     c0-1.8,0.4-2.5,2.4-2.5c20.8,0.1,41.7,0.1,62.5,0c1.7,0,2.3,0.6,2.3,2.3c-0.1,4.7-0.1,9.5,0,14.2c0,1.9-0.6,2.5-2.4,2.5
     c-5.5-0.1-11,0-16.5,0c-3.7,0-3.7,0-3.7,3.6C363.8,111,363.8,125.5,363.8,139.9z"
        />
        <Path
          d="M245.8,93.6c0-17.6,0-35.2,0-52.7c0-3.5,0-3.5,3.4-3.5c4.7,0,9.3,0,14,0c4.3,0,4.3,0,4.3,4.2c0,28.3,0,56.7,0,85
     c0,4.4,0,4.4,4.5,4.4c7.9,0,15.8,0.1,23.7,0c2.2,0,2.9,0.7,2.9,2.9c-0.1,4.5-0.1,9,0,13.5c0,1.8-0.4,2.7-2.5,2.7
     c-15.9-0.1-31.8-0.1-47.7,0c-2.3,0-2.6-1-2.6-2.9C245.8,129.2,245.8,111.4,245.8,93.6z"
        />
        <Path
          d="M387.2,47.4c-3.5,0-7-0.1-10.5,0c-2,0.1-2.9-0.5-3.2-2.6c-2.4-15.5-19.2-23-32.2-14.4c-5.1,3.3-8.1,8.1-9,14.1
     c-0.3,2-1,2.6-3,2.5c-7.2-0.2-14.3-0.2-21.5-0.1c-2.4,0-2.9-0.8-2.8-3.1c1.2-21.2,18.5-40,39.7-43.2c23.2-3.5,44.8,8.4,52.8,29.7
     c1.7,4.5,2.1,9.5,3,14.3c0.4,2-0.4,2.8-2.6,2.7C394.3,47.3,390.7,47.4,387.2,47.4C387.2,47.4,387.2,47.4,387.2,47.4z"
        />
        <Path
          d="M352,47.9c5.7,0,11.3,0,17,0c3.1,0,3.4,0.5,2.8,3.6c-1.5,8.1-7.8,14.2-16.1,15.7c-7.7,1.4-16.1-2.2-20.1-9.1
     c-1.4-2.5-2.1-5.4-2.9-8.1c-0.5-1.6,0.3-2.4,2.1-2.3c5.7,0.1,11.5,0,17.2,0C352,47.8,352,47.8,352,47.9z"
        />
      </G>
    </Svg>
  );
}
