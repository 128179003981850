import { Ionicons } from '@expo/vector-icons';
import Button from '@src/components/Button';
import Input from '@src/components/Input';
import Colors from '@src/components/shared/Colors';
import { TextBodyMedium, TextBodyRegular, TextHeader, TextInterMedium } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { Pressable, View } from '@src/components/shared/Themed';
import WeAlignPrompts from '@src/prompts/WeAlignPrompts';
import { AlignmentContext } from '@src/providers/Alignment';
import React, { useContext, useState } from 'react';
import { Linking } from 'react-native';
import DateTimePicker from 'react-native-ui-datepicker';
import { SPSPage } from '.';

type PersonaButtonProps = {
  title: string;
  onPress?: () => void;
  icon?: string;
};

const PersonaButton = ({ title, onPress, icon }: PersonaButtonProps) => {
  const [onHover, setOnHover] = useState(false);
  const handleHoverIn = () => {
    setOnHover(true);
  };
  const handleHoverOut = () => {
    setOnHover(false);
  };
  return (
    <Pressable
      onPress={onPress}
      onHoverIn={handleHoverIn}
      onHoverOut={handleHoverOut}
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        padding: 10,
        backgroundColor: onHover ? Colors.bluelighter : Colors.light.secondary,
        marginRight: 10,
      }}
    >
      {icon && (
        <Ionicons
          name={icon}
          size={24}
          color={Colors.light.text}
          style={{ marginRight: 10 }}
        />
      )}
      <TextBodyRegular style={[Styles.h7, { color: '#fff' }]}>{title}</TextBodyRegular>
    </Pressable>
  );
};

const SPSMake = ({ goto }: SPSPage) => {
  const { alignmentState, setAlignmentState } = useContext(AlignmentContext);
  const [showDateTimePicker, setShowDateTimePicker] = useState<boolean>(false);
  const [showAssignee, setShowAssignee] = useState<boolean>(false);
  const [showOwnerEmail, setShowOwnerEmail] = useState<boolean>(false);

  const selectLandlord = () => {
    setAlignmentState({
      ...alignmentState,
      ...WeAlignPrompts.landLordPrompts,
    });
  };

  const onSubmit = async () => {
    goto('review');
  };

  const gotoFeedback = () => {
    Linking.openURL('https://baltare.convas.io/');
  };

  const onUploadDeliverablesDoc = (e: FileList | null) => {};

  return (
    <>
      <View
        style={{
          backgroundColor: Colors.light.container,
          marginTop: '3%',
          marginHorizontal: '3%',
          padding: 30,
        }}
      >
        <TextHeader style={[Styles.h1, { marginTop: 10, marginBottom: 30 }]}>Welcome to a tool that helps you verify information requests!</TextHeader>
        <TextInterMedium style={[Styles.h5, { marginBottom: 10 }]}>This is a demo app that is still under construction.</TextInterMedium>
        <TextInterMedium style={[Styles.h5, { marginBottom: 10 }]}>Thank you for spending some time to take a look.</TextInterMedium>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 40,
          }}
        >
          <TextInterMedium style={[Styles.h5, {}]}>You can give us feedback at this link:</TextInterMedium>
          <Pressable onPress={gotoFeedback}>
            <TextInterMedium style={[Styles.h5, { marginLeft: 10, textDecorationLine: 'underline' }]}>https://baltare.convas.io/</TextInterMedium>
          </Pressable>
        </View>

        <TextBodyMedium style={[Styles.h3, { marginBottom: 30 }]}>What is this?</TextBodyMedium>
        <TextInterMedium style={[Styles.h5, { marginBottom: 15 }]}>It's a website that helps you track information requests, automates reminds & notifications, and allows you to focus on result rather than gathering.</TextInterMedium>
        <TextInterMedium style={[Styles.h5, { marginBottom: 15 }]}>We do this by collecting some details about what you are hoping to gather.</TextInterMedium>
        <TextInterMedium style={[Styles.h5, { marginBottom: 15 }]}>Then we create a unique URL that you can share to whoever you think can help you gather the information you seek.</TextInterMedium>
        <TextInterMedium style={[Styles.h5, { marginBottom: 15 }]}>As the responsible parties input their responses, the system automatically checks if what they are submitting in fact answers the questions you have.</TextInterMedium>
        <TextInterMedium style={[Styles.h5, { marginBottom: 15 }]}>If they do not meet the requirement, they are immediately notified within the app. At that time they can add further information, by uploading documents (like PDF or XLS or directly typing into a text field), until the requirement has been met.</TextInterMedium>
        <TextInterMedium style={[Styles.h5, { marginBottom: 15 }]}>When all requirements are satisfied you will receive an email confirming the result.</TextInterMedium>
        <TextInterMedium style={[Styles.h5, { marginBottom: 15 }]}>Throughout the time that your alignment is active you will be able to verify progress and even communicate with the system to either add more requirements or change assignees. These features are available in both a chat bot and user interface for your convenience.</TextInterMedium>
        <TextInterMedium style={[Styles.h5, { marginBottom: 40 }]}>If you set a deadline and it is soon approaching, we automatically notify your assignees if your request is still outstanding so you don't have to.</TextInterMedium>

        <TextBodyMedium style={[Styles.h3, { marginBottom: 20 }]}>How:</TextBodyMedium>
        <TextInterMedium style={[Styles.h5, { marginBottom: 20 }]}>To start you'll have to share some basic details.</TextInterMedium>
        <TextInterMedium style={[Styles.h5, { marginBottom: 20 }]}>Then you'll have the chance to review a summary, a list of "asks", and an email.</TextInterMedium>
        <TextInterMedium style={[Styles.h5, { marginBottom: 20 }]}>Once you are satisfied, click "Generate" and a unique, secure URL will be created that only you will be able to edit.</TextInterMedium>
        <TextInterMedium style={[Styles.h5, { marginBottom: 20 }]}>Share this link with your friends or colleagues to gather information.</TextInterMedium>
        <TextInterMedium style={[Styles.h5, { marginBottom: 40 }]}>They can upload documents or type in responses directly into the link you share. Each document is secure and will include a timestamp, the email/name of the person who uploaded it, and a summary of whether or not that document satisfies what you've looking for.</TextInterMedium>

        <TextBodyMedium style={[Styles.h3, { marginBottom: 20 }]}>We keep all your details secure!</TextBodyMedium>
        <TextInterMedium style={[Styles.h6, { marginBottom: 20 }]}>Contact us if you wish to learn more about creating an account and saving your alignments for the future.</TextInterMedium>
      </View>
      <View
        style={{
          backgroundColor: Colors.light.container,
          marginTop: '3%',
          marginHorizontal: '3%',
          padding: 30,
        }}
      >
        <View style={{}}>
          <TextBodyMedium style={[Styles.h4, {}]}>What information are you looking to gather?</TextBodyMedium>
          <TextInterMedium style={[Styles.h7, { marginTop: 10, marginBottom: 10 }]}>This could be a list, a paragraph or even a document that describes what you are looking to get. We'll transform this into a list of trackable "asks", helping you know what details remain for it to be answered.</TextInterMedium>
          <View style={{ flexDirection: 'row', marginTop: 10, marginBottom: 10 }}>
            <TextInterMedium style={[Styles.h7, { width: '50%' }]}>If you have an existing document that includes details about the information that you are looking for you can upload it here:</TextInterMedium>
            <View>
              <Button
                style={{ width: '70%', alignSelf: 'flex-end' }}
                fontStyle={[Styles.h6, { color: '#fff' }]}
                theme="fancy small"
                toolTip="Coming soon"
                icon="cloud-upload"
              >
                Upload information request document
              </Button>
            </View>
          </View>
          <TextBodyRegular style={[Styles.h7, { marginTop: 10, marginBottom: 10 }]}>Alternatively, you can select from one of our pre-created options below:</TextBodyRegular>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            <Button
              style={{ marginRight: 5, marginBottom: 5 }}
              fontStyle={[Styles.h6, { color: '#fff' }]}
              theme="fancy small"
              onPress={selectLandlord}
            >
              Landlord
            </Button>
            <Button
              style={{ marginRight: 5, marginBottom: 5, background: Colors.bluelighter }}
              fontStyle={[Styles.h6, { color: '#fff' }]}
              theme="fancy small"
              toolTip="Coming soon"
            >
              HR
            </Button>
            <Button
              style={{ marginRight: 5, marginBottom: 5, background: Colors.bluelighter }}
              fontStyle={[Styles.h6, { color: '#fff' }]}
              theme="fancy small"
              toolTip="Coming soon"
            >
              Stock broker
            </Button>
            <Button
              style={{ marginRight: 5, marginBottom: 5, background: Colors.bluelighter }}
              fontStyle={[Styles.h6, { color: '#fff' }]}
              theme="fancy small"
              toolTip="Coming soon"
            >
              Doctor
            </Button>
            <Button
              style={{ marginRight: 5, marginBottom: 5, background: Colors.bluelighter }}
              fontStyle={[Styles.h6, { color: '#fff' }]}
              theme="fancy small"
              toolTip="Coming soon"
            >
              Lawyer
            </Button>
            <Button
              style={{ marginRight: 5, marginBottom: 5, background: Colors.bluelighter }}
              fontStyle={[Styles.h6, { color: '#fff' }]}
              theme="fancy small"
              toolTip="Coming soon"
            >
              Sustainability
            </Button>
            <Button
              style={{ marginRight: 5, marginBottom: 5, background: Colors.bluelighter }}
              fontStyle={[Styles.h6, { color: '#fff' }]}
              theme="fancy small"
              toolTip="Coming soon"
            >
              Event planner
            </Button>
          </View>
        </View>
        <Input
          height={200}
          style={[{ ...Styles.input }]}
          placeholder=""
          placeholderTextColor="#aaa"
          onChangeText={text => setAlignmentState({ ...alignmentState, deliverables: text })}
          multiline={true}
          numberOfLines={5}
          value={alignmentState.deliverables}
        />
      </View>
      <View
        style={{
          backgroundColor: Colors.light.container,
          marginTop: '3%',
          marginHorizontal: '3%',
          padding: 30,
        }}
      >
        <View style={{}}>
          <TextBodyMedium style={[Styles.h4, {}]}>Why are you seeking this information? Share some background of what you want</TextBodyMedium>
          <TextBodyRegular style={[Styles.h7, { marginTop: 10, marginBottom: 20 }]}>Why do you need it, why should they care? Help convince them why this is important.</TextBodyRegular>
        </View>
        <Input
          height={200}
          style={[{ ...Styles.input }]}
          placeholder=""
          placeholderTextColor="#aaa"
          onChangeText={text => setAlignmentState({ ...alignmentState, requirements: text })}
          value={alignmentState.requirements}
          multiline={true}
          numberOfLines={5}
        />
      </View>
      <View
        style={{
          backgroundColor: Colors.light.container,
          marginTop: '3%',
          marginHorizontal: '3%',
        }}
      >
        <Pressable
          style={{ padding: 30, alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}
          onPress={() => setShowAssignee(!showAssignee)}
        >
          <View>
            <TextBodyMedium style={[Styles.h4, {}]}>{`Who will you be requesting from (optional)`}</TextBodyMedium>
            <TextBodyRegular style={[Styles.h7, { marginTop: 10 }]}>{`We will automate the reminders for you.`}</TextBodyRegular>
          </View>
          <Ionicons
            name={showAssignee ? 'chevron-up' : 'chevron-down'}
            size={30}
          />
        </Pressable>
        {showAssignee && (
          <View
            style={{
              paddingHorizontal: 30,
              paddingBottom: 30,
            }}
          >
            <Input
              height={100}
              style={[{ ...Styles.input, marginVertical: 20 }]}
              placeholder="Email addresses of assignees"
              placeholderTextColor="#aaa"
              onChangeText={text => setAlignmentState({ ...alignmentState, assignees: text })}
              value={alignmentState.assignees}
              multiline={true}
              numberOfLines={5}
            />
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 20,
              }}
            >
              <Ionicons
                name="lock-closed"
                size={18}
              />
              <TextBodyRegular style={[Styles.h7, { marginLeft: 10 }]}>{`List the emails of who should be answering your asks. Feel free to add multiple people who you want to have visibility into progress.`}</TextBodyRegular>
            </View>
          </View>
        )}
      </View>
      <View
        style={{
          backgroundColor: Colors.light.container,
          marginTop: '3%',
          marginHorizontal: '3%',
        }}
      >
        <Pressable
          style={{ padding: 30, alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}
          onPress={() => setShowDateTimePicker(!showDateTimePicker)}
        >
          <View>
            <TextBodyMedium style={[Styles.h4, {}]}>{`When do you want it by (optional)`}</TextBodyMedium>
            <TextBodyRegular style={[Styles.h7, { marginTop: 10 }]}>{`Schedule a date and we'll remind them.`}</TextBodyRegular>
          </View>
          <Ionicons
            name={showDateTimePicker ? 'chevron-up' : 'chevron-down'}
            size={30}
          />
        </Pressable>
        {showDateTimePicker && (
          <View
            style={{
              width: '50%',
              marginLeft: 50,
            }}
          >
            <DateTimePicker
              mode="single"
              onChange={params => params.date && setAlignmentState({ ...alignmentState, deadline: params.date.toString() })}
              date={alignmentState.deadline}
            />
          </View>
        )}
      </View>
      <View
        style={{
          backgroundColor: Colors.light.container,
          marginTop: '3%',
          marginHorizontal: '3%',
        }}
      >
        <Pressable
          style={{ padding: 30, alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}
          onPress={() => setShowOwnerEmail(!showOwnerEmail)}
        >
          <View style={{}}>
            <TextBodyMedium style={[Styles.h4, {}]}>{`Stay informed (optional)`}</TextBodyMedium>
            <TextBodyRegular style={[Styles.h7, { marginTop: 10 }]}>{`We will send you notifcations of alignment status changes.`}</TextBodyRegular>
          </View>
          <Ionicons
            name={showOwnerEmail ? 'chevron-up' : 'chevron-down'}
            size={30}
          />
        </Pressable>
        {showOwnerEmail && (
          <View
            style={{
              paddingHorizontal: 30,
              paddingBottom: 30,
            }}
          >
            <Input
              style={[{ ...Styles.input, marginVertical: 20 }]}
              placeholder="Your email"
              placeholderTextColor="#aaa"
              onChangeText={text => setAlignmentState({ ...alignmentState, ownerEmail: text })}
              value={alignmentState.ownerEmail}
              multiline={true}
              numberOfLines={1}
            />
          </View>
        )}
      </View>
      <View
        style={{
          backgroundColor: Colors.light.container,
          marginTop: '3%',
          marginHorizontal: '3%',
          padding: 30,
        }}
      >
        <View
          style={{
            alignSelf: 'flex-start',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Button
            onPress={onSubmit}
            theme="fancy"
            toolTip={`Before publishing, you'll have a chance to review.`}
            disabled={!(alignmentState.requirements && alignmentState.deliverables)}
          >
            Next
          </Button>
          <>
            {!alignmentState.requirements && (
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginLeft: 20,
                }}
              >
                <Ionicons
                  name="alert-circle"
                  size={20}
                />
                <TextBodyRegular style={[Styles.h8, { marginLeft: 3 }]}>{`You must input your requirements first.`}</TextBodyRegular>
              </View>
            )}
            {!alignmentState.deliverables && (
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginLeft: 20,
                }}
              >
                <Ionicons
                  name="alert-circle"
                  size={20}
                />
                <TextBodyRegular style={[Styles.h8, { marginLeft: 3 }]}>{`You must input your deliverables first.`}</TextBodyRegular>
              </View>
            )}
          </>
        </View>
      </View>
    </>
  );
};

export default SPSMake;
