import { getFocusedRouteNameFromRoute, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import Styles from '@src/components/shared/Styles';
import { ModalContext } from '@src/providers/Modal';
import React, { useContext } from 'react';
import { Linking, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import Button from '../Button';
import Colors from '../shared/Colors';
import { TextHeader } from '../shared/StyledText';
import AlignmentUploadModal from './submit/AlignmentUploadModal';

type TopBarWeAlign = {
  currentRoute: any;
};
const TopBarWeAlign = ({ currentRoute }) => {
  const { setModalContent } = useContext(ModalContext);
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const route = useRoute();
  const currentRouteName = getFocusedRouteNameFromRoute(route) || 'Home'; // Default to 'Home' if no route found

  const gotoCreate = () => {
    navigation.navigate('wealign', { screen: 'create' });
  };
  const gotoWebsite = () => {
    Linking.openURL('https://www.wealign.ai');
  };
  const onAdd = () => {
    setModalContent(<AlignmentUploadModal id={'id'} />);
  };

  return (
    <View
      style={{
        zIndex: 1,
        justifyContent: 'center',
        height: 150,
        backgroundColor: '#fff',
        padding: '3%',
        alignItems: 'center',
        borderBottomWidth: 4,
        borderBottomColor: Colors.buttonBackground,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '95%',
        }}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <TouchableOpacity
            onPress={gotoWebsite}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginRight: 15,
            }}
          >
            <TextHeader style={[Styles.h1, { marginRight: 30, color: Colors.buttonBackground }]}>weAlign</TextHeader>
          </TouchableOpacity>
        </View>

        {currentRouteName !== 'create' && (
          <Button
            onPress={gotoCreate}
            theme="fancy"
            icon="add"
          >
            Create
          </Button>
        )}
      </View>
    </View>
  );
};

export default TopBarWeAlign;
