import Button from '@src/components/Button';
import Input from '@src/components/Input';
import { TextHeader } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { signIn } from 'aws-amplify/auth';
import React, { useState } from 'react';
import { ActivityIndicator, NativeSyntheticEvent, TextInputKeyPressEventData, View } from 'react-native';

const MySignIn: React.FC<any> = props => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSignIn = async () => {
    try {
      console.log('handle sign in', props);
      setLoading(true);
      const res = await props.handleSubmit({ username, password });
      console.log('handleSubmit response:', res);
      const res2 = await signIn({ username, password });
      console.log('signIn response', res2);
      setLoading(false);
    } catch (error) {
      console.error('Error signing in:', error);
    }
  };

  const onKeyPress = (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    // Handle the key press event here
    if (event.nativeEvent.key === 'Enter') {
      handleSignIn();
    }
  };

  return (
    <View
      style={{
        marginTop: 24,
        padding: 24,
      }}
    >
      <View
        style={{
          marginTop: 10,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          marginBottom: 30,
        }}
      >
        <TextHeader style={[Styles.h2, { marginBottom: 0 }]}>Enter your credentials below</TextHeader>
        <Button
          style={[Styles.button]}
          onPress={props.toSignUp}
          theme="fancy"
        >
          Create an account
        </Button>
      </View>
      <View
        style={{
          marginBottom: 30,
          width: '50%',
        }}
      >
        <Input
          style={Styles.input}
          placeholder="Username"
          onChangeText={text => setUsername(text)}
          value={username}
          autoComplete="email"
        />
        <Input
          style={Styles.input}
          placeholder="Password"
          secureTextEntry={true}
          onChangeText={text => setPassword(text)}
          value={password}
          onKeyPress={onKeyPress}
          autoComplete="password"
        />
      </View>
      {loading ? (
        <ActivityIndicator
          size="large"
          color="#0000ff"
        />
      ) : (
        <View
          style={{
            marginTop: 10,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Button
            style={[Styles.button, { width: '30%' }]}
            onPress={handleSignIn}
            icon="chevron-forward"
            theme="fancy"
          >
            Sign In
          </Button>
        </View>
      )}
    </View>
  );
};

export default MySignIn;
