import dayjs from 'dayjs';
import React, { Dispatch, PropsWithChildren, SetStateAction, useMemo, useState } from 'react';

export type AlignmentContextProps = {
  alignmentState: AlignmentState;
  setAlignmentState: Dispatch<SetStateAction<AlignmentState | undefined>>;
};

const defaultAlignmentState = {
  deliverables: '',
  requirements: '',
  assignees: '',
  ownerEmail: '',
  deadline: dayjs().toString(),
  emailLLM: '',
  titleLLM: '',
  deliverablesLLM: '',
  requirementsLLM: '',
};
export const AlignmentContext = React.createContext<AlignmentContextProps>({
  alignmentState: defaultAlignmentState,
  setAlignmentState: () => {},
});

export const { Consumer } = AlignmentContext;
export default Consumer;

type AlignmentState = {
  deliverables: string;
  requirements: string;
  assignees: string;
  ownerEmail: string;
  deadline: string;
  emailLLM: string;
  titleLLM: string;
  deliverablesLLM: string;
  requirementsLLM: string;
};

export function Provider({ children }: PropsWithChildren<Record<symbol, symbol>>) {
  const [alignmentState, setAlignmentState] = useState<AlignmentState>(defaultAlignmentState);

  const contextValue: AlignmentContextProps = useMemo(
    () => ({
      alignmentState,
      setAlignmentState,
    }),
    [alignmentState, setAlignmentState],
  );

  return <AlignmentContext.Provider value={contextValue}>{children}</AlignmentContext.Provider>;
}
