//https://www.thisdot.co/blog/setting-up-react-navigation-in-expo-web-a-practical-guide
/* eslint-disable */
/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { createStackNavigator } from '@react-navigation/stack';
import AuthLoginScreen from '@src/components/Auth';
import BackOffice from '@src/components/BackOffice';
import AdvisorDetail from '@src/components/BackOffice/Advisor/Detail';
import AdvisorNew from '@src/components/BackOffice/Advisor/New';
import DemoLanding from '@src/components/Demos';
import CreateCustomTaskList from '@src/components/Demos/CreateCustomTaskList';
import ReportAttributeGen from '@src/components/Demos/ReportAttributeGen';
import TopBarDemo from '@src/components/Demos/TopBarDemo';
import InvestorScreen from '@src/components/Investor/Dashboard';
import MiningDemo from '@src/components/Mining/demo';
import ProjectDetailScreen from '@src/components/Project/Detail';
import ProjectNewScreen from '@src/components/Project/New';
import Reports from '@src/components/Reports';
import { View } from '@src/components/shared/Themed';
import SPSIndex from '@src/components/SPS/create';
import SPSDashboard from '@src/components/SPS/dashboard';
import SPSSubmit from '@src/components/SPS/submit';
import TopBarWeAlign from '@src/components/SPS/TopBarWeAlign';
import SupportScreen from '@src/components/Support';
import SustainabilityDemo from '@src/components/Sustainability/demo';
import TaskListDemo from '@src/components/TaskList/demo';
import TermsScreen from '@src/components/Terms';
import TopBar from '@src/components/TopBar';
import VerticalBar from '@src/components/VerticalBar';
import useResponsive from '@src/hooks/useResponsive';
import { UserContext } from '@src/providers/User';
import { getTranslation } from '@src/services/i18n';
import { AlignStackParamList, BackOfficeParamList, DemoStackParamList, InvestorParamList, LoggedInStackParamList, LoggedOutStackParamList, RootStackParamList } from '@src/types/navigation';
import React, { useContext, useEffect } from 'react';

export function RootNavigator() {
  const Stack = createStackNavigator<RootStackParamList>();
  const { user } = useContext(UserContext);
  const navigation = useNavigation<NativeStackNavigationProp<any>>();

  useEffect(() => {
    if (navigation.getCurrentRoute().path && navigation.getCurrentRoute().path.includes('demo')) {
      return;
    }
    if (navigation.getCurrentRoute().path && navigation.getCurrentRoute().path.includes('wealign')) {
      return;
    }
    if (user) {
      navigation.navigate('loggedIn');
    } else if (!user) {
      navigation.navigate('loggedOut');
    }
  }, [user]);

  return (
    <Stack.Navigator initialRouteName={user ? 'loggedIn' : 'loggedOut'}>
      <Stack.Screen
        name="loggedOut"
        component={LoggedOutNavigator}
        options={{
          gestureEnabled: false,
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="loggedIn"
        component={LoggedInNavigator}
        options={{
          gestureEnabled: false,
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="demo"
        component={DemoNavigator}
        options={{
          gestureEnabled: false,
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="terms"
        component={TermsScreen}
        options={{
          gestureEnabled: false,
          headerShown: false,
        }}
      />

      <Stack.Screen
        name="wealign"
        component={AlignNavigator}
        options={{
          gestureEnabled: false,
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  );
}

export function AlignNavigator() {
  const Stack = createStackNavigator<AlignStackParamList>();
  const navigation = useNavigation<NativeStackNavigationProp<any>>(); // Get the navigation object

  // Get the current route directly from navigation state
  // const currentRoute = navigation.getState().routes[navigation.getState().index].state.routes[0];
  // console.log('\n navigation.getState()', navigation.getState());
  return (
    <View
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <TopBarWeAlign currentRoute={navigation.getState()} />
      <Stack.Navigator initialRouteName="dashboard">
        <Stack.Screen
          name="dashboard"
          component={SPSDashboard}
          options={{
            title: `${getTranslation('title')} - We Align - Dashboard`,
            gestureEnabled: false,
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="create"
          component={SPSIndex}
          options={{
            title: `${getTranslation('title')} - We Align - Create`,
            gestureEnabled: false,
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="submit"
          component={SPSSubmit}
          options={{
            title: `${getTranslation('title')} - We Align `,
            gestureEnabled: false,
            headerShown: false,
          }}
        />
      </Stack.Navigator>
    </View>
  );
}

export function DemoNavigator() {
  const Stack = createStackNavigator<DemoStackParamList>();

  return (
    <View
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <TopBarDemo />
      <Stack.Navigator initialRouteName="demoLanding">
        <Stack.Screen
          name="demoLanding"
          component={DemoLanding}
          options={{
            title: `${getTranslation('title')} - demo`,
            gestureEnabled: false,
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="taskList"
          component={TaskListDemo}
          options={{
            title: `${getTranslation('title')} - demo - task list`,
            gestureEnabled: false,
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="customTaskList"
          component={CreateCustomTaskList}
          options={{
            title: `${getTranslation('title')} - demo - custom task list`,
            gestureEnabled: false,
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="reports"
          component={Reports}
          options={{
            title: `${getTranslation('title')} - demo - reports`,
            gestureEnabled: false,
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="mining"
          component={MiningDemo}
          options={{
            title: `${getTranslation('title')} - demo - mining`,
            gestureEnabled: false,
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="sustainability"
          component={SustainabilityDemo}
          options={{
            title: `${getTranslation('title')} - demo - sustainability`,
            gestureEnabled: false,
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="attributeGen"
          component={ReportAttributeGen}
          options={{
            title: `${getTranslation('title')} - demo - Report Attribute Generation`,
            gestureEnabled: false,
            headerShown: false,
          }}
        />
      </Stack.Navigator>
    </View>
  );
}
export function LoggedOutNavigator() {
  const Stack = createStackNavigator<LoggedOutStackParamList>();

  return (
    <Stack.Navigator initialRouteName="login">
      <Stack.Screen
        name="login"
        component={AuthLoginScreen}
        options={{
          gestureEnabled: false,
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  );
}

export function LoggedInNavigator() {
  const Stack = createStackNavigator<LoggedInStackParamList>();
  const { isWideScreen } = useResponsive();

  return (
    <View
      style={{
        width: '100%',
        height: '100%',
        flexDirection: isWideScreen ? 'row' : 'column',
      }}
    >
      {isWideScreen ? <VerticalBar /> : <TopBar />}
      <Stack.Navigator initialRouteName="investor">
        <Stack.Screen
          name="investor"
          component={InvestorStack}
          options={{
            headerShown: false,
            title: `${getTranslation('title')}`,
          }}
        />
        <Stack.Screen
          name="backoffice"
          component={BackOfficeStack}
          options={{
            headerShown: false,
            title: `${getTranslation('title')} - back office`,
          }}
        />
        <Stack.Screen
          name="support"
          component={SupportScreen}
          options={{
            gestureEnabled: false,
            headerShown: false,
            presentation: 'transparentModal',
          }}
        />
      </Stack.Navigator>
    </View>
  );
}

function InvestorStack() {
  const Stack = createStackNavigator<InvestorParamList>();

  return (
    <Stack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
      }}
      initialRouteName="dashboard"
    >
      <Stack.Screen
        name="dashboard"
        component={InvestorScreen}
        options={{
          title: `${getTranslation('title')} - dashboard`,
        }}
      />
      <Stack.Screen
        name="new"
        component={ProjectNewScreen}
        options={({ route }) => ({
          title: `${getTranslation('title')} - new project`,
        })}
      />
      <Stack.Screen
        name="project"
        component={ProjectDetailScreen}
        options={{
          title: `${getTranslation('title')} - project`,
        }}
      />
    </Stack.Navigator>
  );
}

function BackOfficeStack() {
  const Stack = createStackNavigator<BackOfficeParamList>();

  return (
    <Stack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
      }}
      initialRouteName="backofficeDashboard"
    >
      <Stack.Screen
        name="backofficeDashboard"
        component={BackOffice}
        options={{
          title: `${getTranslation('title')} - back office`,
        }}
      />
      <Stack.Screen
        name="advisorDetail"
        component={AdvisorDetail}
        options={{
          title: `${getTranslation('title')} - advisor - detail`,
        }}
      />
      <Stack.Screen
        name="advisorNew"
        component={AdvisorNew}
        options={{
          title: `${getTranslation('title')} - advisor - new`,
        }}
      />
    </Stack.Navigator>
  );
}
