import { advisorPlaceholder, defaultPlaceholder, sourcePlaceholder, taskListWrapper } from '@src/components/Conversation/placeholders';

export interface IQuery {
  prompt: string[];
}

export default {
  climateChangeEmissions: {
    title: 'Climate Change and Emissions',
    numberOfSources: 50,
    numberOfAdvisor: 20,
    modelName: 'gpt-4o-mini',
    templateWrapper: defaultPlaceholder.templateWrapperPlaceholder,
    templateSource: sourcePlaceholder.placeholder,
    templateSourceRefine: sourcePlaceholder.refinePlaceholder,
    templateAdvisor: advisorPlaceholder.placeholder,
    templateAdvisorRefine: advisorPlaceholder.refinePlaceholder,
    question: "What are the Komaza's greenhouse gas emissions? Does the Komaza have emissions reduction targets? How is climate change risk assessed and managed?",
  },
  resourceUseAndEfficiency: {
    title: 'Resource Use and Efficiency',
    numberOfSources: 50,
    numberOfAdvisor: 20,
    modelName: 'claude-opus-3',
    templateWrapper: defaultPlaceholder.templateWrapperPlaceholder,
    templateSource: sourcePlaceholder.placeholder,
    templateSourceRefine: sourcePlaceholder.refinePlaceholder,
    templateAdvisor: advisorPlaceholder.placeholder,
    templateAdvisorRefine: advisorPlaceholder.refinePlaceholder,
    question: 'How does the Komaza manage energy consumption? What are its water usage and conservation practices? How does it handle waste and promote recycling?',
  },
  environmentalManagementSystems: {
    title: 'Environmental Management Systems',
    numberOfSources: 50,
    numberOfAdvisor: 20,
    modelName: 'gpt-4o-mini',
    templateWrapper: defaultPlaceholder.templateWrapperPlaceholder,
    templateSource: sourcePlaceholder.placeholder,
    templateSourceRefine: sourcePlaceholder.refinePlaceholder,
    templateAdvisor: advisorPlaceholder.placeholder,
    templateAdvisorRefine: advisorPlaceholder.refinePlaceholder,
    question: 'Does the Komaza have environmental certifications (e.g., ISO 14001)? What environmental policies and procedures are in place? How are environmental impacts monitored and reported?',
  },
  demo: {
    numberOfSources: 50,
    numberOfAdvisor: 20,
    modelName: 'gpt-4o-mini',
    templateWrapper: taskListWrapper.templateWrapper,
    templateSource: sourcePlaceholder.placeholder,
    templateSourceRefine: sourcePlaceholder.refinePlaceholder,
    templateAdvisor: advisorPlaceholder.placeholder,
    templateAdvisorRefine: advisorPlaceholder.refinePlaceholder,
    question: 'What are the tasks that need to be completed to achieve the goals of the project?',
  },
};
