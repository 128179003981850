import Screen from '@src/components/Screen';
import React, { useState } from 'react';
import SPSGenerate from './Generate';
import SPSMake from './Make';
import SPSReview from './Review';

export type SPSPage = {
  goto: (c: string) => void;
};

const SPSIndex = () => {
  const [context, setContext] = useState<string>('make');

  const goto = (c: string) => {
    setContext(c);
  };

  return (
    <Screen>
      <>
        {context === 'make' && <SPSMake goto={goto} />}
        {context === 'review' && <SPSReview goto={goto} />}
        {context === 'generate' && <SPSGenerate goto={goto} />}
      </>
    </Screen>
  );
};

export default SPSIndex;
