import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import Button from '@src/components/Button';
import Screen from '@src/components/Screen';
import { TextBodyMedium, TextHeader } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { Pressable, TextInput, View } from '@src/components/shared/Themed';
import { getTranslation } from '@src/services/i18n';
import React, { useContext, useState } from 'react';
import { AdvisorContext } from './Provider';

const AdvisorNew = () => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const { currentAdvisor, makeAdvisor } = useContext(AdvisorContext);
  const [advisorBaseName, setAdvisorBaseName] = useState<string>(currentAdvisor?.title || '');

  const goBack = () => {
    navigation.navigate('backofficeDashboard');
  };

  const createAdvisor = async () => {
    if (!advisorBaseName) {
      return;
    }
    const res = await makeAdvisor(advisorBaseName);
    if (res) {
      navigation.navigate('advisorDetail', {
        advisorId: res.data.createAdvisor.id,
      });
    }
  };

  return (
    <Screen>
      <View
        style={{
          marginTop: 40,
        }}
      >
        <Pressable
          onPress={goBack}
          style={{
            flexDirection: 'row',
            marginBottom: 20,
            alignSelf: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Ionicons
            name="chevron-back"
            size={30}
          />
          <TextBodyMedium style={[Styles.h6, { textDecorationLine: 'underline' }]}>Back to advisors</TextBodyMedium>
        </Pressable>
        <View
          style={{
            padding: 20,
          }}
        >
          <TextHeader style={[Styles.h2, { marginBottom: 20 }]}>Create a new advisor</TextHeader>
          <View
            style={{
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <View
              style={{
                borderBottomColor: '#000',
                borderBottomWidth: 1,
                flex: 1,
                flexGrow: 1,
                marginRight: 20,
                justifyContent: 'center',
              }}
            >
              <TextInput
                style={[
                  {
                    borderWidth: 0,
                    paddingVertical: 20,
                    fontSize: 20,
                    width: '100%',
                    fontFamily: 'inter-bold',
                    letterSpacing: 1,
                  },
                ]}
                placeholder={`Name of advisor`}
                value={advisorBaseName}
                onChange={e => {
                  setAdvisorBaseName(e.target.value);
                }}
              />
            </View>
          </View>
          <View
            style={{
              alignSelf: 'center',
              alignItems: 'center',
              marginTop: 40,
            }}
          >
            <Button
              onPress={createAdvisor}
              style={{}}
              theme="fancy"
            >
              {getTranslation('buttons.next')}
            </Button>
          </View>
        </View>
      </View>
    </Screen>
  );
};

export default AdvisorNew;
