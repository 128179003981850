import { useNavigation } from '@react-navigation/core';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import Button from '@src/components/Button';
import Hero from '@src/components/Hero';
import Screen from '@src/components/Screen';
import { TextBodyRegular, TextHeader } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { Pressable, View } from '@src/components/shared/Themed';
import { LoadingContext } from '@src/providers/Loading';
import { ProjectContext } from '@src/providers/Project';
import React, { useContext, useEffect } from 'react';
import { ActivityIndicator } from 'react-native';

// list the projects that the investor is involved in
// create a new project
const InvestorDashboard = () => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();

  const { loadAllProjects, allProjects } = useContext(ProjectContext);
  const { loading } = useContext(LoadingContext);

  const gotoNewProject = () => {
    navigation.navigate('new');
  };

  useEffect(() => {
    loadAllProjects();
  }, []);

  return (
    <Screen>
      <View
        style={{
          marginTop: 40,
        }}
      >
        <View
          style={{
            position: 'relative',
            backgroundColor: '#fff',
          }}
        >
          <Hero
            image={require('@assets/images/screening.png')}
            title={'Welcome'}
            body={'When creating a new project you will be asked to enter a project name and then upload your documentation for analysis.'}
          >
            <View
              style={{
                marginTop: 20,
              }}
            >
              <Button
                onPress={gotoNewProject}
                theme="fancy"
                icon="chevron-forward"
              >
                Create new project
              </Button>
            </View>
          </Hero>
        </View>
        <View
          style={{
            marginVertical: 20,
            padding: '3%',
            paddingBottom: 40,
            backgroundColor: '#fff',
            borderRadius: 5,
          }}
        >
          <TextHeader style={[Styles.h2, { marginBottom: 10 }]}>Your projects:</TextHeader>
          <TextBodyRegular style={[Styles.h6, { marginBottom: 30 }]}>Click on a project below to add more documents or review previous analysis.</TextBodyRegular>
          <View
            style={{
              flexDirection: 'column',
            }}
          >
            {!loading &&
              allProjects?.map((project, index) => {
                return (
                  <Pressable
                    key={project.id}
                    onPress={() => {
                      navigation.navigate('project', { projectId: project.id });
                    }}
                    style={{
                      paddingVertical: 10,
                      width: '100%',
                      borderColor: '#000',
                      borderBottomWidth: 1,
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      flexDirection: 'row',
                      marginVertical: 10,
                    }}
                  >
                    <TextBodyRegular style={[Styles.h3]}>{`${index + 1}. ${project.title}`}</TextBodyRegular>
                    <TextBodyRegular style={[Styles.h6]}>{new Date(project.createdAt).toLocaleDateString()}</TextBodyRegular>
                  </Pressable>
                );
              })}
          </View>
          {!loading && allProjects?.length === 0 && <TextHeader style={[Styles.h2]}>You have not created any projects</TextHeader>}
          {loading && (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <ActivityIndicator
                size="large"
                color="#000"
              />
              <TextBodyRegular style={[Styles.h6, { marginLeft: 10 }]}>Loading your projects, please wait.</TextBodyRegular>
            </View>
          )}
        </View>
      </View>
    </Screen>
  );
};

export default InvestorDashboard;
