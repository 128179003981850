import { Ionicons } from '@expo/vector-icons';
import { useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import Button from '@src/components/Button';
import { esgWrapper, reviseSummaryPlaceholder } from '@src/components/Conversation/placeholders';
import CountdownTimer from '@src/components/Countdown';
import Colors from '@src/components/shared/Colors';
import { TextBodyMedium, TextBodyRegular, TextHeader } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { View } from '@src/components/shared/Themed';
import { LoadingContext } from '@src/providers/Loading';
import { chat } from '@src/utils/Chat';
import FormatDate from '@src/utils/FormatDate';
import { isCircular } from '@src/utils/isCircular';
import { useContext, useEffect, useState } from 'react';
import { ActivityIndicator } from 'react-native';
import Input from '../Input';
import Screen from '../Screen';

interface ReportsProps {
  indexId: string;
  namespace: string;
}

const Reports = () => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const route = useRoute();
  const { addLoading, removeLoading, checkLoading } = useContext(LoadingContext);
  const [response, setResponse] = useState<string>('');
  const [reviseSummary, setReviseSummary] = useState<boolean>(false);
  const [reviseSummaryQuery, setReviseSummaryQuery] = useState<string>('');
  const indexId = route?.params?.indexId;
  const namespace = route?.params?.namespace;

  useEffect(() => {
    if (route?.params?.indexId && route?.params?.namespace) {
      loadReport({ indexId, namespace });
    }
  }, [route]);

  const goBack = () => {
    navigation.navigate('sustainability');
  };

  const refreshRequest = () => {
    loadReport({ indexId, namespace });
  };

  const onSubmitEditSummary = async () => {
    addLoading('onSubmitEditSummary');
    const summarySource = {
      requestName: 'question',
      numberOfSources: 50,
      modelName: 'gpt-4o-mini',
      indexId,
      namespace,
      question: `Find material related to this question: ${reviseSummaryQuery}.`,
    };
    const summaryRequest = {
      modelName: 'gpt-4o-mini',
      templateWrapper: reviseSummaryPlaceholder.templateWrapper,
      question: reviseSummaryQuery,
    };
    const res = await chat({ start: { summarySource }, end: { summaryRequest } });
    if (res) {
      setResponse(`${response}\n\n\n${FormatDate(Date.now())}\n\n${res.summaryRequest}`);
    }
    removeLoading('onSubmitEditSummary');
  };

  // copy summary to clipboard
  const copySummary = () => {
    if (response.generateReportRequest) navigator.clipboard.writeText(response.generateReportRequest);
  };

  const loadReport = async ({ indexId, namespace }: ReportsProps) => {
    const vsReportQuery = {
      modelName: 'gpt-4o-mini',
      templateWrapper: 'Your response should be to first analyze the goal of question, and then produce a "single simplified question" that will be used to search through a vector store to retrieve relevant documents related to the question: <question>{question}</question>.  Nothing other than the "single simplified question" should be returned.  No other text should be included. Do not include any brackets like {{}}',
      question: esgWrapper.templateWrapper,
    };

    const reportSource = {
      requestName: 'vsReportQuery',
      numberOfSources: 50,
      modelName: 'gpt-4o-mini',
      indexId,
      namespace,
    };

    const generateReportRequest = {
      modelName: 'gpt-4o-mini',
      templateWrapper: esgWrapper.templateWrapper,
    };

    // --------- end TASK LIST REQUESTS

    const reportRequest = {
      0: {
        vsReportQuery,
      },
      1: {
        reportSource, // perform VS search with question
      },
      2: {
        generateReportRequest, // LLM based on {reportSource}
      },
    };

    addLoading('report');

    // Check for circular references
    if (isCircular(reportRequest)) {
      console.error('Circular reference detected in chatObj');
      return; // Handle the error as needed
    }

    try {
      const res = await chat(reportRequest);

      console.log('Reports res', res);

      if (res) {
        setResponse(`${response}\n\n\n${FormatDate(Date.now())}\n\n${res.generateReportRequest}`);
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      removeLoading('report');
    }
  };

  const onReviseSummary = () => {
    setReviseSummary(!reviseSummary);
  };

  return (
    <Screen>
      <>
        <View
          style={{
            backgroundColor: '#fff',
            marginTop: '3%',
            marginHorizontal: '3%',
            padding: 30,
          }}
        >
          <Button
            style={{ alignSelf: 'flex-start' }}
            icon="chevron-forward"
            theme="fancy"
            onPress={goBack}
          >
            Back to the all data table
          </Button>
          <TextBodyRegular style={[Styles.h4, { marginVertical: 20, color: Colors.buttonBackground }]}>Summary</TextBodyRegular>
          <TextBodyMedium style={[Styles.h3, { marginBottom: 30, color: Colors.buttonBackground }]}>Project: EY Sustainability</TextBodyMedium>
          <TextBodyRegular style={[Styles.h6, { width: '70%', marginBottom: 10 }]}>Here is a summary of the documentation that has been submitted on the organization. This summary compiles all the key information you’ve asked for and synthesizes it into consolidated reference material. You can use it to build your final deliverable. Feel free to ask it to adjust anything within the document. Remember that it’s knowledge is based on material that has been submitted.</TextBodyRegular>
        </View>
        <View
          style={{
            backgroundColor: '#fff',
            margin: '3%',
            padding: 30,
          }}
        >
          {checkLoading('report') ? (
            <>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <ActivityIndicator
                  size={20}
                  color="black"
                />
                <TextHeader style={[Styles.h4, { marginLeft: 10 }]}>Loading</TextHeader>
              </View>
              <TextBodyRegular style={[Styles.h7, { marginTop: 10, width: '45%' }]}>Please wait. This can take 60-120 seconds depending on the number of files.</TextBodyRegular>
              <CountdownTimer
                size={14}
                initialTime={120}
              />
            </>
          ) : (
            <View style={{ marginBottom: 20, alignSelf: 'flex-start' }}>
              <Button
                theme="fancy"
                onPress={() => refreshRequest()}
                icon="refresh"
                style={{ alignSelf: 'flex-start' }}
              >
                <TextBodyRegular style={[Styles.h7, { marginRight: 10, color: '#fff' }]}>Refresh</TextBodyRegular>
              </Button>
              <TextBodyRegular style={[Styles.h8, { width: '50%', marginTop: 10 }]}>You may need to refresh. This will be fixed in a future release.</TextBodyRegular>
            </View>
          )}
          {!!response && (
            <View
              style={{
                marginVertical: 20,
                paddingHorizontal: 10,
              }}
            >
              <TextBodyRegular style={[Styles.h5, { marginBottom: 15 }]}>{response}</TextBodyRegular>
              {checkLoading('onSubmitEditSummary') && (
                <View style={{ marginBottom: 30 }}>
                  <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 20 }}>
                    <TextBodyRegular style={[Styles.h4, { marginRight: 20 }]}>Loading</TextBodyRegular>
                    <ActivityIndicator
                      size={20}
                      color="black"
                    />
                  </View>
                  <TextBodyRegular style={[Styles.h7, { marginTop: 20, width: '50%' }]}>Please wait. This can take 15-60 seconds depending on the complexity of the question.</TextBodyRegular>
                  <CountdownTimer
                    size={14}
                    initialTime={30}
                  />
                </View>
              )}
              {reviseSummary && (
                <>
                  <Input
                    style={Styles.input}
                    placeholder={'How do you want to revise the summary?'}
                    placeholderTextColor={'#999'}
                    height={50}
                    onChangeText={setReviseSummaryQuery}
                    value={reviseSummaryQuery}
                    onSubmitEditing={onSubmitEditSummary}
                  />
                  <Button
                    style={{ alignSelf: 'flex-end' }}
                    onPress={onSubmitEditSummary}
                    theme="fancy"
                  >
                    <View style={{ flexDirection: 'row' }}>
                      <TextBodyRegular style={[Styles.h6, { color: '#fff' }]}>Submit</TextBodyRegular>
                    </View>
                  </Button>
                </>
              )}
              <View
                style={{
                  flexDirection: 'row',
                  marginTop: 20,
                }}
              >
                <Button
                  style={{ alignSelf: 'flex-start', marginRight: 40 }}
                  onPress={onReviseSummary}
                  theme="fancy"
                  active={reviseSummary}
                >
                  <View style={{ flexDirection: 'row' }}>
                    <TextBodyRegular style={[Styles.h4, { marginRight: 30, color: '#fff' }]}>Inquire for more details</TextBodyRegular>
                    <Ionicons
                      name="pencil"
                      size={24}
                      color="white"
                    />
                  </View>
                </Button>
                <Button
                  style={{ alignSelf: 'flex-start' }}
                  onPress={copySummary}
                  theme="fancy"
                >
                  <View style={{ flexDirection: 'row' }}>
                    <TextBodyRegular style={[Styles.h4, { marginRight: 30, color: '#fff' }]}>Copy</TextBodyRegular>
                    <Ionicons
                      name="clipboard"
                      size={24}
                      color="white"
                    />
                  </View>
                </Button>
              </View>
            </View>
          )}
        </View>
      </>
    </Screen>
  );
};

export default Reports;
