const pink = '#F598FF';
const lilac = '#94ABF9';
const darkBlue = '#171D3A';
const lightGrayPurple = '#E5E9FD';
const lighterGrayPurple = '#F0F3FF'; // Slightly lighter than lightGrayPurple
const orange = '#ff6600';
const aiBlue = '#0000ff';
const activeButton = '#0000ff';
// 244270 f7a453 ffb339
// const buttonBackground = '#244270';
const bluelighter = '#7d7fbf';
const buttonBackground = lilac; // '#966fd6'; //'#373997';
const buttonBackgroundYellow = '#D6C94A';
const buttonBackgroundDark = '#2e2e7a';
const buttonBackgroundActive = '#4a4fb0';
const green = '#90EE90';
const red = '#FF6961';
const gray = '#f1f1f1';
const orangePastel = '#FAC898';

export default {
  orange,
  darkBlue,
  aiBlue,
  activeButton,
  lilac,
  pink,
  toolTip: pink,
  // 244270 f7a453 ffb339
  // buttonBackground,
  bluelighter,
  buttonBackground,
  buttonBackgroundYellow,
  buttonBackgroundDark,
  buttonBackgroundActive,
  lightGrayPurple,
  lighterGrayPurple,
  green,
  red,
  gray,
  orangePastel,
  light: {
    background: '#fff',
    container: lightGrayPurple,
    secondary: lilac,
    text: darkBlue,
    buttonBg: '#000',
    buttonBorder: '#000',
    buttonBgInverted: '#FFF',
    buttonColor: '#fff',
    buttonInvertedColor: '#000',
    shadow: '#fff',
  },
  dark: {
    background: '#fff',
    container: lightGrayPurple,
    secondary: lilac,
    text: darkBlue,
    buttonBg: '#fff',
    buttonBorder: '#fff',
    buttonBgInverted: '#000',
    buttonColor: '#000',
    buttonInvertedColor: '#fff',
    shadow: '#000',
  },
};
