import { Ionicons } from '@expo/vector-icons';
import { useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { AdvisorContext } from '@src/components/BackOffice/Advisor/Provider';
import Conversation from '@src/components/Conversation';
import Screen from '@src/components/Screen';
import { TextBodyMedium, TextBodyRegular, TextHeader } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { Pressable, View } from '@src/components/shared/Themed';
import useResponsive from '@src/hooks/useResponsive';
import { LoadingContext } from '@src/providers/Loading';
import { ProjectContext } from '@src/providers/Project';
import { getTranslation } from '@src/services/i18n';
import FormatDate from '@src/utils/FormatDate';
import { deletePineconeNamespace } from '@src/utils/Pinecone';
import React, { useContext, useEffect } from 'react';
import { ActivityIndicator, ScrollView } from 'react-native';
import DocumentManager from './DocumentManager';

const ProjectDetail = () => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const { isWideScreen } = useResponsive();
  const route = useRoute();
  const { currentProject, loadProjectById, deleteProjectById } = useContext(ProjectContext);
  const { loadAllAdvisors } = useContext(AdvisorContext);
  const { checkLoading } = useContext(LoadingContext);
  const projectId = route?.params?.projectId;
  const loading = checkLoading('loadProjectById');

  useEffect(() => {
    loadProjectById(projectId);
  }, [route]);

  useEffect(() => {
    loadAllAdvisors();
  }, []);

  const goBack = () => {
    navigation.navigate('dashboard');
  };

  const goDelete = () => {
    if (currentProject) {
      deleteProjectById(projectId);
      if (currentProject) {
        deletePineconeNamespace(currentProject.indexId, currentProject.namespace);
      }
      // delete the files in the s3 bucket associated with the project
      goBack();
    } else {
      throw new Error('No project to delete');
    }
  };

  const movingSection = () => {
    return (
      <>
        <View
          style={{
            marginBottom: 20,
            padding: isWideScreen ? '5%' : 20,
            backgroundColor: '#fff',
            borderRadius: 5,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <View>
            <TextHeader style={[Styles.h2, { marginBottom: 10 }]}>{currentProject && currentProject.title}</TextHeader>
            <View
              style={{
                marginTop: 10,
              }}
            >
              <TextBodyMedium style={[Styles.h6, { marginRight: 5 }]}>Created on:</TextBodyMedium>
              {currentProject && <TextBodyRegular style={[Styles.h6, {}]}>{FormatDate(currentProject.createdAt)}</TextBodyRegular>}
            </View>
          </View>
          <Pressable
            onPress={goDelete}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              backgroundColor: 'red',
              padding: 10,
              borderRadius: 10,
              alignSelf: 'flex-end',
              marginTop: 30,
            }}
          >
            <Ionicons
              name="warning"
              size={30}
              color="white"
            />
            <TextBodyMedium style={[Styles.h6, { color: '#fff', marginLeft: 5, textDecorationLine: 'underline' }]}>{getTranslation('buttons.delete')}</TextBodyMedium>
          </Pressable>
        </View>
      </>
    );
  };

  return (
    <Screen>
      <ScrollView>
        <View
          style={{
            width: '100%',
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Pressable
              onPress={goBack}
              style={{
                flexDirection: 'row',
                alignSelf: 'flex-start',
                alignItems: 'center',
                marginVertical: 40,
              }}
            >
              <Ionicons
                name="chevron-back"
                size={30}
              />
              <TextBodyMedium style={[Styles.h6, { textDecorationLine: 'underline' }]}>{getTranslation('project.newProject.back')}</TextBodyMedium>
            </Pressable>
          </View>
          <View style={{ flexDirection: isWideScreen ? 'row' : 'column', justifyContent: 'space-between' }}>
            {currentProject && !loading && (
              <>
                <View
                  style={{
                    display: isWideScreen ? 'none' : 'flex',
                  }}
                >
                  {movingSection()}
                </View>
                <View
                  style={{
                    width: isWideScreen ? '68%' : '100%',
                  }}
                >
                  {currentProject.documents?.length ? (
                    <Conversation currentProject={currentProject} />
                  ) : (
                    <View
                      style={{
                        padding: 20,
                        backgroundColor: '#fff',
                        borderRadius: 5,
                      }}
                    >
                      <TextBodyRegular style={[Styles.h4, { marginHorizontal: 10 }]}>Please upload documents on the right to begin creating your report.</TextBodyRegular>
                    </View>
                  )}
                </View>
                <View
                  style={{
                    width: isWideScreen ? '30%' : '100%',
                    marginVertical: isWideScreen ? 0 : 20,
                  }}
                >
                  <View
                    style={{
                      display: isWideScreen ? 'flex' : 'none',
                    }}
                  >
                    {movingSection()}
                  </View>
                  <DocumentManager />
                </View>
              </>
            )}
            {(!currentProject || loading) && (
              <View
                style={{
                  alignItems: 'center',
                  width: '100%',
                  marginTop: 40,
                }}
              >
                <ActivityIndicator
                  size="large"
                  color="#000"
                />
                <TextHeader style={[Styles.h3, { marginTop: 20, alignSelf: 'center' }]}>Please wait. Your project is loading.</TextHeader>
              </View>
            )}
          </View>
        </View>
      </ScrollView>
    </Screen>
  );
};

export default ProjectDetail;
