import { Feather } from '@expo/vector-icons';
import { ReportVersion } from '@src/API';
import { AdvisorContext } from '@src/components/BackOffice/Advisor/Provider';
import Button from '@src/components/Button';
import Input from '@src/components/Input';
import Styles from '@src/components/shared/Styles';
import { LoadingContext } from '@src/providers/Loading';
import { chat } from '@src/utils/Chat';
import FinancialModel from '@src/utils/FinancialModel';
import React, { useContext, useState } from 'react';
import { ActivityIndicator, NativeSyntheticEvent, TextInputKeyPressEventData, View } from 'react-native';
import { TextBodyMedium, TextBodyRegular } from '../shared/StyledText';
import { Picker, Pressable } from '../shared/Themed';
import { advisorPlaceholder, defaultPlaceholder, sourcePlaceholder } from './placeholders';
// Import the uuid library

// Generate a new report with a unique ID

export interface ReportChainz extends ReportVersion {
  interimData: any;
}

const Conversation: React.FC<any> = ({ indexId, namespace, callback }) => {
  const [query, setQuery] = useState<string>('');
  const [templateWrapper, setTemplateWrapper] = useState<string>('');
  const [templateAdvisor, setTemplateAdvisor] = useState<string>('');
  const [templateAdvisorRefine, setTemplateAdvisorRefine] = useState<string>('');
  const [numberOfAdvisor, setNumberOfAdvisor] = useState<string>('');
  const [templateSource, setTemplateSource] = useState<string>('');
  const [templateSourceRefine, setTemplateSourceRefine] = useState<string>('');
  const [numberOfSources, setNumberOfSources] = useState<string>('');
  const [modelName, setModelName] = useState<string>('OpenAI (gpt-4o-mini)');
  const [advisorKey, setAdvisorKey] = useState<string>('esg');
  const { allAdvisors } = useContext(AdvisorContext);
  const [showConfig, setShowConfig] = useState(false);
  const [themeSelection, setThemeSelection] = useState<string | undefined>();
  const { loading, addLoading, removeLoading } = useContext(LoadingContext);

  const models = {
    'OpenAI (gpt-4o-mini)': 'gpt-4o-mini',
    'OpenAI (gpt-4o)': 'openai-4o',
    'Claude (claude-3-opus-20240229)': 'claude-opus-3',
    'Mistral (mistral-small)': 'mistral-small',
    'Mistral (mistral-large)': 'mistral-large',
    'gpt-4o-mini': 'gpt-4o-mini',
    'openai-4o': 'openai-4o',
    'claude-opus-3': 'claude-opus-3',
    'mistral-small': 'mistral-small',
    'mistral-large': 'mistral-large',
  };

  const selectableAdvisors = allAdvisors?.map(advisor => advisor.title) || [];

  const toggleShowConfig = () => {
    setShowConfig(!showConfig);
  };

  const onKeyPressQuery = (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    // Handle the key press event here
    if (event.nativeEvent.key === 'Enter') {
      onHandleChat();
    }
  };

  const onHandleChat = async () => {
    sendChat(query);
  };

  const triggerPredinedPrompt = async (category: string | undefined) => {
    if (category === undefined) {
      setQuery('');
      setTemplateWrapper('');
      setTemplateSource('');
      setTemplateSourceRefine('');
      setTemplateAdvisor('');
      setTemplateAdvisorRefine('');
      setNumberOfSources('');
      setNumberOfAdvisor('');
      setModelName('');
    } else {
      setQuery(FinancialModel[category].question);
      setTemplateWrapper(FinancialModel[category].templateWrapper);
      setTemplateSource(FinancialModel[category].templateSource);
      setTemplateSourceRefine(FinancialModel[category].templateSourceRefine);
      setTemplateAdvisor(FinancialModel[category].templateAdvisor);
      setTemplateAdvisorRefine(FinancialModel[category].templateAdvisorRefine);
      setNumberOfSources(FinancialModel[category].numberOfSources);
      setNumberOfAdvisor(FinancialModel[category].numberOfAdvisor);
      setModelName(FinancialModel[category].modelName);
    }
  };

  const triggerClimateChangeEmissions = () => {
    triggerPredinedPrompt('climateChangeEmissions');
    setThemeSelection('climateChangeEmissions');
  };
  const triggerResourceUseAndEfficiency = () => {
    triggerPredinedPrompt('resourceUseAndEfficiency');
    setThemeSelection('resourceUseAndEfficiency');
  };
  const triggerEnvironmentalManagementSystems = () => {
    triggerPredinedPrompt('environmentalManagementSystems');
    setThemeSelection('environmentalManagementSystems');
  };
  const triggerDemo = () => {
    triggerPredinedPrompt('demo');
    setThemeSelection('demo');
  };

  const triggerClearReportSelection = () => {
    triggerPredinedPrompt(undefined);
    setThemeSelection(undefined);
  };

  const sendChat = async (question: string) => {
    // todo this is going to need a refacto
    addLoading('sendChat');
    setShowConfig(false);
    const selectedModel = models[modelName];
    const selectedKnow = allAdvisors?.filter(advisor => advisor.title.toLowerCase() === 'esg')[0];
    const indexIdAdvisor = selectedKnow.indexId;
    const namespaceAdvisor = selectedKnow.namespace;

    const newTemplateWrapper = templateWrapper.length > 0 ? templateWrapper : defaultPlaceholder.templateWrapperPlaceholder;
    const newTemplateSource = templateSource.length > 0 ? templateSource : sourcePlaceholder.placeholder;
    const newTemplateSourceRefine = templateSourceRefine.length > 0 ? templateSourceRefine : sourcePlaceholder.refinePlaceholder;
    const newTemplateAdvisor = templateAdvisor.length > 0 ? templateAdvisor : advisorPlaceholder.placeholder;
    const newTemplateAdvisorRefine = templateAdvisorRefine.length > 0 ? templateAdvisorRefine : advisorPlaceholder.refinePlaceholder;

    setTemplateWrapper(newTemplateWrapper);
    setTemplateSource(newTemplateSource);
    setTemplateSourceRefine(newTemplateSourceRefine);
    setTemplateAdvisor(newTemplateAdvisor);
    setTemplateAdvisorRefine(newTemplateAdvisorRefine);

    const reportGenerateChainRequest = { templateWrapper: newTemplateWrapper, templateSource: newTemplateSource, templateSourceRefine: newTemplateSourceRefine, templateAdvisor: newTemplateAdvisor, templateAdvisorRefine: newTemplateAdvisorRefine, indexIdAdvisor, namespaceAdvisor, question, indexId, namespace, numberOfSources, numberOfAdvisor, modelName: selectedModel };
    console.log(' Chat Obj: ', reportGenerateChainRequest);
    try {
      const res = await chat({ reportGenerateChainRequest });
      if (res) {
        const { interimData, ...rest } = res;
        const newReport = { ...rest, text: rest.chained.reportGenerateChainRequest, date: `${Date.now()}` };
        console.log('newReport', newReport);
        callback(newReport);
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      removeLoading('sendChat');
    }
  };

  const isSendDisabled = query.length === 0;
  return (
    <>
      <View
        style={{
          // padding: 30,
          backgroundColor: '#fff',
          borderRadius: 5,
          padding: '3%',
        }}
      >
        <View style={{}}>
          <View>
            <Input
              style={Styles.input}
              placeholder=""
              placeholderTextColor={'#999'}
              height={100}
              onChangeText={text => {
                setQuery(text);
              }}
              value={query}
              onKeyPress={onKeyPressQuery}
              multiline
            />
          </View>
          {loading ? (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 20,
              }}
            >
              <TextBodyRegular
                style={[
                  Styles.h4,
                  {
                    marginRight: 10,
                  },
                ]}
              >
                Sending...
              </TextBodyRegular>
              <ActivityIndicator
                size="small"
                color="#000"
                style={{
                  alignSelf: 'flex-start',
                }}
              />
            </View>
          ) : (
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginVertical: 10,
              }}
            >
              <Button
                disabled={isSendDisabled}
                onPress={onHandleChat}
                icon="chevron-forward"
                theme="fancy"
              >
                Send
              </Button>
            </View>
          )}
        </View>
        <TextBodyRegular style={[Styles.h6, { marginVertical: 10 }]}>Ask a question or select from our predefined options below.</TextBodyRegular>
        <View
          style={{
            alignSelf: 'flex-end',
          }}
        >
          <Button
            onPress={toggleShowConfig}
            icon={`chevron-${showConfig ? 'up' : 'down'}`}
            theme="simple"
          >{`${showConfig ? 'Hide' : 'Show'} config`}</Button>
        </View>
        {showConfig && (
          <View
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <View
              style={{
                marginBottom: 20,
              }}
            >
              <TextBodyMedium style={[Styles.h4, { marginBottom: 10 }]}>Report type:</TextBodyMedium>
              <Pressable
                onPress={triggerClimateChangeEmissions}
                style={{ marginRight: 20, flexDirection: 'row', alignItems: 'center' }}
              >
                <TextBodyRegular style={[Styles.h6, { marginRight: 10 }]}>Climate Change Emissions</TextBodyRegular>
                {themeSelection === 'climateChangeEmissions' ? (
                  <Feather
                    name="check-circle"
                    size={30}
                  />
                ) : (
                  <Feather
                    name="circle"
                    size={30}
                  />
                )}
              </Pressable>
              <Pressable
                onPress={triggerResourceUseAndEfficiency}
                style={{ marginRight: 20, flexDirection: 'row', alignItems: 'center' }}
              >
                <TextBodyRegular style={[Styles.h6, { marginRight: 10 }]}>Resource Use And Efficiency</TextBodyRegular>
                {themeSelection === 'resourceUseAndEfficiency' ? (
                  <Feather
                    name="check-circle"
                    size={30}
                  />
                ) : (
                  <Feather
                    name="circle"
                    size={30}
                  />
                )}
              </Pressable>
              <Pressable
                onPress={triggerEnvironmentalManagementSystems}
                style={{ marginBottom: 10, marginRight: 20, flexDirection: 'row', alignItems: 'center' }}
              >
                <TextBodyRegular style={[Styles.h6, { marginRight: 10 }]}>Environmental Management Systems</TextBodyRegular>
                {themeSelection === 'environmentalManagementSystems' ? (
                  <Feather
                    name="check-circle"
                    size={30}
                  />
                ) : (
                  <Feather
                    name="circle"
                    size={30}
                  />
                )}
              </Pressable>
              <Pressable
                onPress={triggerDemo}
                style={{ marginBottom: 10, marginRight: 20, flexDirection: 'row', alignItems: 'center' }}
              >
                <TextBodyRegular style={[Styles.h6, { marginRight: 10 }]}>Demo</TextBodyRegular>
                {themeSelection === 'demo' ? (
                  <Feather
                    name="check-circle"
                    size={30}
                  />
                ) : (
                  <Feather
                    name="circle"
                    size={30}
                  />
                )}
              </Pressable>
              <Button
                style={{ fontSize: 14 }}
                disabled={!!!themeSelection}
                onPress={triggerClearReportSelection}
                theme="simple"
              >
                Clear selection
              </Button>
            </View>
            <View>
              <TextBodyMedium style={[Styles.h4, { marginTop: 20 }]}>Model:</TextBodyMedium>
              <TextBodyRegular style={[Styles.h6, {}]}>{`Select which model you want to use: (OpenAI, Claude, Mistral)`}</TextBodyRegular>
              <Picker
                style={[Styles.picker, { marginBottom: 10, color: '#000' }]}
                options={['OpenAI (gpt-4o-mini)', 'OpenAI (gpt-4o)', 'Claude (claude-3-opus-20240229)', 'Mistral (mistral-small)', 'Mistral (mistral-large)']}
                setResult={setModelName}
                selectedValue={modelName}
              />
            </View>
            <View>
              <TextBodyMedium style={[Styles.h4, { marginTop: 20 }]}>Wrapping template:</TextBodyMedium>
              <TextBodyRegular style={[Styles.h6, { marginTop: 10 }]}>{`This is the most important template. It is what is sent to the final query to produce the report.`}</TextBodyRegular>
              <View style={{}}>
                <TextBodyRegular style={[Styles.h7, { marginTop: 10 }]}>{`- {question} what is entered in the field above (required)`}</TextBodyRegular>
                <TextBodyRegular style={[Styles.h7, { marginTop: 10 }]}>{`- {chat_history} dynamically created [not used yet] (optional)`}</TextBodyRegular>
                <TextBodyRegular style={[Styles.h7, { marginTop: 10 }]}>{`- {source} the text from the source docs (optional)`}</TextBodyRegular>
                <TextBodyRegular style={[Styles.h7, { marginTop: 10 }]}>{`- {sourceRefined} refined source docs (optional)`}</TextBodyRegular>
                <TextBodyRegular style={[Styles.h7, { marginTop: 10 }]}>{`- {advisor} the text from the advisor (optional)`}</TextBodyRegular>
                <TextBodyRegular style={[Styles.h7, { marginTop: 10 }]}>{`- {advisorRefined} refined advisor text (optional)`}</TextBodyRegular>
              </View>
              <Input
                style={[Styles.input]}
                height={150}
                placeholder={defaultPlaceholder.templateWrapperPlaceholder}
                placeholderTextColor={'#999'}
                onChangeText={setTemplateWrapper}
                value={templateWrapper}
                multiline
              />
            </View>
            <View
              style={{
                marginVertical: 20,
              }}
            >
              <TextBodyMedium style={[Styles.h4, { marginTop: 30, marginBottom: 10 }]}>{`Sources template: {source}`}</TextBodyMedium>
              <View style={{}}>
                <TextBodyRegular style={[Styles.h6, { marginTop: 10 }]}>{`You must include {question}, {chat_history}.  This produces the {context} for the wrapping template query below.`}</TextBodyRegular>
                <Input
                  style={Styles.input}
                  placeholder={sourcePlaceholder.Placeholder}
                  placeholderTextColor={'#999'}
                  height={150}
                  onChangeText={setTemplateSource}
                  value={templateSource}
                  multiline
                />
              </View>
              <View>
                <TextBodyMedium style={[Styles.h4, { marginTop: 10 }]}>Amount of material:</TextBodyMedium>
                <View
                  style={{
                    flexDirection: 'row',
                    // justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <TextBodyRegular style={[Styles.h6, { marginRight: 15 }]}>{`The number of source material you want returned:`}</TextBodyRegular>
                  <Input
                    style={[
                      Styles.input,
                      {
                        width: 30,
                        alignSelf: 'flex-start',
                      },
                    ]}
                    placeholder="(default 10 if empty)"
                    placeholderTextColor={'#999'}
                    onChangeText={setNumberOfSources}
                    value={numberOfSources}
                  />
                </View>
              </View>
              <View style={{}}>
                <TextBodyMedium style={[Styles.h4, { marginTop: 10 }]}>{`Source refinement template: {sourceRefined}`}</TextBodyMedium>
                <TextBodyRegular style={[Styles.h6, { marginTop: 10 }]}>{`You must include {context} and {question}. This query wraps the source materials for an additional refinement LLM.  This will be used for scoring the source material for relevancy to the question.`}</TextBodyRegular>
                <Input
                  style={Styles.input}
                  placeholder={sourcePlaceholder.RefinePlaceholder}
                  placeholderTextColor={'#999'}
                  height={150}
                  onChangeText={setTemplateSourceRefine}
                  value={templateSourceRefine}
                  multiline
                />
              </View>
            </View>
            <View
              style={{
                marginVertical: 20,
              }}
            >
              <TextBodyMedium style={[Styles.h4, { marginTop: 20, marginBottom: 10 }]}>Advisor template</TextBodyMedium>
              <View>
                <TextBodyRegular style={[Styles.h6, {}]}>{`Select which advisor you want to use: (ESG)`}</TextBodyRegular>
                <Picker
                  style={[Styles.picker, { marginBottom: 10, color: '#000' }]}
                  options={selectableAdvisors}
                  setResult={setAdvisorKey}
                  selectedValue={advisorKey}
                />
              </View>
              <View style={{}}>
                <TextBodyMedium style={[Styles.h4, {}]}>{`Advisor query: {advisor}`}</TextBodyMedium>
                <TextBodyRegular style={[Styles.h6, { marginTop: 10 }]}>{`You must include {question}.`}</TextBodyRegular>
                <Input
                  style={Styles.input}
                  placeholder={advisorPlaceholder.placeholder}
                  placeholderTextColor={'#999'}
                  height={150}
                  onChangeText={setTemplateAdvisor}
                  value={templateAdvisor}
                  multiline
                />
              </View>
              <View>
                <TextBodyMedium style={[Styles.h4, { marginTop: 10 }]}>Amount of material:</TextBodyMedium>
                <View
                  style={{
                    flexDirection: 'row',
                    // justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <TextBodyRegular style={[Styles.h6, { marginRight: 15 }]}>{`The number of advisord material you want returned:`}</TextBodyRegular>
                  <Input
                    style={[
                      Styles.input,
                      {
                        width: 30,
                        alignSelf: 'flex-start',
                      },
                    ]}
                    placeholder="(default 10 if empty)"
                    placeholderTextColor={'#999'}
                    onChangeText={setNumberOfAdvisor}
                    value={numberOfAdvisor}
                  />
                </View>
              </View>
              <View
                style={{
                  marginVertical: 20,
                }}
              >
                <TextBodyMedium style={[Styles.h4, {}]}>{`Advisor refinement query: {advisorRefined}`}</TextBodyMedium>
                <TextBodyRegular style={[Styles.h6, { marginTop: 10 }]}>{`You must include {context} and {question}. This query wraps the advisor for an additional refinement LLM that enables scoring for relevancy to the question.`}</TextBodyRegular>
                <Input
                  style={Styles.input}
                  placeholder={advisorPlaceholder.refinePlaceholder}
                  placeholderTextColor={'#999'}
                  height={150}
                  onChangeText={setTemplateAdvisorRefine}
                  value={templateAdvisorRefine}
                  multiline
                />
              </View>
            </View>
          </View>
        )}
      </View>
    </>
  );
};

export default Conversation;
