import Button from '@src/components/Button';
import Input from '@src/components/Input';
import { TextInterRegular } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { View } from '@src/components/shared/Themed';
import { AlignmentContext } from '@src/providers/Alignment';
import { ModalContext } from '@src/providers/Modal';
import React, { useContext, useState } from 'react';
import { NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import SaveEditableInputModal from './SaveEditableInputModal';

type InputEditableProps = {
  property: string;
  onAlign: (property: string) => void;
  height?: number;
};
const InputEditable = ({ property, onAlign, height }: InputEditableProps) => {
  const { alignmentState, setAlignmentState } = useContext(AlignmentContext);
  const { setModalContent } = useContext(ModalContext);
  const value = alignmentState[property];
  const [inputValue, setInputValue] = useState<string>(value);
  const [state, setState] = useState<string>();

  const onSave = () => {
    setAlignmentState({ ...alignmentState, [property]: inputValue });
    setState(undefined);
    setModalContent(undefined);
  };

  const onCancel = () => {
    setInputValue(value);
    setAlignmentState({ ...alignmentState, [property]: value });
    setState(undefined);
    setModalContent(undefined);
  };

  const onRealign = () => {
    onAlign && onAlign(property);
    setState(undefined);
    setModalContent(undefined);
  };

  const onKeyPress = (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    if (e.key === 'Escape') {
      onCancel();
    } else if (e.shiftKey && e.key === 'Enter') {
      // Trigger the same function as onSave button
      onSave();
    }
    console.log('keypress', e.key);
  };

  return (
    <>
      {state === 'edit' ? (
        <>
          <Input
            style={[Styles.h4]}
            onChangeText={setInputValue}
            value={inputValue || value || ''}
            // numberOfLines={1}
            multiline={true}
            onKeyPress={onKeyPress}
            height={height}
          />
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
            }}
          >
            <Button
              theme="fancy"
              modal={
                <SaveEditableInputModal
                  onYes={onRealign}
                  onNo={onSave}
                />
              }
            >
              Save
            </Button>
            <Button
              theme="fancy"
              onPress={onCancel}
            >
              Cancel
            </Button>
          </View>
        </>
      ) : (
        <>
          <TextInterRegular style={[Styles.h4]}>{value}</TextInterRegular>
          <View
            style={{
              alignSelf: 'flex-end',
            }}
          >
            <Button
              theme="fancy"
              onPress={() => {
                setState('edit');
              }}
            >
              Edit
            </Button>
          </View>
        </>
      )}
    </>
  );
};

export default InputEditable;
