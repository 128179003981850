/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getSubmissionPreScreen = /* GraphQL */ `query GetSubmissionPreScreen($id: ID!) {
  getSubmissionPreScreen(id: $id) {
    id
    deliverables
    requirements
    deadline
    emailLLM
    titleLLM
    deliverablesLLM
    requirementsLLM
    assignees
    ownerEmail
    indexId
    namespace
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSubmissionPreScreenQueryVariables,
  APITypes.GetSubmissionPreScreenQuery
>;
export const listSubmissionPreScreens = /* GraphQL */ `query ListSubmissionPreScreens(
  $filter: ModelSubmissionPreScreenFilterInput
  $limit: Int
  $nextToken: String
) {
  listSubmissionPreScreens(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deliverables
      requirements
      deadline
      emailLLM
      titleLLM
      deliverablesLLM
      requirementsLLM
      assignees
      ownerEmail
      indexId
      namespace
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSubmissionPreScreensQueryVariables,
  APITypes.ListSubmissionPreScreensQuery
>;
export const getProject = /* GraphQL */ `query GetProject($id: ID!) {
  getProject(id: $id) {
    id
    title
    history
    indexId
    namespace
    documents
    reports {
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProjectQueryVariables,
  APITypes.GetProjectQuery
>;
export const listProjects = /* GraphQL */ `query ListProjects(
  $filter: ModelProjectFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      history
      indexId
      namespace
      documents
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProjectsQueryVariables,
  APITypes.ListProjectsQuery
>;
export const getAdvisor = /* GraphQL */ `query GetAdvisor($id: ID!) {
  getAdvisor(id: $id) {
    id
    title
    owners
    indexId
    namespace
    documents
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAdvisorQueryVariables,
  APITypes.GetAdvisorQuery
>;
export const listAdvisors = /* GraphQL */ `query ListAdvisors(
  $filter: ModelAdvisorFilterInput
  $limit: Int
  $nextToken: String
) {
  listAdvisors(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      owners
      indexId
      namespace
      documents
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAdvisorsQueryVariables,
  APITypes.ListAdvisorsQuery
>;
