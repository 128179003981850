import { ReportVersion } from '@src/API';
import { fetchAuthSession } from 'aws-amplify/auth';
import Constants from 'expo-constants';

export interface ChatResponse extends ReportVersion {
  twoChainzOutput: any;
}

async function getCognitoToken() {
  try {
    const session = await fetchAuthSession({ forceRefresh: true });
    const token = session.tokens?.idToken?.toString();
    // const token = session.tokens?.accessToken?.toString();
    return token;
  } catch (error) {
    console.error('Error fetching Cognito session:', error);
    return null;
  }
}

interface IChat {
  templateWrapper?: string;
  question?: string;
  modelName: string;
  templateSource?: string;
  templateSourceRefine?: string;
  templateAdvisor?: string;
  templateAdvisorRefine?: string;
  indexIdAdvisor?: string;
  namespaceAdvisor?: string;
  indexId?: string;
  namespace?: string;
  numberOfSources?: string | number;
  numberOfAdvisor?: string | number;
  chat_history?: string;
}
interface IChatCollection {
  [key: string]: IChat;
}
interface IChatCollectionRequest {
  [key: string]: IChatCollection;
}

interface IChatResponse {
  text: string;
  sourceMaterial?: any[];
  advisorMaterial?: any[];
  interimData?: any;
}

interface IChatResponseCollection {
  [key: string]: IChatResponse; // <--  todo This was once "ChatResponse"!!
}

interface IChatResponseCollectionRequest {
  [key: string]: IChatResponseCollection;
}

/**
 * Chat with the Pinecone model.
 * @param templateWrapper The template to use for chat.
 * @param templateSource The query template to search the source documents
 * @param templateSourceRefine The template we use to refine the source documents that were returned
 * @param templateAdvisor The query template to search the advisor documents
 * @param templateAdvisorRefine The template we use to refine the advisor documents that were returned
 * @param question The question to ask the Pinecone model.
 * @param modelName The model to use for chat.
 * @param indexId The id of the index.
 * @param namespace The project name.
 * @param indexIdAdvisor The index of the advisor.
 * @param namespaceAdvisor The namespace of the advisor.
 * @param namespace The project name.
 * @param numberOfSources The number of sources to return.
 * @param numberOfAdvisor The number of advisor documents to return.
 * @param chat_history The history of the chat.
 * @returns The response from the Pinecone model.
 */
export async function chat(chatObj: IChatCollectionRequest): Promise<any | undefined> {
  const remoteUrl = 'https://ukt4rwijik537kkp3zlyh6ax3u0sbrht.lambda-url.us-east-1.on.aws';
  const url = process.env.NODE_ENV !== 'development' ? remoteUrl : 'http://localhost:3000/chat';
  const apiKey = Constants?.expoConfig?.extra?.aws.API_KEY;

  if (!apiKey) {
    console.error('API key is not configured.');
    return;
  }

  try {
    // const controller = new AbortController();
    // const timeoutId = setTimeout(() => controller.abort(), 1050000); // 1050 seconds timeout

    // TODO This brings back AWS Cognito
    // const token = await getCognitoToken();
    // const authHeader = `Bearer ${token}`;

    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': authHeader, // <-- TODO we need this for AWS Cognito
        // 'x-api-key': apiKey,
        // Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token
      },
      body: JSON.stringify(chatObj),
      // signal: controller.signal,
      credentials: 'same-origin', // or 'same-origin' if your request is to the same domain
    });

    if (!res.ok) {
      console.error(`Failed to fetch: ${res.status} ${res.statusText}`);
      return;
    }

    const data: IChatResponseCollectionRequest[] = await res.json();

    const flattenedResults = data.reduce((acc, result) => {
      return { ...acc, ...result.text };
    }, {});

    return flattenedResults;
  } catch (error) {
    if (error.name === 'AbortError') {
      console.error('Request timed out');
    } else {
      console.error('Failed to fetch or parse JSON:', error);
    }
  }
}
