import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { reviseEmailPlaceholder, reviseSummaryPlaceholder, taskListWrapperEmail, taskListWrapperSummary } from '@src/components/Conversation/placeholders';
import CountdownTimer from '@src/components/Countdown';
import Input from '@src/components/Input';
import { TextBodyMedium, TextBodyRegular, TextHeader } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import useResponsive from '@src/hooks/useResponsive';
import { LoadingContext } from '@src/providers/Loading';
import { ModalContext } from '@src/providers/Modal';
import { chat } from '@src/utils/Chat';
import { uploadFiles } from '@src/utils/FileUpload';
import FormatDate from '@src/utils/FormatDate';
import React, { useContext, useEffect, useState } from 'react';
import { ActivityIndicator, ScrollView, View } from 'react-native';
import Button from '../Button';

interface TaskModalProps {
  task: any;
  indexId?: string;
  namespace?: string;
}

const TaskModal = ({ task, indexId, namespace }: TaskModalProps) => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const { isWideScreen } = useResponsive();
  const { setModalContent } = useContext(ModalContext);
  const { checkLoading, addLoading, removeLoading } = useContext(LoadingContext);
  const [emailChat, setEmailChat] = useState<string>();
  const [summaryChat, setSummaryChat] = useState<string>();
  const [assignee, setAssignee] = useState<string>();
  const [reviseEmail, setReviseEmail] = useState<boolean>(false);
  const [reviseSummary, setReviseSummary] = useState<boolean>(false);
  const [reviseEmailQuery, setReviseEmailQuery] = useState<string>('');
  const [reviseSummaryQuery, setReviseSummaryQuery] = useState<string>('');

  const onSubmitEditEmail = async () => {
    addLoading('onSubmitEditEmail');
    const emailRequest = {
      modelName: 'gpt-4o-mini',
      templateWrapper: reviseEmailPlaceholder.templateWrapper,
      question: `<emailChat>${emailChat}</emailChat> Revise <emailChat> based on this information:${reviseEmailQuery}`,
    };

    const res = await chat({ 0: { emailRequest } });
    if (res) {
      setEmailChat(`${emailChat}\n\n\n${FormatDate(Date.now())}\n\n${res.emailRequest}`);
    }
    removeLoading('onSubmitEditEmail');
  };
  const onSubmitEditSummary = async () => {
    addLoading('onSubmitEditSummary');
    const summarySource = {
      requestName: 'question',
      numberOfSources: 50,
      modelName: 'gpt-4o-mini',
      indexId,
      namespace,
      question: `Find material related to this question: ${reviseSummaryQuery}.`,
    };
    const summaryRequest = {
      modelName: 'gpt-4o-mini',
      templateWrapper: reviseSummaryPlaceholder.templateWrapper,
      question: reviseSummaryQuery,
    };
    const res = await chat({ start: { summarySource }, end: { summaryRequest } });
    if (res) {
      setSummaryChat(`${summaryChat}\n\n\n${FormatDate(Date.now())}\n\n${res.summaryRequest}`);
    }
    removeLoading('onSubmitEditSummary');
  };

  const closeModal = () => {
    setModalContent(undefined);
  };

  // create an email based on the task and assignee
  const generateTaskDetails = async () => {
    const summarySource = {
      requestName: 'question',
      numberOfSources: 50,
      modelName: 'gpt-4o-mini',
      indexId,
      namespace,
      question: `Find material related to this task: ${task.name} ${task.summary}`,
    };
    const emailChainRequest = {
      modelName: 'gpt-4o-mini',
      templateWrapper: taskListWrapperEmail.templateWrapper,
      question: `${task.name} ${task.summary} `,
    };
    const summaryChainRequest = {
      modelName: 'gpt-4o-mini',
      templateWrapper: taskListWrapperSummary.templateWrapper,
    };
    // make this pinecone enabled
    return chat({ getSource: { summarySource }, parrallel: { emailChainRequest, summaryChainRequest } });
  };

  // copy email to clipboard
  const copyEmail = () => {
    if (emailChat) navigator.clipboard.writeText(emailChat);
  };

  // copy summary to clipboard
  const copySummary = () => {
    if (summaryChat) navigator.clipboard.writeText(summaryChat);
  };

  const onReviseEmail = () => {
    setReviseEmail(!reviseEmail);
  };

  const onReviseSummary = () => {
    setReviseSummary(!reviseSummary);
  };

  const handleFileUpload = async (files: FileList | null) => {
    if (files) {
      addLoading('uploadFileToSource');
      try {
        if (indexId && namespace) {
          await uploadFiles(files, indexId, namespace);
          await load();
        }
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        removeLoading('uploadFileToSource');
      }
    }
  };

  const load = async () => {
    addLoading('generateTaskDetails');
    const taskDetails = await generateTaskDetails();

    if (taskDetails) {
      console.log('taskDetails', taskDetails);
      if (taskDetails.emailChainRequest) setEmailChat(taskDetails.emailChainRequest);
      if (taskDetails.summaryChainRequest) setSummaryChat(taskDetails.summaryChainRequest);
    }
    removeLoading('generateTaskDetails');
  };

  useEffect(() => {
    load();
  }, []);

  const emailSummaryElement = () => {
    return (
      <>
        {emailChat && (
          <>
            <View
              style={{
                marginVertical: 20,
                width: '50%',
                paddingHorizontal: 10,
              }}
            >
              <TextBodyMedium style={[Styles.h4, { marginBottom: 15 }]}>Email:</TextBodyMedium>
              <TextBodyRegular style={[Styles.h5, { marginBottom: 15 }]}>{emailChat}</TextBodyRegular>
              {checkLoading('onSubmitEditEmail') && (
                <View style={{ marginBottom: 30 }}>
                  <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 20 }}>
                    <TextBodyRegular style={[Styles.h4, { marginRight: 20 }]}>Loading</TextBodyRegular>
                    <ActivityIndicator
                      size={20}
                      color="black"
                    />
                  </View>
                  <TextBodyRegular style={[Styles.h7, { marginTop: 20, width: '50%' }]}>Please wait. This can take 15-60 seconds depending on the complexity of the question.</TextBodyRegular>
                  <CountdownTimer
                    size={14}
                    initialTime={30}
                  />
                </View>
              )}
              {reviseEmail && (
                <>
                  <Input
                    style={Styles.input}
                    placeholder={'How do you want to revise the email?'}
                    placeholderTextColor={'#999'}
                    height={50}
                    onChangeText={setReviseEmailQuery}
                    value={reviseEmailQuery}
                    onSubmitEditing={onSubmitEditEmail}
                  />
                  <Button
                    style={{ alignSelf: 'flex-end', marginBottom: 20 }}
                    onPress={onSubmitEditEmail}
                    theme="fancy"
                  >
                    <TextBodyRegular style={[Styles.h6, { color: '#fff' }]}>Submit</TextBodyRegular>
                  </Button>
                </>
              )}
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                <Button
                  theme="fancy"
                  style={{ alignSelf: 'flex-start', marginRight: 40 }}
                  onPress={onReviseEmail}
                  active={reviseEmail}
                >
                  <View style={{ flexDirection: 'row' }}>
                    <TextBodyRegular style={[Styles.h4, { marginRight: 30, color: '#fff' }]}>Revise</TextBodyRegular>
                    <Ionicons
                      name="pencil"
                      size={24}
                      color="white"
                    />
                  </View>
                </Button>
                <Button
                  theme="fancy"
                  style={{ alignSelf: 'flex-start' }}
                  onPress={copyEmail}
                >
                  <View style={{ flexDirection: 'row' }}>
                    <TextBodyRegular style={[Styles.h4, { marginRight: 30, color: '#fff' }]}>Copy</TextBodyRegular>
                    <Ionicons
                      name="clipboard"
                      size={24}
                      color="white"
                    />
                  </View>
                </Button>
              </View>
            </View>
          </>
        )}
        {summaryChat && (
          <>
            <View
              style={{
                marginVertical: 20,
                width: '50%',
                paddingHorizontal: 10,
              }}
            >
              <TextBodyMedium style={[Styles.h4, { marginBottom: 15 }]}>Summary:</TextBodyMedium>
              <TextBodyRegular style={[Styles.h5, { marginBottom: 15 }]}>{summaryChat}</TextBodyRegular>
              {checkLoading('onSubmitEditSummary') && (
                <View style={{ marginBottom: 30 }}>
                  <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 20 }}>
                    <TextBodyRegular style={[Styles.h4, { marginRight: 20 }]}>Loading</TextBodyRegular>
                    <ActivityIndicator
                      size={20}
                      color="black"
                    />
                  </View>
                  <TextBodyRegular style={[Styles.h7, { marginTop: 20, width: '50%' }]}>Please wait. This can take 15-60 seconds depending on the complexity of the question.</TextBodyRegular>
                  <CountdownTimer
                    size={14}
                    initialTime={30}
                  />
                </View>
              )}
              {reviseSummary && (
                <>
                  <Input
                    style={Styles.input}
                    placeholder={'How do you want to revise the summary?'}
                    placeholderTextColor={'#999'}
                    height={50}
                    onChangeText={setReviseSummaryQuery}
                    value={reviseSummaryQuery}
                    onSubmitEditing={onSubmitEditSummary}
                  />
                  <Button
                    style={{ alignSelf: 'flex-end' }}
                    onPress={onSubmitEditSummary}
                    theme="fancy"
                  >
                    <View style={{ flexDirection: 'row' }}>
                      <TextBodyRegular style={[Styles.h6, { color: '#fff' }]}>Submit</TextBodyRegular>
                    </View>
                  </Button>
                </>
              )}
              <View
                style={{
                  flexDirection: 'row',
                  marginTop: 20,
                }}
              >
                <Button
                  style={{ alignSelf: 'flex-start', marginRight: 40 }}
                  onPress={onReviseSummary}
                  theme="fancy"
                  active={reviseSummary}
                >
                  <View style={{ flexDirection: 'row' }}>
                    <TextBodyRegular style={[Styles.h4, { marginRight: 30, color: '#fff' }]}>Inquire for more details</TextBodyRegular>
                    <Ionicons
                      name="pencil"
                      size={24}
                      color="white"
                    />
                  </View>
                </Button>
                <Button
                  style={{ alignSelf: 'flex-start' }}
                  onPress={copySummary}
                  theme="fancy"
                >
                  <View style={{ flexDirection: 'row' }}>
                    <TextBodyRegular style={[Styles.h4, { marginRight: 30, color: '#fff' }]}>Copy</TextBodyRegular>
                    <Ionicons
                      name="clipboard"
                      size={24}
                      color="white"
                    />
                  </View>
                </Button>
              </View>
              <View>
                <TextBodyMedium style={[Styles.h4, { marginTop: 30, marginBottom: 10 }]}>Upload documents</TextBodyMedium>
                <TextBodyRegular style={[Styles.h5, { marginBottom: 20 }]}>Adding more documents will increase the quality of the summary.</TextBodyRegular>
                <View
                  style={{
                    marginBottom: 30,
                  }}
                >
                  <UploadDocumentButton onPress={handleFileUpload} />
                </View>
              </View>
            </View>
          </>
        )}
      </>
    );
  };

  return (
    <ScrollView
      style={{
        position: 'absolute',
        backgroundColor: '#fff',
        borderRadius: 20,
        padding: 20,
        left: '10%',
        top: '10%',
        bottom: '10%',
        width: '80%',
      }}
    >
      <View
        style={{
          padding: 20,
          paddingTop: 50,
        }}
      >
        <View
          style={{
            alignSelf: 'flex-end',
          }}
        >
          <Button
            theme="fancy"
            onPress={closeModal}
            icon="close"
          ></Button>
        </View>
        <TextBodyMedium style={[Styles.h3, { marginBottom: 10, marginRight: 150 }]}>{`Attribute: ${task.name}`}</TextBodyMedium>
        <TextBodyRegular style={[Styles.h6, { marginBottom: 30, marginRight: 150 }]}>{`${task.summary}`}</TextBodyRegular>
        {checkLoading('generateTaskDetails') && (
          <>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <ActivityIndicator
                size={20}
                color="black"
              />
              <TextHeader style={[Styles.h4, { marginLeft: 10 }]}>Loading</TextHeader>
            </View>
            <TextBodyRegular style={[Styles.h7, { marginTop: 10, width: '45%' }]}>Please wait. This can take 10-30 seconds depending on the number of files.</TextBodyRegular>
            <CountdownTimer
              size={14}
              initialTime={30}
            />
          </>
        )}
        {!checkLoading('generateTaskDetails') && (
          <>
            {isWideScreen ? (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                {emailSummaryElement()}
              </View>
            ) : (
              <>{emailSummaryElement()}</>
            )}
          </>
        )}
      </View>
    </ScrollView>
  );
};

export default TaskModal;
