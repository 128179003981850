import { Provider as DemoProvider } from '@src/components/Demos/Context';
import React from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider as AdvisorProvider } from '../components/BackOffice/Advisor/Provider';
import { Provider as AlignmentProvider } from './Alignment';
import { Provider as AnalyticsProvider } from './Analytics';
import { Provider as AssessmentProvider } from './Assessment';
import { Provider as AuthenticationProvider } from './Authentication';
import { Provider as LoadingProvider } from './Loading';
import { Provider as ModalProvider } from './Modal';
import { Provider as NavigationProvider } from './Navigation';
import { Provider as ProjectProvider } from './Project';
import { Provider as UserProvider } from './User';

const unauthenticatedProviders = [
  ProjectProvider, // we only need this for initiator users
  AssessmentProvider, // we only need this for initiator users: we can conditionally add/remove this provider based on the user, this is a future refactor
  NavigationProvider, // we need the nav outside of all user logic
  AnalyticsProvider, // we need to trigger analytics in the navigation provider
  LoadingProvider, // child providers need to be able to change the global loading state
  SafeAreaProvider,
];

const authenticatedProviders = [
  ProjectProvider, // we only need this for initiator users
  AdvisorProvider,
  AssessmentProvider, // we only need this for initiator users: we can conditionally add/remove this provider based on the user, this is a future refactor
  DemoProvider,
  AlignmentProvider,
  NavigationProvider, // we need the nav outside of all user logic
  UserProvider, // user state listener
  ModalProvider,
  AuthenticationProvider, // aws amplify auth
  AnalyticsProvider, // we need to trigger analytics in the navigation provider
  LoadingProvider, // child providers need to be able to change the global loading state
  SafeAreaProvider,
];

export default function Providers({ children }: any) {
  const providers = authenticatedProviders;
  return providers.reduce((Reduced, Provider) => {
    return <Provider>{Reduced}</Provider>;
  }, children);
}
