import { taskListWrapper } from '@src/components/Conversation/placeholders';
import CountdownTimer from '@src/components/Countdown';
import { TextBodyMedium, TextBodyRegular, TextHeader } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { View } from '@src/components/shared/Themed';
import { LoadingContext } from '@src/providers/Loading';
import { chat } from '@src/utils/Chat';
import { isCircular } from '@src/utils/isCircular';
import { useContext, useEffect, useState } from 'react';
import { ActivityIndicator } from 'react-native';
import TaskListActionPlan from './TaskListActionPlan';

interface TaskListProps {
  indexId?: string;
  namespace?: string;
}

const TaskList = ({ indexId, namespace }: TaskListProps) => {
  const { addLoading, removeLoading, checkLoading } = useContext(LoadingContext);
  const [response, setResponse] = useState<any>();

  useEffect(() => {
    loadingLLMRequest({ indexId, namespace });
  }, []);

  const loadingLLMRequest = async ({ indexId, namespace }: TaskListProps) => {
    addLoading('loadingLLMRequest');

    const vsTaskListQuery = {
      modelName: 'gpt-4o-mini',
      templateWrapper: 'Your response should be to first analyze the goal of question, and then produce a "single simplified question" that will be used to search through a vector store to retrieve relevant documents related to the question: <question>{question}</question>.  Nothing other than the "single simplified question" should be returned.  No other text should be included.  Do not include any brackets like {{}}',
      question: taskListWrapper.templateWrapper,
    };

    const taskListSource = {
      requestName: 'vsTaskListQuery',
      numberOfSources: 50,
      modelName: 'gpt-4o-mini',
      indexId,
      namespace,
    };

    const taskListRequest = {
      modelName: 'gpt-4o-mini',
      templateWrapper: `${taskListWrapper.templateWrapper}\n${taskListWrapper.jsonFormat}`,
      isJson: true,
    };

    const llmRequest = {
      0: {
        vsTaskListQuery,
      },
      1: {
        taskListSource,
      },
      2: {
        taskListRequest,
      },
    };

    try {
      // Check for circular references
      if (isCircular(llmRequest)) {
        console.error('Circular reference detected in chatObj');
        return; // Handle the error as needed
      }

      const res = await chat(llmRequest);

      if (res) {
        setResponse(res.taskListRequest);
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      removeLoading('loadingLLMRequest');
    }
  };

  let taskList;
  if (response) {
    taskList = response.tasks.sort((a: any, b: any) => b.difficulty - a.difficulty);
  } else {
    taskList = [];
  }

  return (
    <View>
      {checkLoading('loadingLLMRequest') && (
        <>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <ActivityIndicator
              size={20}
              color="black"
            />
            <TextHeader style={[Styles.h4, { marginLeft: 10 }]}>Loading</TextHeader>
          </View>
          <TextBodyRegular style={[Styles.h7, { marginTop: 10, width: '45%' }]}>Please wait. This can take 60-120 seconds depending on the number of files.</TextBodyRegular>
          <CountdownTimer
            size={14}
            initialTime={120}
          />
        </>
      )}
      {response && taskList && (
        <>
          {taskList && taskList.length > 1 ? (
            <>
              <TextHeader style={[Styles.h3, { marginBottom: 20 }]}>Task List</TextHeader>
              <TextBodyMedium style={[Styles.h6, { marginBottom: 20 }]}>Total tasks: {taskList.length}</TextBodyMedium>
              <TextBodyRegular style={[Styles.h7, { width: '50%', marginBottom: 30 }]}>Below is a list of tasks based on the files you uploaded. Clicking on a task will reveal more details about that task, allowing you to upload more files or generate email templates.</TextBodyRegular>
              <TaskListActionPlan
                actionPlan={taskList}
                indexId={indexId}
                namespace={namespace}
              />
            </>
          ) : (
            <>
              <TextHeader style={[Styles.h3, { marginBottom: 20 }]}>Task List</TextHeader>
              <TextBodyRegular style={[Styles.h7, { width: '50%', marginBottom: 30 }]}>No tasks found. Please try again.</TextBodyRegular>
            </>
          )}
        </>
      )}
    </View>
  );
};

export default TaskList;
