import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/core';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import Screen from '@src/components/Screen';
import { TextBodyMedium, TextBodyRegular, TextHeader } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { Pressable, View } from '@src/components/shared/Themed';
import React from 'react';
import Button from '../Button';
import AdvisorList from './Advisor/AdvisorList';

// list the projects that the investor is involved in
// create a new project
const BackOffice = () => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const gotoNewProject = () => {
    navigation.navigate('advisorNew');
  };

  const goBack = () => {
    navigation.navigate('investor');
  };

  return (
    <Screen>
      <View
        style={{
          marginTop: 30,
        }}
      >
        <Pressable
          onPress={goBack}
          style={{
            flexDirection: 'row',
            marginBottom: 30,
            alignSelf: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Ionicons
            name="chevron-back"
            size={30}
          />
          <TextBodyMedium style={[Styles.h6, { textDecorationLine: 'underline' }]}>Back to home</TextBodyMedium>
        </Pressable>
        <TextHeader style={[Styles.h1, { marginBottom: 30 }]}>BACK OFFICE</TextHeader>
        <View
          style={{
            padding: 20,
            borderWidth: 1,
            borderColor: '#ccc',
            borderRadius: 10,
            backgroundColor: '#f9f9f9',
          }}
        >
          <TextHeader style={[Styles.h2, { marginVertical: 10 }]}>Advisors:</TextHeader>
          <View
            style={{
              marginVertical: 20,
              borderColor: '#ccc',
              borderWidth: 1,
              padding: 20,
              paddingBottom: 40,
              backgroundColor: '#fff',
              borderRadius: 5,
            }}
          >
            <TextBodyRegular style={[Styles.h6, { marginBottom: 30 }]}>Create a new advisor. An advisor includes reference documentation that can be used to improve reports.</TextBodyRegular>
            <Button
              onPress={gotoNewProject}
              theme="fancy"
              icon="add"
            >
              New advisor
            </Button>
          </View>
          <View
            style={{
              marginVertical: 20,
              borderColor: '#ccc',
              borderWidth: 1,
              padding: 20,
              paddingBottom: 40,
              backgroundColor: '#fff',
              borderRadius: 5,
            }}
          >
            <TextHeader style={[Styles.h2, { marginBottom: 10 }]}>Your advisor:</TextHeader>
            <TextBodyRegular style={[Styles.h6, { marginBottom: 30 }]}>Click on an advisor below to add more documents.</TextBodyRegular>
            <AdvisorList />
          </View>
        </View>
      </View>
    </Screen>
  );
};

export default BackOffice;
