import Button from '@src/components/Button';
import { weAlignReview } from '@src/components/Conversation/placeholders';
import Input from '@src/components/Input';
import Colors from '@src/components/shared/Colors';
import { TextBodyMedium, TextBodyRegular } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { View } from '@src/components/shared/Themed';
import { AlignmentContext } from '@src/providers/Alignment';
import { LoadingContext } from '@src/providers/Loading';
import { ModalContext } from '@src/providers/Modal';
import { chat } from '@src/utils/Chat';
import isValidJson from '@src/utils/isValidJson';
import React, { useContext, useEffect, useState } from 'react';
import { ActivityIndicator } from 'react-native';
import { SPSPage } from '.';
import DeliverableLLMItem from './DeliverableLLMItem';
import InputEditable from './InputEditable';

const AddAlignment = () => {
  const [inputValue, setInputValue] = useState<string>('');

  // const onKeyPress = (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
  //   if (e.key === 'Escape') {
  //     onCancel();
  //   } else if (e.shiftKey && e.key === 'Enter') {
  //     // Trigger the same function as onSave button
  //     onSave();
  //   }
  //   console.log('keypress', e.key);
  // };

  return (
    <View>
      <Input
        style={[Styles.h4]}
        onChangeText={setInputValue}
        value={inputValue}
        multiline={true}
      />
    </View>
  );
};

const SPSReview = ({ goto }: SPSPage) => {
  const { addLoading, removeLoading, checkLoading } = useContext(LoadingContext);
  const { alignmentState, setAlignmentState } = useContext(AlignmentContext);
  const { setModalContent } = useContext(ModalContext);

  let alignmentStateDeliverables = alignmentState.deliverablesLLM || alignmentState.deliverables;
  const alignmentStateRequirements = alignmentState.requirements || alignmentState.requirementsLLM;

  const tasks = alignmentStateDeliverables && typeof alignmentStateDeliverables === 'string' && alignmentStateDeliverables.includes('{') && alignmentStateDeliverables.trim().startsWith('{') && isValidJson(alignmentStateDeliverables) && JSON.parse(alignmentStateDeliverables).tasks;
  if (alignmentStateDeliverables && alignmentStateDeliverables.includes('{')) {
    alignmentStateDeliverables = alignmentStateDeliverables.replace(/{/g, '{{').replace(/}/g, '}}');
  }
  const deliverablesLLM = {
    modelName: 'gpt-4o-mini',
    templateWrapper: `
    Your role is to analyze the tasks from <source> and create a comprehensive action plan task list. It is crucial that you identify and include **all** tasks mentioned in the source material, which may be numerous. Each task must be unique and clearly defined. Review the <source> material thoroughly and ensure that no tasks are omitted from your response. Each task should include the following components: - Name: A concise title for the task (string). - Summary: A brief explanation of how the source relates to the task and a suggestion for completion (string). - Difficulty Score: A score from 0-10 indicating the complexity of the task based on the resources required (number). <source>${alignmentStateRequirements} ${alignmentStateDeliverables}<source> ${weAlignReview.jsonFormat}`,
  };

  const requirementsLLM = {
    modelName: 'gpt-4o-mini',
    templateWrapper: `Create a useful summary based on information from both the requirements: ${alignmentStateRequirements} and deliverables ${alignmentStateDeliverables}.`,
  };

  const titleLLM = {
    modelName: 'gpt-4o-mini',
    templateWrapper: `Create a short 5 word title based on these requirements: ${alignmentStateRequirements}`,
  };

  const emailLLM = {
    modelName: 'gpt-4o-mini',
    templateWrapper: `Write an email that asks the recipient to submit information and/or documents that fullfil the requirements listed: ${alignmentStateRequirements}`,
  };

  const gotoCreate = () => {
    goto('make');
  };

  const gotoGenerated = () => {
    goto('generate');
  };

  const onAlign = async (property?: string) => {
    try {
      addLoading('onAlign');
      const updatedRequests = { 0: { deliverablesLLM, titleLLM, requirementsLLM, emailLLM } };
      const res = await chat(updatedRequests);
      setAlignmentState({ ...alignmentState, ...res });
    } catch (err) {
      console.log('error onAlign', err);
    } finally {
      removeLoading('onAlign');
    }
  };

  const addRequirement = () => {
    // Add a new requirement
    console.log('addRequirement');
    // setModalContent(<AddAlignment />);
  };

  useEffect(() => {
    onAlign();
  }, []);

  return (
    <>
      <View
        style={{
          backgroundColor: Colors.light.container,
          marginTop: '3%',
          marginHorizontal: '3%',
          padding: 30,
        }}
      >
        <TextBodyMedium style={[Styles.h2, { marginTop: 10, marginBottom: 30 }]}>Draft of your alignment</TextBodyMedium>
        <TextBodyRegular style={[Styles.h6, { marginBottom: 10 }]}>Below we have prepared your request. Please revise it as you need before generating a link that can be shared.</TextBodyRegular>
        <TextBodyRegular style={[Styles.h6, { marginBottom: 20 }]}>If you have multiple parties that you wish to send the same requirements to, you will be able to duplicate your alignment on the next step.</TextBodyRegular>
      </View>
      {checkLoading('onAlign') && (
        <View
          style={{
            backgroundColor: Colors.light.container,
            marginTop: '3%',
            marginHorizontal: '3%',
            padding: 30,
          }}
        >
          <ActivityIndicator
            size="large"
            color={Colors.buttonBackgroundActive}
          />
        </View>
      )}
      {!checkLoading('onAlign') && (
        <>
          {alignmentState && alignmentState.titleLLM && (
            <View
              style={{
                backgroundColor: Colors.light.container,
                marginTop: '3%',
                marginHorizontal: '3%',
                padding: 30,
              }}
            >
              <View
                style={{
                  borderBottomColor: '#fff',
                  paddingBottom: 20,
                  marginBottom: 20,
                  borderBottomWidth: 1,
                }}
              >
                <TextBodyMedium style={[Styles.h4, { color: Colors.lilac }]}>Your headline</TextBodyMedium>
                <TextBodyRegular style={[Styles.h6, { marginTop: 10 }]}>This will be visible at the top of the alignment page as well as in your email subject.</TextBodyRegular>
              </View>
              <InputEditable
                property="titleLLM"
                onAlign={onAlign}
              />
            </View>
          )}

          {alignmentState && alignmentState.requirementsLLM && (
            <View
              style={{
                backgroundColor: Colors.light.container,
                marginTop: '3%',
                marginHorizontal: '3%',
                padding: 30,
              }}
            >
              <View
                style={{
                  borderBottomColor: '#fff',
                  paddingBottom: 20,
                  marginBottom: 20,
                  borderBottomWidth: 1,
                }}
              >
                <TextBodyMedium style={[Styles.h4, { color: Colors.lilac }]}>Your summary</TextBodyMedium>
                <TextBodyRegular style={[Styles.h6, { marginTop: 10 }]}>A brief explanation of the requirements</TextBodyRegular>
              </View>
              <InputEditable
                property="requirementsLLM"
                onAlign={onAlign}
                height={500}
              />
            </View>
          )}

          {tasks && (
            <View
              style={{
                backgroundColor: Colors.light.container,
                marginTop: '3%',
                marginHorizontal: '3%',
                padding: 30,
              }}
            >
              <View
                style={{
                  borderBottomColor: '#fff',
                  paddingBottom: 20,
                  marginBottom: 20,
                  borderBottomWidth: 1,
                }}
              >
                <TextBodyMedium style={[Styles.h4, { color: Colors.lilac }]}>What needs to be completed</TextBodyMedium>
                <TextBodyRegular style={[Styles.h6, { marginVertical: 10 }]}>This is a list of the requirements that we suspect you want? You can add or remove below.</TextBodyRegular>
              </View>
              {tasks.map((requirement, index) => {
                return (
                  <View
                    key={`review-requirement-${index}`}
                    style={{
                      marginBottom: 20,
                    }}
                  >
                    <DeliverableLLMItem
                      index={index}
                      task={requirement}
                      onAlign={onAlign}
                    />
                  </View>
                );
              })}

              <Button
                onPress={addRequirement}
                toolTip="Coming soon"
                style={{
                  width: '100%',
                  // backgroundColor: Colors.buttonBackground,
                  backgroundColor: '#fff',
                }}
              >
                <TextBodyMedium style={[Styles.h6]}>Add a new requirement</TextBodyMedium>
              </Button>
            </View>
          )}

          {alignmentState && alignmentState.emailLLM && (
            <View
              style={{
                backgroundColor: Colors.light.container,
                marginTop: '3%',
                marginHorizontal: '3%',
                padding: 30,
              }}
            >
              <View
                style={{
                  borderBottomColor: '#fff',
                  paddingBottom: 20,
                  marginBottom: 20,
                  borderBottomWidth: 1,
                }}
              >
                <TextBodyMedium style={[Styles.h4, { color: Colors.lilac }]}>Your shareable email</TextBodyMedium>
                <TextBodyRegular style={[Styles.h6, { marginTop: 10 }]}>Copy this email and drop it into your email client and send it to the responsible parties. This feature will be automated if you added assignees in the previous step.</TextBodyRegular>
              </View>
              <InputEditable
                property="emailLLM"
                onAlign={onAlign}
                height={500}
              />
            </View>
          )}

          {!checkLoading('onAlign') && (
            <View
              style={{
                backgroundColor: Colors.light.container,
                marginTop: '3%',
                marginHorizontal: '3%',
                padding: 30,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Button
                onPress={gotoCreate}
                theme="fancy"
                icon="chevron-back"
                toolTip={'Go back and edit your alignment'}
              >
                Edit
              </Button>
              <Button
                onPress={gotoGenerated}
                theme="fancy"
                toolTip={'Create a unique shareable link'}
              >
                Generate
              </Button>
            </View>
          )}
        </>
      )}
    </>
  );
};

export default SPSReview;
